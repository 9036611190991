import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

import './i18n';

//import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './ErrorBoundary';
import { FileMakerProvider } from './FileMaker';
/**
 * @description - Root component of the application
 * @see {@link https://reactjs.org/docs/react-dom.html#createroot}
*/
Object.defineProperty(window, '__APOLLO_DEVTOOLS_GLOBAL_HOOK__', { value: true, configurable: true, enumerable: true, writable: true });
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <FileMakerProvider>
        <BrowserRouter basename="/">
          <App config={rootElement.dataset} />
        </BrowserRouter>
      </FileMakerProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        '/service-worker.js'
      );
      if (registration.installing) {
        //console.log('Service worker installing');
      } else if (registration.waiting) {
        //console.log('Service worker installed');
      } else if (registration.active) {
        //console.log('Service worker active');
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

// ...

if(!process.env.REACT_APP_BUILD_TARGET) registerServiceWorker();