import React from "react";
export default class ErrorBoundary extends React.Component {
  state: { hasError: boolean; error:any };
  props:any;
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error: { message: any; }) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo, Object.getOwnPropertyNames(error));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex h-screen ">
          <div className="m-auto align-middle  text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-red-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h3 className="mt-2 text-sm font-medium dark:text-white text-gray-900">SmartPublisher encountered a problem</h3>
            <p className="mt-1 text-sm dark:text-white text-gray-500">{this.state.hasError ? this.state.error : null}</p>
            <div className="mt-3"> <a href="/login" className=" text-sm rounded bg-blue-600 p-3 text-white font-medium">Go to Login</a></div>
          </div>
        </div>

      )
    }

    return this.props.children;
  }
}