import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';

export const MenuDropdown = ({ actions, row = {}, buttonClass = "", className = "" }) => {
  return actions[0]?.items?.length ? (
    <Menu as="div" className="relative dark:bg-darkbrand-700">
      {({ open }) => (
        <>
          <div onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }} className="relative">
            <Menu.Button className={`${className ? className : ""} ${buttonClass ? buttonClass : ""} h-5 -my-2 flex text-gray-400 hover:dark:text-white text-gray-900 focus:outline-none focus:ring-offset-transparent `}>
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-full align-middle" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="overflow-y-visible origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-darkbrand-700 dark:ring-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
              {actions?.map(({ items, title, onlyMobile = false }, i) => (
                <div key={i}>
                  {i > 0 ? <div className={`${onlyMobile ? "md:hidden" : ""} relative`}>
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-2 bg-white text-sm text-gray-500">{title}</span>
                    </div>
                  </div> : null}
                  <div className={`${onlyMobile ? "md:hidden" : ""} flex flex-col space-y-1`} key={i}>
                    {items?.map(({title, handleAction }, j) => (
                      <Menu.Item key={(i * 2) + j}>
                        {({ active }) => (
                          <button onClick={(e) => handleAction(i, row, e)} className={`${active ? 'bg-gray-100 dark:text-white text-gray-700' : 'dark:text-white text-gray-700'}  ${onlyMobile ? "md:hidden" : ""} table-cell  py-2 text-sm w-full z-50`}>
                            {title}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </div>
              ))}

            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  ) : null;
}
