import React from 'react';
import { Tab } from '@headlessui/react';


export const Tabs = ({ children = [], ...props }: any) => {

    return (
        <>
            <Tab.Group>
                <Tab.List>
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {children.map((child: React.ReactElement) => <Tab key={child.key} {...props} className={({ selected }) => `${selected ? 'border-indigo-500 text-indigo-600' : 'border-transparent dark:text-white text-gray-500 hover:dark:text-white text-gray-700 hover:border-gray-200'} whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm`}>{child.props.tabLabel}</Tab>)}
                        </nav>
                    </div>
                </Tab.List>
                <Tab.Panels>
                    {children.map((child: React.ReactElement) => <Tab.Panel key={child.key} {...props}>{child}</Tab.Panel>)}
                </Tab.Panels>
            </Tab.Group>
        </>);
};
