import { RadioGroup } from "@headlessui/react";
import { useController } from "react-hook-form";

export const HeadlineSizeSelect = () => {
  const { field,/* fieldState: { invalid, isTouched, isDirty },formState: { touchedFields, dirtyFields } */ } = useController({
    name: "adHeadlineSize",
    rules: { required: true },
  });
  const headlineSizeOptions = ["S", "M", "L"];
  return (<>
    <span className="sr-only">Headline Size</span>
    <RadioGroup title="Headline Size" value={field.value} onChange={field.onChange}>
      <RadioGroup.Label className="sr-only">Headline Size</RadioGroup.Label>
      <div className="grid grid-cols-3">
        {headlineSizeOptions.map((option, idx) => (
          <RadioGroup.Option key={idx} value={option} className={({ active, checked }) => `cursor-pointer focus:outline-none ${checked ? 'bg-brand-600 text-white hover:bg-brand-500' : "bg-brand-400 text-gray-600"} flex items-center justify-center ${idx == 0 ? "rounded-l-md" : ""} ${idx == 2 ? "rounded-r-md" : ""} py-0 px-1 text-sm font-semibold uppercase sm:flex-1`} >
            <RadioGroup.Label as="span">{option}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  </>);
}