import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, } from "@apollo/client";
import { gql } from "@apollo/client";
import { AppContext, IAppContext } from "./App";
import { TopNav } from "./components/TopNav";
import { Breadcrumb } from "./components/Breadcrumb";
import { Footer } from "./components/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import SessionExpirationWarningDialog from "./SessionExpirationWarningDialog";
import toast, { Toaster } from 'react-hot-toast';
import { CommandPalette } from "./components/CommandPalette";
import { SlideOver } from "./SlideOver";

export const KEEPALIVE_MINUTES = 14.75;

export const LayoutContext = React.createContext<any>({});

export const Layout = () => {
    const context= useContext<IAppContext | null>(AppContext);
    const { user } = context || {};
    const navigate = useNavigate();
    const [modal, setModal] = useState({});
    const [title, setTitle] = useState("");
    const [showTimeoutWarning, setShowTimeoutWarning] = useState(false);
    const [subtitle, setSubtitle] = useState("");
    const [activeRecord, setActiveRecord] = useState("");
    const [restartTimeoutWarning, setRestartTimeoutWarning] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [searchEnabled, setSearchEnabled] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [currentRecordId, setCurrentRecordId] = useState(null);
    const [menu, setMenu] = useState([]);
    const [showSlideOver, setShowSlideOver] = useState(false);
    const [slideOverElement, setSlideOverElement] = useState(<div>empty...</div>)
    const [getSystemStatus] = useLazyQuery(gql`
    query resumeSession{
        getSystemStatus {
            filemakerServerHostname
        filemakerServerVersion
        smartpublisherVersion
        smartpublisherFilemakerUrl
        smartpublisherWebdirectUrl
        pre1SupportUrl
        pre1Id
        clientId
        clientName
        firstName
        initials
        lastName
        nameFirst
        nameLast
        userFirst
        userFull
        userLast
        menuLevel
        menuLevelAccess
        preferred_username
        primFunction
        pubIDUserDefaultPref
        salesFunction
        salesTabView
        sid
        signature
        userId
        userType
        fmsVersion
        timeStampFormat
        timeFormat
        fmsApiName
        dateFormat
        fmsBuildDate
        }
    }
`, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache"
    });
    useEffect(() => {
        let count = 0;
        const intervalId = setInterval(() => {
            count = count + 1;
            //console.log("Keepalive count: ", count);
            //console.log("Start/Now: ", start.toString(), new Date().getTime().toString());
            if (count >= 3) {
                getSystemStatus();
                setShowTimeoutWarning(true);
                clearInterval(intervalId);
            } else {
                getSystemStatus();
            }
        }, 1000 * KEEPALIVE_MINUTES * 60); // Remaining time for session to expire
        return () => clearInterval(intervalId);
    }, [restartTimeoutWarning]);
    //If user object changes to a falsy value redirect to login
    useEffect(() => {
        if (!user) navigate("/login");
    }, [user]);
    const resumeSession = async () => {
        setShowTimeoutWarning(false);
        setRestartTimeoutWarning(!restartTimeoutWarning); //flip value, value doesn't matter
    };
    const toastOptions = {
        // Define default options
        className: '',
        duration: 5000,
        style: {
            background: '#363636',
            color: '#fff',
        },

        // Default options for specific types
        success: {
            duration: 3000,
            theme: {
                primary: 'green',
                secondary: 'black',
            },
        },
        error: {
            duration: 9000
        }
    }
    return <LayoutContext.Provider value={{
        activeRecord,
        currentRecordId,
        menu,
        modal,
        searchEnabled,
        searchValue,
        setActiveRecord,
        setCurrentRecordId,
        setMenu,
        setModal,
        setSearchEnabled,
        setSearchValue,
        setShowSlideOver,
        setSlideOverElement,
        setShowNotification,
        setSubtitle,
        setTitle,
        showNotification,
        subtitle,
        title,
        toast,
    }}>
        <CommandPalette>
            <TopNav />
        </CommandPalette>
        <Breadcrumb />
        <div className={"grow container mx-auto sm:px-6 lg:px-8"}>
            <Outlet />
        </div>
        <Footer />
        <Toaster position="top-center" reverseOrder={false} gutter={8} containerClassName="" containerStyle={{}} toastOptions={toastOptions} />
        <SessionExpirationWarningDialog show={showTimeoutWarning} handleResume={resumeSession} />
        <SlideOver isOpen={showSlideOver} onClose={() => setShowSlideOver(false)} position="left">
            {slideOverElement}
        </SlideOver>
    </LayoutContext.Provider>;
};
export default Layout;