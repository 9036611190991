import React, { Fragment, useState, useContext } from 'react'
import { PencilIcon, PlusIcon, SelectorIcon, CheckIcon, XIcon } from '@heroicons/react/solid'
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { FormProvider, useForm, useFormContext, useController } from 'react-hook-form';
import { Tab } from '@headlessui/react'
import { Combobox } from '@headlessui/react'
import { Listbox, Transition } from '@headlessui/react'
import { LayoutContext } from 'react-filemaker';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useSlideOver } from '../SlideOver';
import { AddressInput } from "./AddressInput";
import { Input } from "./Input";
import { Select } from "./Select";
import { useConfirmationModal } from "./Modal";
export const CREATE_CONTACT = gql`
    mutation createContact ($input: CreateAccountInput) {
        createContact(input: $input){
            id
            contactId
        }
    }
`

export const NewContactForm = ({ accountId }) => {
    const { onClose } = useSlideOver();
    const [createContact] = useMutation(CREATE_CONTACT);
    const onSubmit = async (values) => {
        try {
            const result = await createContact({ variables: { input: values } });
            console.log("createContact", result);
            toast.custom("Contact created successfully");
        } catch (e) {
            toast.custom("Error creating account");
        }
    }
    const onError = (error, e) => {
        toast("Error creating account");
        console.log("error account create", error);
    }
    const defaultContact = {
        accountId: accountId,
        email: "",
        extension: "",
        firstName: "",
        lastName: "",
        makePrimary: false,
        nickname: "",
        phone: "",
        phone2: "",
        phone2Type: "",
        salutation: "",
        title: "",
    };
    const methods = useForm({
        mode: "onBlur",
        defaultValues: defaultContact
    });
    const { open } = useConfirmationModal();
    const { handleSubmit, formState } = methods;
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="space-y-4">
                <div className="space-x-2 flex">
                    <Input name={"firstName"} label="First Name" />
                    <Input name={"lastName"} label="Last Name" />
                </div>
                <Input name={"title"} label="Title" />
                <Input name={"phone"} label="Phone" />
                <div className="space-x-2 flex">
                    <Input name={"phone2"} label="Secondary Phone" />
                    <Select name={"phone2Type"} label="Type" placeholder="&nbsp;" options={[{id: "Fax",name:"Fax"},{id: "Home", name: "Home"}, { id: "Mobile", name: "Mobile"}, {id: "Work", name: "Work"}]} />
                </div>
                <Input name={"email"} label="Email" />
                <Input name={"nickname"} label="Nickname" />
                <Input name={"salutation"} label="Salutation" />
                <AddressInput key="physical" tabLabel="Physical Address" />
                <div className="flex-shrink-0 px-4 pt-4 flex justify-between sticky bottom-0 bg-white z-20">

                    <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium dark:text-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                        onClick={() => {
                            console.log(formState.dirtyFields, formState.isDirty);
                             if (formState.isDirty) {
                                open({
                                    title: "Discard Changes?",
                                    message: "Are you sure you want to discard your changes?",
                                    handleConfirm: () => {
                                        methods.reset();
                                        //onClose();
                                    },
                                    handleCancel: () => {
                                        console.log("cancel...");
                                    }
                                })
                            } else {
                                methods.reset();
                                //onClose();
                            } 
                        }}
                    >
                        Close
                    </button>
                    <button type="submit" className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500">
                        Save
                    </button>
                </div>
            </form>
        </FormProvider>
    )
};
export default NewContactForm;
{/* 
                <div className=" grid grid-cols-2">
                    <div className="relative flex items-start col-span-1">
                        <div className="flex items-center h-5">
                            <input
                                id="do-not-call"
                                aria-describedby="do-not-call-description"
                                name="do-not-call"
                                type="checkbox"
                                className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded"
                                value={doNotCall}
                                onChange={e => setDoNotCall(e.target.value)}
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label htmlFor="do-not-call" className="font-medium dark:text-white text-gray-700">
                                Do not call
                            </label>
                            <span id="do-not-call-description" className="text-gray-500">
                                <span className="sr-only">Do not call</span>
                            </span>
                        </div>
                    </div>
                    <div className="relative flex items-start col-span-1">
                        <div className="flex items-center h-5">
                            <input
                                id="do-not-email"
                                aria-describedby="do-not-email-description"
                                name="do-not-email"
                                type="checkbox"
                                className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded"
                                value={doNotEmail}
                                onChange={e => setDoNotEmail(e.target.value)}
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label htmlFor="do-not-email" className="font-medium dark:text-white text-gray-700">
                                Do not email
                            </label>
                            <span id="do-not-email-description" className="text-gray-500">
                                <span className="sr-only">Do not email</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <label htmlFor="greeting" className="block text-sm font-medium dark:text-white text-gray-700">
                        Greeting
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="greeting"
                            id="greeting"
                            className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            value={greeting}
                            onChange={e => setGreeting(e.target.value)}
                        />
                    </div>
                </div> */}
/*
}
            {!editData && <div>
                <div className="sm:flex sm:items-end">
                    <div className="sm:flex-1">
                        <div>
                            <div className="flex items-center">
                                <h3 className="font-bold text-xl dark:text-white text-gray-900 sm:text-2xl">{firstName + ' ' + lastName}</h3>
                            </div>
                            {title && <p className="text-sm text-gray-500">{title}</p>}
                            {email && !title && <p className="text-sm text-gray-500">{email}</p>}
                            {phone && !email && !title && <p className="text-sm text-gray-500">{phone}</p>}
                        </div>
                        <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                            <button
                                type="button"
                                disabled={!email}
                                className={`${!email ? "opacity-50 cursor-not-allowed" : ""} flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 sm:flex-1`}
                            >
                                Email
                            </button>
                            <button
                                type="button"
                                className={`${!phone && !phone2 && !phone3 ? "opacity-50 cursor-not-allowed" : ""} flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium dark:text-white text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500`}
                            >
                                Call
                            </button>
                            <span className="ml-3 inline-flex sm:ml-0">
                                <div className="relative inline-block text-left">
                                    <button onClick={() => handleEdit()} className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500">
                                        <span className="sr-only">Open options menu</span>
                                        <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="pt-5 pb-5">
                    <dl className="space-y-8 sm:space-y-6">
                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Phone</dt>
                        <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{phone || '—'}</dd>

                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Phone 2</dt>
                        <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{phone2 || '—'}</dd>

                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Email</dt>
                        <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{email || '—'}</dd>

                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Nickname</dt>
                        <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{nickname || '—'}</dd>

                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Address</dt>
                        <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{street1 || '—'}</dd>
                        {street2 && <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{street2}</dd>}
                        <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2 flex space-x-1">
                            {city && <div>{city},</div>}
                            <div>{state}</div>
                            <div>{zip}</div>
                        </dd>

                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Do not call</dt>
                        <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">
                            <input
                                id="do-not-call-view"
                                aria-describedby="do-not-call-description"
                                name="do-not-call-view"
                                type="checkbox"
                                placeholder="Do not call"
                                className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded"
                                value={doNotCall}
                                disabled
                            />
                        </dd>

                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Do not email</dt>
                        <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">
                            <input
                                id="do-not-email-view"
                                aria-describedby="do-not-email-description"
                                name="do-not-email-view"
                                type="checkbox"
                                placeholder="Do not email"
                                className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded"
                                value={doNotEmail}
                                disabled
                            />
                        </dd>

                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Greeting</dt>
                        <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{greeting || '—'}</dd>

                    </dl>
                </div>
            </div>}
            */