import React, { lazy, Suspense, useState, useContext } from "react";
import { ApolloProvider, ApolloClient, from, InMemoryCache } from "@apollo/client";
//import { RetryLink } from "@apollo/client/link/retry";
//import { createContext } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import apolloLogger from "apollo-link-logger";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import Layout from "./Layout";
import { NotFound } from "./components/NotFound";
import { Loading } from './components/Loading'
import RenewAd from "./components/AdRenew";
//import styled from 'styled-components'
import toast from "react-hot-toast";
import Dashboard from "./components/Dashboard";
import { Navigate } from "react-router-dom";
//import { useReactiveVar } from '@apollo/client';
import { IntlProvider } from 'react-intl';
//import { use } from "i18next";
//import { Controller, useFormContext } from "react-hook-form";
//import { set } from "date-fns";
import { ClassifiedAdEditor } from "./ClassifiedAdEditor";
import { loader } from 'graphql.macro';
import { FileUpload } from "./FileUpload";
import { CalendarEmbed } from "./components/CalendarEmbed";
const dataApiTypeDefs = loader('./dataapi.graphql');
const AccountsList = lazy(() => import("./components/AccountsList"));
const AccountDetail = lazy(() => import("./components/AccountDetails"));
//const NewAccount = lazy(() => import("./components/AccountForm"));
const AdDetail = lazy(() => import("./components/AdDetail"));
const AdsList = lazy(() => import("./components/AdList"));
const ContactsList = lazy(() => import("./components/ContactsList"));
const Login = lazy(() => import("./components/Login"));
const Logout = lazy(() => import("./components/Logout"));
const UserDetail = lazy(() => import("./components/Settings"));
const TasksList = lazy(() => import("./components/TaskList"));
//const NewTask = lazy(() => import("./components/TaskForm"));
const NewAdForm = lazy(() => import("./components/NewAdForm"));
const AccountsSearch = () => <>Account Search</>;
//const AccountsSearch = lazy(() => import("./components/AccountsSearch"));
const AdsSearch = () => <>AdsSearch</>;
const AgingsetDetail = ({ type = "" }) => <>Agingset</>;
const AgingsetsList = () => <>AgingsetsList</>;
const AgingsetsSearch = () => <>AgingsetsSearch</>;
const CalendarView = lazy(() => import("./components/CalendarView"));
const ComposeEmail = ({ type = "" }) => <>ComposeEmail</>;
const ContactDetail = () => <>ContactDetail</>;
const ContactsSearch = () => <>ContactsSearch</>;
const ContractDetail = () => <>ContractDetail</>;
const ContractsList = () => <>ContractsList</>;
const ContractsSearch = () => <>ContractsSearch</>;
const CreditHold = ({ view = "" }) => <>CreditHoldList</>;
const DocumentDetail = () => <>DocumentDetail</>;
const DocumentsList = () => <>DocumentsList</>;
const DocumentsSearch = () => <>DocumentsSearch</>;
const HistoryRecordDetail = () => <>HistoryRecordDetail</>;
const HistoryRecordsList = () => <>HistoryRecordsList</>;
const HistoryRecordsSearch = () => <>HistoryRecordsSearch</>;
const InsertionDetail = () => <>InsertionDetail</>;
const InsertionsList = () => <>InsertionList</>;
//const InsertionsList = lazy(() => import("./components/InsertionsList"));
const InsertionsSearch = () => <>InsertionsSearch</>;
const InvoiceDetail = () => <>InvoiceDetail</>;
const InvoicesList = ({ type = "" }) => <>InvoicesList</>;
const InvoicesSearch = () => <>InvoicesSearch</>;
const IssueCreationWizard = () => <>IssueCreationWizard</>;
const IssueDetail = () => <>IssueDetail</>;
const IssuesList = ({ type = "" }) => <>IssuesList</>;
const IssuesSearch = () => <>IssuesSearch</>;
const Labels = ({ type = "", source = "" }) => <>Labels</>;
const LetterDetail = () => <>LetterDetail</>;
const LettersList = () => <>LettersList</>;
const LineitemsList = () => <>LineitemsList</>;
const LineitemsSearch = () => <>LineitemsSearch</>;
const Mailchimp = () => <>Mailchimp</>;
const MessageDetail = () => <>MessageDetail</>;
const MessagesList = () => <>MessagesList</>;
const MessagesSearch = () => <>MessagesSearch</>;
const NewContact = () => <>NewContact</>;
const NewLetter = () => <>NewLetter</>;
const NewPayment = () => <>NewPayment</>;
const PackageDetail = () => <>PackageDetail</>;
const PackagePrebill = () => <>PackagePrebill</>;
const PackagesList = () => <>PackagesList</>;
const PackagesSearch = () => <>PackagesSearch</>;
const PaymentDetail = () => <>PaymentDetail</>;
const SubscriptionRenewals = () => <>RenewalsList</>;
const RenewContract = () => <>RenewContract</>;
const RenewPackage = () => <>RenewPackage</>;
const RenewSubscription = () => <>RenewSubscription</>;
const Statement = () => <>Statement</>;
const SubscriptionDetail = () => <>SubscriptionDetail</>;
const SubscriptionsList = ({ status = "", type = "" }) => <>SubscriptionsList</>;
const SubscriptionsSearch = () => <>SubscriptionsSearch</>;
const TasksSearch = () => <>TasksSearch</>;
const TransactionDetail = () => <>TransactionDetail</>;
const TransactionsList = ({ type = "" }) => <>TransactionsList</>;
const TransactionsSearch = () => <>TransactionsSearch</>;
const UsersList = () => <>UsersList</>;
function offsetFromCursor(items, cursor, readField) {
  // Search from the back of the list because the cursor we're
  // looking for is typically the ID of the last item.
  for (let i = items.length - 1; i >= 0; --i) {
    const item = items[i];
    // Using readField works for both non-normalized objects
    // (returning item.id) and normalized references (returning
    // the id field from the referenced entity object), so it's\
    // a good idea to use readField when you're not sure what
    // kind of elements you're dealing with.
    if (readField("id", item) === cursor) {
      // Add one because the cursor identifies the item just
      // before the first item in the page we care about.
      return i + 1;
    }
  }   // Report that the cursor could not be found.
  return -1;
}

type User = {
  id?: string
  userId?: string
  lastName?: string
  firstName?: string
  sessionId?: string
  defaultMMTab?: string
  avatar_url?: string
  initials?: string
  role?: string
  preferred_username: string
}
export type IAppContext = {
  user?: User,
  setUser: Function,
  isLoggedIn?: boolean;
  setIsLoggedIn?: (isLoggedIn: boolean) => void;
  isAdmin?: boolean;
  setIsAdmin?: (isAdmin: boolean) => void;
  isSuperAdmin?: boolean;
  setIsSuperAdmin?: (isSuperAdmin: boolean) => void;
  isUser?: boolean;
  setIsUser?: (isUser: boolean) => void;
  isClient?: boolean;
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        feed: {
          keyArgs: ["type"],
          merge(existing, incoming, options) {
            const { args, readField } = options;
            //@ts-ignore
            const { cursor } = args;
            const merged = existing ? existing.slice(0) : [];
            let offset = offsetFromCursor(merged, cursor, readField);
            // If we couldn't find the cursor, default to appending to
            // the end of the list, so we don't lose any data.
            if (offset < 0) offset = merged.length;
            // Now that we have a reliable offset, the rest of this logic
            // is the same as in offsetLimitPagination.
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i];
            }
            return merged;
          },
          // If you always want to return the whole list, you can omit
          // this read function.
          read(existing, options) {
            const { args, readField } = options;
            //@ts-ignore
            const { cursor, limit = existing.length } = args;
            if (existing) {
              let offset = offsetFromCursor(existing, cursor, readField);
              if (offset < 0) offset = 0;
              return existing.slice(offset, offset + limit);
            }
          },
        },
      },
    },
  }
});

export const AppContext = React.createContext<IAppContext | null>(null);


export type FoundSet = {
  id: string;
  sort: any;
  tableName: string;
  activeRecordNumber: number;
  foundSetId: string;
  activeRecId: string;
  nextRecId: string;
  prevRecId: string;
  activeRow: string;
  recordCount: string;
};
// Create Reactive variable
export const foundSet = cache.makeVar<FoundSet | null>(null);
function App({ config }) {
  const { clientId } = config;
  let userFromToken;
  try {
    userFromToken = JSON.parse(localStorage.getItem("user") || '');
  } catch (e) {
    userFromToken = null;
  }
  const navigate = useNavigate();
  const [user, setUser] = useState(userFromToken);
  //newrelic.setCustomAttribute("pre1id", user?.pre1id);

  var url = new URL("/pre1/graphql", window.location.href);
  //newrelic.addRelease('smartpublisher', 'dev');
  const httpLink = createUploadLink({
    uri: process.env.NODE_ENV === "development" ? `http://${window.location.hostname}:4000/graphql` : url.href
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("access_token");
    // return the headers to the context so httpLink can read them
    return token
      ? {
        headers: {
          ...headers,
          "x-pre1-client-id": clientId,
          authorization: token ? `Bearer ${token}` : "",
        },
      } : {
        headers: {
          ...headers,
          "x-pre1-client-id": clientId,
        }
      };

  });
  /*   const retryLink = new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 5,
        retryIf: (error, _operation) => !!error
      }
    }); */
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        if (message.includes("Expired Session") || message.includes("Context creation failed: jwt expired") || message.includes("Authentication Error")) {
          setUser(null);
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          if (window.location.pathname !== "/login" && window.location.pathname !== "/logout") navigate("/login");
        } else {
          toast.error(message);
        }
        //newrelic.noticeError(new Error(`[Network error]: ${networkError}`));
      });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`, networkError.message);
    //if (networkError) newrelic.noticeError(new Error(`[Network error]: ${networkError}`));
  });


  //Other links to consider.... 
  // apollo-link-debounce -- for public requests debounce will prevent someone from refreshing a ton of times and creating a huge number of requests to the server. 
  //apollo-link-queue -- needed for offline mode - hook watches for going offline, queue starts growing while offline and then when a retry succeeds the queued requests go through
  /*
  url.protocol = url.protocol.replace('https', 'wss');
  const wsLink = new WebSocketLink({
    uri: url.href,
    options: {
      reconnect: true,
      connectionParams: {
        authToken: localStorage.getItem('access_token')
      }
    }
  });
  const splitLink = split(   ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  //wsLink,
  httpLink
  );
  */

  const client = new ApolloClient({
    cache,
    link: from([
      authLink,
      apolloLogger,
      errorLink,
      //retryLink,
      //splitLink
      httpLink,
    ]),
    name: "smartpublisher-web",
    version: "1.0",
    typeDefs: dataApiTypeDefs
  });
  const defaultMyAccountsTableView = "table";
  return (
    <AppContext.Provider value={{ user, setUser }} >
      <IntlProvider locale={"us"}>
        <ApolloProvider client={client}>
          <Suspense
            fallback={
              <div className="dark:bg-darkbrand-800 flex justify-center items-center min-h-screen">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route index element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/" element={<Layout />}>
                <Route path="home" element={<Home />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="calendar" element={<CalendarView />} />
                <Route path="accounts">
                  <Route index element={<AccountsList type={defaultMyAccountsTableView || "sales"} />} />
                  <Route path=":accountId">
                    <Route index element={<AccountDetail />} />
                    <Route path="ad/:adId" element={<AdDetail />} />
                    <Route path="ad/:adId/renew" element={<RenewAd />} />
                    <Route path="ad/new" element={<NewAdForm />} />
                    <Route path="ads" element={<AdsList />} />
                    <Route path="ads/quotes" element={<AdsList />} />
                    <Route path="contact/new" element={<NewContact />} />
                    <Route path="contacts" element={<ContactsList />} />
                    <Route path="contract/:contractId" element={<ContractDetail />} />
                    <Route path="contract/:contractId/renew" element={<RenewContract />} />
                    <Route path="contracts" element={<ContractsList />} />
                    <Route path="documents" element={<DocumentsList />} />
                    <Route path="documents/:documentId" element={<DocumentDetail />} />
                    <Route path="history" element={<HistoryRecordsList />} />
                    <Route path="insertion/:insertionId" element={<InsertionDetail />} />
                    <Route path="insertions" element={<InsertionsList />} />
                    <Route path="invoice/:invoiceId" element={<InvoiceDetail />} />
                    <Route path="invoices" element={<InvoicesList />} />
                    <Route path="lineitems" element={<LineitemsList />} />
                    <Route path="lineitems/:transIndexId" element={<LineitemsList />} />
                    <Route path="message/:messageId" element={<LetterDetail />} />
                    <Route path="message/new" element={<NewLetter />} />
                    <Route path="messages" element={<LettersList />} />
                    <Route path="package/:packageId" element={<PackageDetail />} />
                    <Route path="package/:packageId/prebill" element={<PackagePrebill />} />
                    <Route path="package/:packageId/renew" element={<RenewPackage />} />
                    <Route path="packages" element={<PackagesList />} />
                    <Route path="payment/:paymentId" element={<PaymentDetail />} />
                    <Route path="payment/new" element={<NewPayment />} />
                    <Route path="payments" element={<TransactionsList type="payments" />} />
                    <Route path="statement" element={<Statement />} />
                    <Route path="subscription/:subscriptionId" element={<SubscriptionDetail />} />
                    <Route path="subscription/:subscriptionId/email" element={<ComposeEmail />} />
                    <Route path="subscription/:subscriptionId/renew" element={<RenewSubscription />} />
                    <Route path="subscriptions" element={<SubscriptionsList status="Ad" />} />
                    <Route path="subscriptions/quotes" element={<SubscriptionsList status="Quote" />} />
                    <Route path="tasks" element={<TasksList />} />
                    <Route path="transactions" element={<TransactionsList />} />
                    <Route path="transactions/:transactionId" element={<TransactionDetail />} />
                    <Route path="transactions/credits" element={<TransactionsList />} />
                    <Route path="transactions/payments" element={<TransactionsList />} />
                  </Route>
                  <Route path="sales" element={<AccountsList type="sales" />} />
                  <Route path="marketing" element={<AccountsList type="marketing" />} />
                  <Route path="accounting" element={<AccountsList type="accounting" />} />
                  <Route path="map" element={<AccountsList type="map" />} />
                  <Route path="search" element={<AccountsSearch />} />
                </Route>
                <Route path="ads">
                  <Route index element={<AdsList />} />
                  <Route path=":adId" element={<AdDetail />} />
                  <Route path="details" element={<AdsList />} />
                  <Route path="production" element={<AdsList />} />
                  <Route path="search" element={<AdsSearch />} />
                </Route>
                <Route path="agingsets">
                  <Route index element={<AgingsetsList />} />
                  <Route path=":agingsetId" element={<AgingsetDetail />} />
                  <Route path=":agingsetId/creditHold" element={<CreditHold view="aging" />} />
                  <Route path=":agingsetId/detail" element={<AgingsetDetail type="detail" />} />
                  <Route path="search" element={<AgingsetsSearch />} />
                </Route>
                <Route path="contacts">
                  <Route index element={<ContactsList />} />
                  <Route path=":contactId" element={<ContactDetail />} />
                  <Route path="email" element={<ComposeEmail type="batch" />} />
                  <Route path="envelopes" element={<Labels type="envelope" />} />
                  <Route path="labels" element={<Labels source="contacts" />} />
                  <Route path="mailchimp" element={<Mailchimp />} />
                  <Route path="search" element={<ContactsSearch />} />
                </Route>
                <Route path="contracts">
                  <Route index element={<ContractsList />} />
                  <Route path=":contractId" element={<ContractDetail />} />
                  <Route path="search" element={<ContractsSearch />} />
                </Route>
                <Route path="documents">
                  <Route index element={<DocumentsList />} />
                  <Route path=":documentId" element={<DocumentDetail />} />
                  <Route path="search" element={<DocumentsSearch />} />
                </Route>
                <Route path="history">
                  <Route index element={<HistoryRecordsList />} />
                  <Route path=":historyId" element={<HistoryRecordDetail />} />
                  <Route path="search" element={<HistoryRecordsSearch />} />
                </Route>
                <Route path="insertions">
                  <Route index element={<InsertionsList />} />
                  <Route path=":insertionId" element={<InsertionDetail />} />
                  <Route path="production" element={<InsertionsList />} />
                  <Route path="search" element={<InsertionsSearch />} />
                  <Route path="web" element={<InsertionsList />} />
                  <Route path="wide" element={<InsertionsList />} />
                </Route>
                <Route path="issues">
                  <Route index element={<IssuesList />} />
                  <Route path=":issueId" element={<IssueDetail />} />
                  <Route path="create" element={<IssuesList type="create" />} />
                  <Route path="search" element={<IssuesSearch />} />
                  <Route path="wizard" element={<IssueCreationWizard />} />
                </Route>
                <Route path="invoices">
                  <Route index element={<InvoicesList />} />
                  <Route path=":invoiceId" element={<InvoiceDetail />} />
                  <Route path="email" element={<ComposeEmail />} />
                  <Route path="extended" element={<InvoicesList type="extended" />} />
                  <Route path="labels" element={<Labels />} />
                  <Route path="search" element={<InvoicesSearch />} />
                </Route>
                <Route path="messages">
                  <Route index element={<MessagesList />} />
                  <Route path=":messageId" element={<MessageDetail />} />
                  <Route path="search" element={<MessagesSearch />} />
                </Route>
                <Route path="packages">
                  <Route index element={<PackagesList />} />
                  <Route path=":packageId" element={<PackageDetail />} />
                  <Route path="search" element={<PackagesSearch />} />
                </Route>
                <Route path="subscriptions">
                  <Route index element={<SubscriptionsList type="summary" />} />
                  <Route path="addresses" element={<SubscriptionsList type="addresses" />} />
                  <Route path="labels" element={<Labels />} />
                  <Route path="renewals" element={<SubscriptionRenewals />} />
                  <Route path="schedule" element={<SubscriptionsList type="schedule" />} />
                  <Route path="search" element={<SubscriptionsSearch />} />
                </Route>
                <Route path="tasks">
                  <Route index element={<TasksList />} />
                  <Route path="email" element={<TasksList type="batch" />} />
                  <Route path="envelope" element={<Labels type="envelope" />} />
                  <Route path="labels" element={<Labels />} />
                  <Route path="search" element={<TasksSearch />} />
                </Route>
                <Route path="lineitems">
                  <Route index element={<LineitemsList />} />
                  <Route path="extended" element={<LineitemsList />} />
                  <Route path="search" element={<LineitemsSearch />} />
                </Route>
                <Route path="transactions">
                  <Route index element={<TransactionsList />} />
                  <Route path="extended" element={<TransactionsList type="extended" />} />
                  <Route path="search" element={<TransactionsSearch />} />
                </Route>
                <Route path="users">
                  <Route index element={<UsersList />} />
                  <Route path=":userId" element={<UserDetail />} />
                </Route>
                <Route path="settings">
                  <Route index element={<UserDetail />} />
                </Route>
                <Route path="admin">
                  <Route index element={<UserDetail />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Suspense>
        </ApolloProvider>
      </IntlProvider>
    </AppContext.Provider >);
}


const Home = () => {

  const context = useContext<IAppContext | null>(AppContext);
  const { user } = context || {};
  const { defaultMMTab } = user || {};
  switch (defaultMMTab) {
    case "Dashboard":
      return <Navigate replace to={"/dashboard"} />;
    default:
      alert("no route for " + defaultMMTab);
      return <Navigate replace to={"/accounts"} />;
  }
}










const BuildTarget = ({ config }) => {
  switch (process.env.REACT_APP_BUILD_TARGET) {
    case "fileupload":
      return <FileUpload />
    case "classifiedadeditor":
      return <ClassifiedAdEditor />
    case "calendar":
      return <CalendarEmbed />
    default:
      return <App config={config} />
  }
}
export default BuildTarget;
