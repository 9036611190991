import { gql, useQuery } from "@apollo/client";
import { MailIcon, PhoneIcon, } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
const year = new Date().getFullYear()

export const Footer = () => {
    const { data, error, loading } = useQuery(gql`
        query getSystemStatus{
            getSystemStatus {
                clientId
                clientName
                dateFormat
                email
                filemakerServerHostname
                filemakerServerVersion
                fmsApiName
                fmsBuildDate
                fmsVersion
                initials
                pre1Id
                pre1SupportUrl
                preferred_username
                scope
                sid
                smartpublisherFilemakerUrl
                smartpublisherVersion
                smartpublisherWebdirectUrl
                timeFormat
                timeStampFormat
                userFull
                userId
                userRecId
            }
        }
    `, {
        notifyOnNetworkStatusChange: true
    });
    if (error) return <div>Error!</div>;
    return (
        <footer className="flex flex-col sm:flex-row p-1 mt-5 dark:bg-darkbrand-500 bg-gray-200 border-t border-gray-300 w-full justify-between">
            <p className="text-center sm:inline text-xs"><span>&copy; {year} </span><a className="decoration-dotted underline" href="https://www.pre1.com">Pre1 Software</a></p>
            <p className="text-center flex text-xs justify-between"><a className="flex " href="mailto:support@pre1.com"><MailIcon className="w-4 h-4 mr-1" />support@pre1.com</a><StarIcon className="w-4 h-4 mx-4" /><a className="flex " href="tel:+15032887500"><PhoneIcon className="w-4 h-4 mr-1"></PhoneIcon>(503) 288-7500</a></p>
            <p className="text-center sm:inline text-xs">{!loading ? (<> <span className={`cursor-default ${error ? "text-red-700" : ""}`} title={`${data.getSystemStatus.filemakerServerHostname} ${data.getSystemStatus.filemakerServerVersion}`}><span>SmartPublisher&trade;</span> {`v${data.getSystemStatus.smartpublisherVersion}`}</span> <span>{`${data.getSystemStatus.clientName} ${process.env.REACT_APP_VERSION}`}</span></>) : null} </p>
        </footer>
    )
}
