import React, { useState, Fragment, useContext, useEffect } from "react";
import { Transition, Dialog, Combobox } from "@headlessui/react";
import { SearchIcon, SupportIcon, ExclamationIcon, OfficeBuildingIcon } from "@heroicons/react/solid";
import { useNavigate, useParams } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import { CakeIcon, CalendarIcon, CashIcon, CreditCardIcon, CurrencyDollarIcon, DocumentTextIcon, GiftIcon, NewspaperIcon, TemplateIcon, UserIcon } from "@heroicons/react/outline";
import { LayoutContext } from "../Layout";
import { useTranslation } from "react-i18next";
import { AccountForm } from "./AccountForm";
import { NewContactForm } from "./NewContactForm";
import { NewTaskForm } from "./NewTaskForm";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GET_ACCOUNTS = gql`
  query getAccounts($input: FindInput) {
    findAccounts(input: $input) {
      accounts {
        id
        company
        acctCategory
        lastDisplayFU
        lastRunDate
        displayRep{
            initials
            userId
            firstName
            lastName
        }
        class1Rep{
            initials
            userId
            firstName
            lastName
        }
        contacts {
          contactFirst
          contactLast
        }
        accountID
      }
      count
      total
    }
  }
`;

const CommandPaletteContext = React.createContext({});

export const CommandPalette = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountId } = useParams();
  const [commandPaletteOpen, setCommandPaletteOpen] = useState(false);
  const { setShowSlideOver, setSlideOverElement }: any = useContext(LayoutContext);
  const [commandPaletteSearchValue, setCommandPaletteSearchValue] = useState("");
  const query = commandPaletteSearchValue.toLowerCase().replace(/^[#>]/, '')
  const [findAccounts, { data, loading }] = useLazyQuery(GET_ACCOUNTS, {
    variables: {
      input: {
        criteria: [{
          criterion: [
            { "key": "cCompanyAlphaNum", value: "=" + commandPaletteSearchValue.replace(/#(\S+)/g, '') }
          ]
        }]
      }
    }
  });
  const filteredAccounts = loading || !data ? [] : data.findAccounts?.accounts ? data.findAccounts.accounts : [];
  useEffect(() => {
    if (commandPaletteSearchValue[0] !== ">" && commandPaletteSearchValue.length > 3) {
      findAccounts();
    }
  }, [commandPaletteSearchValue])
  const searchValueParts = commandPaletteSearchValue.includes("#") ? commandPaletteSearchValue.split("#") : "";
  const moduleValue = Array.isArray(searchValueParts) && searchValueParts.length > 0 ? searchValueParts[1].split(" ") : "";
  const moduleBeingSearched = Array.isArray(moduleValue) ? moduleValue[0] : "";
  return (
    <CommandPaletteContext.Provider value={{ setCommandPaletteOpen, setCommandPaletteSearchValue }}>
      <Transition.Root show={commandPaletteOpen} as={Fragment} afterLeave={() => {
        setCommandPaletteSearchValue("");
      }} appear>
        <Dialog as="div" className="relative z-10 sm:mt-0 " onClose={() => {
          setCommandPaletteOpen(false);
        }}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="mt-20 sm:mt-0 mx-auto max-w-xl transform divide-y divide-gray-100  dark:text-gray-900 overflow-hidden rounded-xl bg-white dark:bg-gray-900 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox value={commandPaletteSearchValue} onChange={(item: any) => {
                  if (item.id) {
                    navigate(`accounts/${item.id}`);
                  } else {
                    if (item.includes("::")) {
                      const words = commandPaletteSearchValue.split(' ');
                      const cleaned = words.length > 1 ? words.splice(words.length - 2, 1).join(' ') : " ";
                      setCommandPaletteSearchValue((cleaned + " " + item).trim() + "=");
                    } else {
                      console.log("SELECTED", item);
                      setCommandPaletteOpen(false);
                      switch (item) {
                        case "newAccount": setSlideOverElement(<AccountForm></AccountForm>); break;
                        case "newContact": setSlideOverElement(<NewContactForm accountId={accountId}></NewContactForm>); break;
                        case "newTask": setSlideOverElement(<NewTaskForm accountId={accountId}></NewTaskForm>); break;
                        default: break;
                      }
                      setShowSlideOver(true);
                    }
                  }
                }}>
                  <div className="relative">
                    <SearchIcon className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400 dark:text-gray-50" aria-hidden="true" />
                    <Combobox.Input className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 dark:text-gray-50 placeholder-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." onChange={(event) => setCommandPaletteSearchValue(event.target.value)} />
                  </div>
                  <Combobox.Options static className="max-h-80 scroll-py-10 scroll-py-10 scroll-pb-2 scroll-pb-2 space-y-4 overflow-y-auto p-1 pb-2">
                    {filteredAccounts.length > 0 && (
                      <li>
                        <h2 className="text-xs font-semibold text-gray-900  dark:text-gray-50">Accounts</h2>
                        <ul className="mt-2 text-sm text-gray-700">
                          {filteredAccounts.map((account) => (
                            <Combobox.Option key={account.id} value={account} className={({ active }) => classNames('flex cursor-default select-none items-center px-4 py-2', active && 'bg-indigo-600 text-white')}>
                              {({ active }) => (
                                <>
                                  <OfficeBuildingIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                  <span className="ml-3 flex-auto truncate dark:text-gray-50">{account.company}</span>
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )}
                    {commandPaletteSearchValue.includes("#") && (
                      <li>
                        <h2 className="text-xs font-semibold text-gray-900 dark:text-gray-200">{`Search Fields within ${moduleBeingSearched}`}</h2>
                        <ul className="-mx-4 mt-2 text-sm text-gray-700 dark:text-gray-300">
                          {[
                            "accountID",
                            "acctCategory",
                            "acctDiscountPercent",
                            "acctDiscountType",
                            "acctType",
                            "address",
                            "address2",
                            "advertiserPortalAgreement",
                            "agencyID",
                            "alert",
                            "annualAdBudget",
                            "annualRevenue",
                            "billCity",
                            "billCompany",
                            "billCountry",
                            "billCustomerLoginPassword",
                            "billEmail",
                            "billFirst",
                            "billingCycle",
                            "billLast",
                            "billPhone",
                            "billState",
                            "billStreet",
                            "billSuite",
                            "billToID",
                            "billZip",
                            "city",
                            "classifiedRep1",
                            "classifiedRep2",
                            "commissionExpireString",
                            "commRateSale",
                            "company",
                            "contactQuality",
                            "country",
                            "creator",
                            "creditHold",
                            "creditLimit",
                            "creditStatus",
                            "crmshowhiddencontacts",
                            "crmshowsubscontacts",
                            "StringCreated",
                            "StringEstablished",
                            "StringModified",
                            "discountLog",
                            "displayFUCount",
                            "displayRepID",
                            "doNotCall",
                            "edition",
                            "financeChargesYN",
                            "firstFUString",
                            "firstPaymentString",
                            "firstRunString",
                            "invoiceByAd",
                            "invoiceFreeClass",
                            "invoiceFreeDisp",
                            "invoicePrepaidClass",
                            "invoicePrepaidDisp",
                            "invoices_Email",
                            "invoices_PrInt",
                            "invoiceType",
                            "lastClass1FU",
                            "lastClass2FU",
                            "lastCollectionFU",
                            "lastDisplayFU",
                            "lastPaymentAmount",
                            "lastPaymentString",
                            "lastRunString",
                            "latitude",
                            "longitude",
                            "mailingCode",
                            "mailingList",
                            "mergeLetter",
                            "modifier",
                            "Notes",
                            "oldAcctID",
                            "primaryPublicationId",
                            "primPubID",
                            "recurringChargeActive",
                            "recurringChargeAmt",
                            "recurringChargeCardorcheck",
                            "recurringChargeChoice",
                            "refreshTs",
                            "rep2type",
                            "requirePo",
                            "sacctCredit",
                            "sdueunpublishedIa",
                            "sicCode",
                            "sinvoiceDue",
                            "state",
                            "statementsemail",
                            "statementsprint",
                            "status",
                            "subscriptionsonly",
                            "sunearnedincome",
                            "taxableYnAdvertising",
                            "taxableYnSubscriptions",
                            "taxrateadvertising",
                            "taxratesubscriptions",
                            "tearsheetsprintcbcount",
                            "tearsheetsprintcwcount",
                            "tearsheetsprintdisplaycount",
                            "terms",
                            "termsReqChange",
                            "termsReqString",
                            "tsModifiedClient",
                            "webAddress",
                            "zip",
                            "balance",
                            "creditBalance",
                            "invoiceBalance",
                            "contacts",
                            "ads",
                            "transactions",
                            "invoices",
                            "tasks",
                            "displayRep",
                            "class1Rep",
                            "class2Rep",
                            "primaryPublication",
                          ].map((fieldName) => (<Combobox.Option key={"accounts::" + fieldName} value={"#Accounts::" + fieldName} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1${active && 'bg-indigo-600 text-white'}`} >
                            <span className="ml-3 flex-auto truncate">{fieldName}</span>
                          </Combobox.Option>))}
                        </ul>
                      </li>
                    )}
                    {!commandPaletteSearchValue.startsWith(">") && (
                      <li>
                        <h2 className="text-xs font-semibold text-gray-900 dark:text-gray-200">Search by Module</h2>
                        <ul className="divide-y -mx-1 text-sm text-gray-700 dark:text-gray-300">
                          <Combobox.Option key={"accounts"} value={"#Accounts"} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1  pointer-cursor ${active && 'bg-indigo-600 text-white'}`}>
                            <span className="ml-3 flex-auto truncate">{t("module.accounts")}</span>
                          </Combobox.Option>
                          <Combobox.Option key={"Contacts"} value={"Contacts"} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1  pointer-cursor ${active && 'bg-indigo-600 text-white'}`}>
                            <span className="ml-3 flex-auto truncate">{t("module.contacts")}</span>
                          </Combobox.Option>
                          <Combobox.Option key={"Tasks"} value={"Tasks"} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1  pointer-cursor ${active && 'bg-indigo-600 text-white'}`}>
                            <span className="ml-3 flex-auto truncate">{t("module.tasks")}</span>
                          </Combobox.Option>
                          <Combobox.Option key={"Correspondence"} value={"Correspondence"} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1  pointer-cursor ${active && 'bg-indigo-600 text-white'}`}>
                            <span className="ml-3 flex-auto truncate">{t("module.letters")}</span>
                          </Combobox.Option>
                          <Combobox.Option key={"Ads"} value={"Ads"} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1  pointer-cursor ${active && 'bg-indigo-600 text-white'}`}>
                            <span className="ml-3 flex-auto truncate">{t("module.ads")}</span>
                          </Combobox.Option>
                          <Combobox.Option key={"Contracts"} value={"Contracts"} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1  pointer-cursor ${active && 'bg-indigo-600 text-white'}`}>
                            <span className="ml-3 flex-auto truncate">{t("module.contracts")}</span>
                          </Combobox.Option>
                          <Combobox.Option key={"Packages"} value={"Packages"} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1  pointer-cursor ${active && 'bg-indigo-600 text-white'}`}>
                            <span className="ml-3 flex-auto truncate">{t("module.packages")}</span>
                          </Combobox.Option>
                          <Combobox.Option key={"Subscriptions"} value={"Subscriptions"} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1  pointer-cursor ${active && 'bg-indigo-600 text-white'}`}>
                            <span className="ml-3 flex-auto truncate">{t("module.subscriptions")}</span>
                          </Combobox.Option>
                          <Combobox.Option key={"Insertions"} value={"Insertions"} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1  pointer-cursor ${active && 'bg-indigo-600 text-white'}`}>
                            <span className="ml-3 flex-auto truncate">{t("module.insertions")}</span>
                          </Combobox.Option>
                          <Combobox.Option key={"Issues"} value={"Issues"} className={({ active }) => `flex cursor-default select-none items-center px-4 py-1 pointer-cursor ${active && 'bg-indigo-600 text-white'}`}>
                            <span className="ml-3 flex-auto truncate">{t("module.issues")}</span>
                          </Combobox.Option>
                        </ul>
                      </li>
                    )}
                    {commandPaletteSearchValue === '>' && (
                      <li className="">
                        <h2 className="sr-only">Quick actions</h2>
                        <ul className="divide-y divide-gray-100 text-sm text-gray-700 -mx-1">
                          <Combobox.Option key={"newAccount"} value={"newAccount"} className={({ active }) => classNames('flex cursor-default select-none items-center px-2 py-1', active && 'bg-indigo-600 text-white ')}>
                            {({ active }) => (
                              <>
                                <OfficeBuildingIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                <span className="ml-3 flex-auto truncate  dark:text-gray-50">Create New Account</span>
                                <span className={classNames('ml-3 flex-none text-xs font-semibold', active ? 'text-indigo-100' : 'text-gray-400')}>
                                  <kbd className="font-sans">⌘</kbd>
                                  <kbd className="font-sans">N</kbd>
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                          {accountId ?
                            <>
                              <Combobox.Option key={"newContact"} value={"newContact"} className={({ active }) => classNames('flex cursor-default select-none items-center px-2 py-1', active && 'bg-indigo-600 text-white ')}>
                                {({ active }) => (
                                  <>
                                    <UserIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                    <span className="ml-3 flex-auto truncate  dark:text-gray-50">Create New Contact</span>
                                    <span className={classNames('ml-3 flex-none text-xs font-semibold', active ? 'text-indigo-100' : 'text-gray-400')}>
                                      <kbd className="font-sans">⌘</kbd>
                                      <kbd className="font-sans">A</kbd>
                                    </span>
                                  </>
                                )}
                              </Combobox.Option>
                              <Combobox.Option key={"newTask"} value={"newTask"} className={({ active }) => classNames('flex cursor-default select-none items-center px-2 py-1', active && 'bg-indigo-600 text-white ')}>
                                {({ active }) => (
                                  <>
                                    <CalendarIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                    <span className="ml-3 flex-auto truncate  dark:text-gray-50">Create New Task</span>
                                    <span className={classNames('ml-3 flex-none text-xs font-semibold', active ? 'text-indigo-100' : 'text-gray-400')}>
                                      <kbd className="font-sans">⌘</kbd>
                                      <kbd className="font-sans">T</kbd>
                                    </span>
                                  </>
                                )}
                              </Combobox.Option>
                              <Combobox.Option key={"newAd"} value={"newAd"} className={({ active }) => classNames('pointer-events-none bg-gray-200 flex cursor-default select-none items-center px-2 py-1 border-0', active && 'bg-indigo-600 text-white ')}>
                                {({ active }) => (
                                  <>
                                    <TemplateIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                    <span className="ml-3 flex-auto truncate  dark:text-gray-50">Create New Ad</span>
                                    <span className={classNames('ml-3 flex-none text-xs font-semibold', active ? 'text-indigo-100' : 'text-gray-400')}>
                                      <kbd className="font-sans">⌘</kbd>
                                      <kbd className="font-sans">N</kbd>
                                    </span>
                                  </>
                                )}
                              </Combobox.Option>
                              <Combobox.Option key={"newPackage"} value={"newPackage"} className={({ active }) => classNames('pointer-events-none bg-gray-200 flex cursor-default select-none items-center px-2 py-1', active && 'bg-indigo-600 text-white ')}>
                                {({ active }) => (
                                  <>
                                    <GiftIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                    <span className="ml-3 flex-auto truncate  dark:text-gray-50">Create New Package</span>
                                    <span className={classNames('ml-3 flex-none text-xs font-semibold', active ? 'text-indigo-100' : 'text-gray-400')}>
                                      <kbd className="font-sans">⌘</kbd>
                                      <kbd className="font-sans">T</kbd>
                                    </span>
                                  </>
                                )}
                              </Combobox.Option>
                              <Combobox.Option key={"newContract"} value={"newContract"} className={({ active }) => classNames('pointer-events-none  bg-gray-200 flex cursor-default select-none items-center px-2 py-1', active && 'bg-indigo-600 text-white ')}>
                                {({ active }) => (
                                  <>
                                    <DocumentTextIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                    <span className="ml-3 flex-auto truncate  dark:text-gray-50">Create New Contract</span>
                                  </>
                                )}
                              </Combobox.Option>
                              <Combobox.Option key={"newPayment"} value={"newPayment"} className={({ active }) => classNames('pointer-events-none  bg-gray-200 flex cursor-default select-none items-center px-2 py-1', active && 'bg-indigo-600 text-white ')}>
                                {({ active }) => (
                                  <>
                                    <CreditCardIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                    <span className="ml-3 flex-auto truncate  dark:text-gray-50">Create New Payment</span>
                                    <span className={classNames('ml-3 flex-none text-xs font-semibold', active ? 'text-indigo-100' : 'text-gray-400')}>
                                      <kbd className="font-sans">⌘</kbd>
                                      <kbd className="font-sans">W</kbd>
                                    </span>
                                  </>
                                )}
                              </Combobox.Option>
                              <Combobox.Option key={"newDebit"} value={"newDebit"} className={({ active }) => classNames('pointer-events-none bg-gray-200 flex cursor-default select-none items-center px-2 py-1', active && 'bg-indigo-600 text-white ')}>
                                {({ active }) => (
                                  <>
                                    <CurrencyDollarIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                    <span className="ml-3 flex-auto truncate  dark:text-gray-50">Create New Debit Invoice</span>
                                  </>
                                )}
                              </Combobox.Option>
                              <Combobox.Option key={"newCredit"} value={"newCredit"} className={({ active }) => classNames('pointer-events-none bg-gray-200 flex cursor-default select-none items-center px-2 py-1', active && 'bg-indigo-600 text-white ')}>
                                {({ active }) => (
                                  <>
                                    <CashIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                    <span className="ml-3 flex-auto truncate  dark:text-gray-50">Create New Credit</span>
                                  </>
                                )}
                              </Combobox.Option>
                              <Combobox.Option key={"newSubscription"} value={"newSubscription"} className={({ active }) => classNames('pointer-events-none bg-gray-200 flex cursor-default select-none items-center px-2 py-1', active && 'bg-indigo-600 text-white ')}>
                                {({ active }) => (
                                  <>
                                    <NewspaperIcon className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-400')} aria-hidden="true" />
                                    <span className="ml-3 flex-auto truncate  dark:text-gray-50">Create New Subscription</span>
                                  </>
                                )}
                              </Combobox.Option>
                            </> : null}
                        </ul>
                      </li>
                    )}
                  </Combobox.Options>
                  {commandPaletteSearchValue === '?' && (
                    <div className="py-14 px-6 text-center text-sm sm:px-14 ">
                      <SupportIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900">Help with searching</p>
                      <p className="mt-2 text-gray-500">Use this tool to quickly search for accounts.</p>
                    </div>
                  )}

                  {query !== '' && !loading && commandPaletteSearchValue.length > 3 && commandPaletteSearchValue !== '?' && filteredAccounts.length === 0 && filteredAccounts.length === 0 && (
                    <div className="py-14 px-6 text-center text-sm sm:px-14">
                      <ExclamationIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900">No results found</p>
                      <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                    </div>
                  )}

                  {query !== '' && loading && commandPaletteSearchValue.length > 3 && commandPaletteSearchValue !== '?' && filteredAccounts.length === 0 && filteredAccounts.length === 0 && (
                    <div className="py-14 px-6 text-center text-sm sm:px-14">
                      <CakeIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900 dark:text-gray-50">Loading search results</p>
                    </div>
                  )}

                  <div className="flex flex-wrap items-center bg-gray-50 py-2.5 px-4 text-xs text-gray-700 dark:bg-gray-900 dark:text-gray-50">
                    Type{' '}
                    <kbd className={classNames('mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2', commandPaletteSearchValue.startsWith('#') ? 'border-indigo-600 text-indigo-600' : 'border-gray-400 text-gray-900')}>
                      {">"}
                    </kbd>{' '}
                    for additional actions
                    <kbd className={classNames('mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2', commandPaletteSearchValue === '?' ? 'border-indigo-600 text-indigo-600' : 'border-gray-400 text-gray-900')}>
                      ?
                    </kbd>{' '}
                    for help.
                    <kbd className={classNames('mx-1 flex h-5 w-10 items-center justify-center rounded border bg-white font-semibold sm:mx-2', commandPaletteSearchValue === '?' ? 'border-indigo-600 text-indigo-600' : 'border-gray-400 text-gray-900')}>
                      Ctrl
                    </kbd>
                    {' + '}
                    <kbd className={classNames('mx-1 flex h-5 w-12 items-center justify-center rounded border bg-white font-semibold sm:mx-2', commandPaletteSearchValue === '?' ? 'border-indigo-600 text-indigo-600' : 'border-gray-400 text-gray-900')} >
                      Space
                    </kbd>{' to Open.'}
                  </div>
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {children}
    </CommandPaletteContext.Provider>
  );
}

export const useCommandPalette = () => {
  const {
    setCommandPaletteOpen,
    setCommandPaletteSearchValue,
  }: any = useContext(CommandPaletteContext);
  return { setCommandPaletteOpen, setCommandPaletteSearchValue };
}
