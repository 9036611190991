import { useRef, useEffect, createContext, useContext } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";
import { useState } from "react";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

const useMountTransition = (isMounted, unmountDelay) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (isMounted && !isTransitioning) {
      setIsTransitioning(true);
    } else if (!isMounted && isTransitioning) {
      timeoutId = setTimeout(() => setIsTransitioning(false), unmountDelay);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [unmountDelay, isMounted, isTransitioning]);

  return isTransitioning;
};

function createPortalRoot() {
  const drawerRoot = document.createElement("div");
  drawerRoot.setAttribute("id", "drawer-root");

  return drawerRoot;
}

export type ISlideOver = {
  isOpen?: boolean;
  children?:   React.ReactNode;
  className?: string;
  title?: string;
  onClose: any
  position?: string;
  removeWhenClosed?: boolean;
}
/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-drawer-component-with-react-portals
 */
export let SlideOverContext = createContext<ISlideOver>({
  onClose: () => {},
});

export const SlideOver = ({
  isOpen,
  children,
  className = "",
  title = "",
  onClose = () => {},
  position = "left",
  removeWhenClosed = true
}) => {
  const bodyRef = useRef(document.querySelector("body"));
  const portalRootRef = useRef(
    document.getElementById("drawer-root") || createPortalRoot()
  );
  const isTransitioning = useMountTransition(isOpen, 300);

  // Append portal root on mount
  useEffect(() => {
    bodyRef?.current?.appendChild(portalRootRef.current);
    const portal = portalRootRef.current;
    const bodyEl = bodyRef.current ? bodyRef.current : document.body;

    return () => {
      // Clean up the portal when drawer component unmounts
      portal.remove();
      // Ensure scroll overflow is removed
      bodyEl.style.overflow = "";
    };
  }, []);
/* 
  // Prevent page scrolling when the drawer is open
  useEffect(() => {
    const updatePageScroll = () => {
      if (isOpen) {
        bodyRef?.current.style.overflow = "hidden";
      } else {
        bodyRef?.current.style.overflow = "";
      }
    };

    updatePageScroll();
  }, [isOpen]); */

  // Allow Escape key to dismiss the drawer
  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keyup", onKeyPress);
    }

    return () => {
      window.removeEventListener("keyup", onKeyPress);
    };
  }, [isOpen, onClose]);

  if (!isTransitioning && removeWhenClosed && !isOpen) {
    return null;
  }

  return createPortal(
    <SlideOverContext.Provider value={{
      isOpen,
      children,
      className,
      title,
      onClose,
      position,
      removeWhenClosed,
    }}>
      <div className={cn("drawer", position)} role="dialog">

        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 overflow-hidden z-30" onClose={onClose}>
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0 bg-brand-900d opacity-50 pointer-events-none" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="h-full  divide-y divide-gray-200 flex flex-col bg-white dark:bg-darkbrand-900 dark:border-l shadow-xl ">
                      <div className="py-3 px-4 bg-brand-900 sm:px-6 sticky top-0 z-20">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white focus:outline-none" tabIndex={0}>{title}</Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              type="button"
                              className="bg-brand-900 rounded-md  hover:dark:text-white text-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={onClose}
                              tabIndex={0}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex-1">
                        {children}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </SlideOverContext.Provider>,
    portalRootRef.current
  );
};

export default SlideOver;

export type UseSlideOver = {
  isOpen: boolean;
  title: string;
  onClose: Function;
  position  : string;
}
export const useSlideOver = () => {
  const {
    isOpen,
    title,
    onClose,
    position
  } = useContext<ISlideOver>(SlideOverContext)
  return { isOpen, onClose, position, title };
}