import { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react'
import { useNavigate } from "react-router-dom";
import { KEEPALIVE_MINUTES } from "./Layout";
export const SessionExpirationWarningDialog = ({ handleResume, show = false }) => {
    const navigate = useNavigate();
    const now = new Date();
    const time = now.getTime();
    const expiration = time + (KEEPALIVE_MINUTES * 60 * 1000);
    const [remainingTime, setRemainingTime] = useState(expiration - time);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingTime(expiration - new Date().getTime());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    const minutes = Math.floor(remainingTime / 1000 / 60);
    const seconds = Math.floor(remainingTime / 1000) % 60;
    useEffect(() => {
        if (remainingTime <= 0) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
            navigate("/login");
        }
    }, [remainingTime]);
    return (
        <Dialog open={show} onClose={() => navigate("/login")} className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                    <div>
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium dark:text-white text-gray-900">Are you still there?</Dialog.Title>
                            <div className="mt-2">
                                <Dialog.Description className="text-sm dark:text-white text-gray-500">
                                    {`Session is going to time out in ${minutes}:${seconds.toString().padStart(2, '0')} seconds.`}
                                </Dialog.Description>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6 flex">
                        <button
                            type="button"
                            className="m-2 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                            onClick={() => handleResume()}
                        >
                            Resume
                        </button>
                        <button
                            type="button"
                            className="m-2 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                            onClick={() => navigate("/login")}
                        >
                            Logout
                        </button>
                    </div>
                </div>
            </div>

        </Dialog>
    )
};

export default SessionExpirationWarningDialog;