import { Fragment, useContext, useEffect, useState } from "react";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';

import { ClassifiedEditorContext } from "./ClassifiedAdEditor";
type IColorSelect = {
  onSelect: (value: any) => void;
}

export const ColorSelect = ({ onSelect }: IColorSelect) => {
  const { colorList }: any = useContext(ClassifiedEditorContext);
  const options = colorList ? ["Select...", ...colorList].filter((color) => color !== "" && color !== "-").map((color, idx) => {
    return {
      key: idx,
      value: idx == 0 ? "" : `<c${color}>`,
      label: color,
      name: color,
      disabled: idx == 0 ? true : false,
    };
  }) : [];

  const [selected, setSelected] = useState(options[0]);
  useEffect(() => {
    if (selected?.value !== "") {
      onSelect(selected);
      setSelected(options[0]);
    }
  }, [selected]);

  return (<Listbox value={selected} onChange={setSelected}>
    <div className="relative mt-1 z-10">
      <Listbox.Button className="ring-1 ring-inset ring-brand-700 relative w-full cursor-default rounded-lg bg-brand-500  py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-brand-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm hover:bg-brand-400 focus:z-10">
        <span className="block truncate text-brand-50">{selected.name}</span>
        <span className="pointer-events-none absolute text-brand-50 inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon className="h-5 w-5 text-brand-50" aria-hidden="true" />
        </span>
      </Listbox.Button>
      <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" >
        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {options.map((value, idx) => (
            <Listbox.Option value={value} disabled={value.disabled} key={idx}
              className={({ active }) => `relative cursor-default select-none py-1 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : (value.disabled ? 'text-gray-400' : 'text-gray-900')}`}
            >
              {({ selected }) => (
                <>
                  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{value.name}</span>
                  {selected ? (<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"><CheckIcon className="h-5 w-5" aria-hidden="true" /> </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </div>
  </Listbox>);
};
