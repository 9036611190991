import { Link } from "react-router-dom";
export const NotFound = () => {
    return (
        <div className="container mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
            <p className=" text-center text-base">
                <span className="py-4 block">Page Not Found</span>
                <Link className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to="/login">Go to Login</Link>
            </p>
        </div>
    )
}
export default NotFound;