import { Fragment } from 'react';
import { SelectorIcon } from '@heroicons/react/solid';
import { useFormContext, useController } from 'react-hook-form';
import { Listbox, Transition } from '@headlessui/react';

export const Select = ({ name, label, options, labelKey, placeholder = "", idKey, ...props }: any) => {
    const { control } = useFormContext();
    const {
        field: { value, onChange }
    } = useController({ control, name });
    return (
        <div className="flex flex-col w-full ">
            <label htmlFor={name} className="block text-sm w-full font-medium dark:text-white text-gray-700">{label}</label>
            <div className="w-full mt-1">
                <Listbox value={value} onChange={onChange}>
                    {({ open }) => (
                        <div className='relative w-full'>
                            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <span className={`${value ? "" : "dark:text-white text-gray-400"} block truncate`}>{value ? value[labelKey || 'name'] : placeholder}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 dark:text-white text-gray-400" aria-hidden="true" />
                                </span>
                            </Listbox.Button>
                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute w-full z-10 mt-0 overflow-x-hidden bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    {options.map((option, idx) => (
                                        <Listbox.Option
                                            key={`${idx}${idKey ? option[idKey] : option?.id}`}
                                            value={option}
                                            disabled={option.disabled}
                                            className={({ active }) => `${active ? 'text-white bg-indigo-600' : 'dark:text-white text-gray-900'} cursor-default select-none  py-2 pl-3`}
                                        >
                                            {({ active, selected }) => (
                                                <div className={active ? 'bg-blue-500 text-white' : 'bg-whitedark:text-white text-black'}>{option[labelKey || 'name']}</div>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>

                            </Transition>
                        </div>
                    )}
                </Listbox>
            </div>
        </div>);
};
