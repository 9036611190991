import { Calendar, dateFnsLocalizer, Event } from 'react-big-calendar'
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import addHours from 'date-fns/addHours'
import enUS from 'date-fns/locale/en-US'
import format from 'date-fns/format'
import fr from 'date-fns/locale/fr'
import getDay from 'date-fns/getDay'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'

//import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
//import 'react-big-calendar/lib/css/react-big-calendar.css'

import "../react-big-calendar.scss";

import { useMediaQuery } from './Table'
import { useFileMakerScript } from "../FileMaker";

export const CalendarEmbed = () => {
  const isSm = useMediaQuery('(max-width: 640px)');
  const { t } = useTranslation();
  const [events, setEvents] = useState<Event[]>([])
  //Gets events for the calendar
  const [getEvents] = useFileMakerScript("CAL - Get events (start,end)");
  const [range, setRange] = useState({ start: new Date(new Date().getFullYear(), new Date().getMonth(), 1), end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) });
  const [showEvent, setShowEvent]: any = useState(null);
  const dateRangeStart = Array.isArray(range) ? range[0].toLocaleDateString() : range?.start?.toLocaleDateString();
  const dateRangeEnd = Array.isArray(range) ? (range[range.length > 1 ? 6 : 0].toLocaleDateString()) : range?.end?.toLocaleDateString();
  //Runs one time - This undoes the "normal" background for the main SP web app
  useEffect(() => {
    //window.document.body.classList.add('bg-white');
    window.document.body.style.backgroundColor = 'white';
    window.document.body.style.color = "#000000 !important";
    window.document.body.style.overflow = "hidden";
  }, []);
  useEffect(() => {
    const getEventsAsync = async () => {
      const data = await getEvents({start: dateRangeStart, end: dateRangeEnd});
      const taskEvents = data.tasks.map((task) => {
        return {
          id: task.id,
          title: "Task: " + task.description,
          start: new Date(task.callDate),
          end: addHours(new Date(task.callDate), 1),
          allDay: false,
          done: task.done,
          priority: task.priority,
          accountId: task.accountId,
          type: "task",
          description: task.description,
          datedone: task.datedone,
          timestampCreated: task.timestampCreated,
        }
      }
      ) || [];
      const issueEvents = data.issues.map((issue) => {
        return {
          id: issue.id,
          title: `${issue.publication.pubInitials} ${issue.issueId} ${issue?.description ? issue.description : "Regular Issue"}`,
          start: new Date(issue.issueDate),
          end: addHours(new Date(issue.issueDate), 1),
          allDay: true,
          type: "issue"
        }
      }
      ) || [];
      const adExpirationEvents = data.ads.map((ad) => {
        return {
          id: ad.id,
          title: "Ad: " + ad.account.company,
          start: new Date(ad.endDate),
          end: addHours(new Date(ad.endDate), 1),
          allDay: true,
          type: "adExpiration"
        }
      }
      ) || [];
      setEvents([...taskEvents, ...issueEvents, ...adExpirationEvents]);
    };
    //Run the (newly defined and using async/await) function to get the ad data
    getEventsAsync();
  }, [dateRangeStart, dateRangeEnd]);
  /*
  useEffect(() => {
    if (showEvent !== null) {
      if (showEvent.type === 'task') alert("This would show the task in a slideover to the right");
      else if (showEvent.type === 'issue') alert("This would show the issue in a slideover to the right");
      else if (showEvent.type === 'adExpiration') alert("This would show the ad in a slideover to the right");
    }
  }, [showEvent])
  */

  const onRangeChange = useCallback((range) => {
    setRange(range);
  }, [])


  const locales = {
    'en-US': enUS,
    'fr': fr
  }

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  })


  const eventPropGetter = useCallback(
    (event) => ({
      ...(event.type == "task" ? {
        className: event.done ? "bg-hippie-blue-500" : "bg-hippie-blue-300",
      } : (event.type == "issue" ? {
        className: "bg-outer-space-400"
      } : {
        className: "bg-turkish-rose-300",
      }))
    }),
    []
  )

  return (
    <>
      <Calendar
        culture={localStorage.i18nextLng}
        className={"bg-outer-space-50 shadow my-3 p-2 rounded w-full text-xs h-full flex-grow"}
        defaultView={isSm ? 'agenda' : 'month'}
        events={events}
        localizer={localizer}
        onRangeChange={onRangeChange}
        views={['month', 'week', 'day', 'agenda']}
        eventPropGetter={eventPropGetter}
        messages={{
          agenda: t("calendar.agenda"),
          allDay: t("calendar.allDay"),
          month: t("calendar.month"),
          day: t("calendar.day"),
          today: t("calendar.today"),
          previous: t("calendar.previous"),
          next: t("calendar.next"),
          date: t("calendar.date"),
          noEventsInRange: t("calendar.noEventsInRange"),
          time: t("calendar.time"),
          tomorrow: t("calendar.tomorrow"),
          week: t("calendar.week"),
          work_week: t("calendar.work_week"),
          yesterday: t("calendar.yesterday")
      }}
        onSelectEvent={(event) => setShowEvent(event)}
      />
      {showEvent && (<div>{JSON.stringify(showEvent)}</div>)}
    </>
  );
}

export default CalendarEmbed;
