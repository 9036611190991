import { useContext } from 'react'
import { XIcon } from '@heroicons/react/solid'
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { FormProvider, useForm } from 'react-hook-form';
import { LayoutContext } from 'react-filemaker';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useSlideOver } from '../SlideOver';
import { Select } from './Select';
import { Input } from './Input';
import { AddressInput } from './AddressInput';
import { PopupSelect } from './PopupSelect';
import { Tabs } from './Tabs';
export const GET_VALUE_LISTS = gql`
    query getValueLists{
        getValueLists{
            categories{
                value
            }
            quality{
                value
            }
            targetCodes{
                value
            }
        }
    }
`;
export const GET_PUBLICATIONS = gql`
query getPublications{
        getPublications{
            id
            publication
            publicationId
        }
    }
`;

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

//Should not be in this file... ///////////////////////
export const CREATE_ACCOUNT = gql`
    mutation createAccount ($input: CreateAccountInput) {
        createAccount(input: $input){
            id
            accountId
        }
    }
`
export const defaultAccount = {
    company: '',
    firstName: '',
    lastName: '',
    title: '',
    phone: '',
    extension: '',
    phone2Type: '',
    phone2: '',
    email: '',
    website: '',
    primaryPublicationId: '',
    address: '',
    suite: '',
    city: '',
    state: '',
    zip: '',
    quality: '',
    category: '',
    accountCodes: []
};

export const Badge = ({ children, onRemove = null, ...props }: any) => {
    return (<span className="mx-1 inline-flex items-center px-2.5 py-1.5 rounded-full text-sm font-medium leading-5 bg-gray-100 text-gray-500">{children}{onRemove !== null ? <XIcon className='h-4 ml-1 cursor-pointer' onClick={onRemove} /> : null}</span>);
}
export const AccountForm = () => {
    const { onClose } = useSlideOver();
    const { data } = useQuery(GET_VALUE_LISTS);
    const navigate = useNavigate();
    const [createAccount] = useMutation(CREATE_ACCOUNT);
    const layoutContext: any = useContext(LayoutContext);
    const { data: dataPublications } = useQuery(GET_PUBLICATIONS);
    let categoriesVl = data?.getValueLists?.categories || [];
    let accountCodesVl = data?.getValueLists?.targetCodes.map((option, idx) => ({
        id: idx,
        name: option.value,
        ...option
    })) || [];
    let qualityVl = data?.getValueLists?.quality || [];
    let publications = dataPublications?.getPublications || [];
    const onSubmit = async (values, e) => {
        try {
            values.primaryPublicationId = `${values.primaryPublicationId.publicationId}`;
            values.accountCodes = values.accountCodes.map(accountCodeVl => accountCodeVl.value)
            values.category = values?.category?.value;
            values.quality = values?.quality?.value
            const result = await createAccount({ variables: { input: values } });
            toast.custom("Account created successfully");
            navigate(`/accounts/${result.data.createAccount.id}`);
        } catch (e) {
            toast.custom("Error creating account");
        }
    }
    const onError = (error, e) => {
        toast("Error creating account");
        console.log("error account create", error);
    }
    const methods = useForm({
        defaultValues: defaultAccount,
        mode: 'onBlur',
        
    });
    methods.watch("accountCodes");
    const { handleSubmit, formState, setValue, getValues } = methods;

    const handleRemove = (idx: number) => {
        let values = getValues();
        values.accountCodes.splice(idx, 1);
        setValue('accountCodes', values.accountCodes);
    }
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="flex flex-col dark:bg-black divide-slate-300 divide-y h-full  ">
                <div className="space-y-2 flex-grow p-4">
                    <Input name={"company"} label="Account Name" />
                    <Tabs>
                        <AddressInput key="physical" tabLabel="Physical Address" />
                        <AddressInput key="billing" namePrefix={"billing"} tabLabel="Billing Address" />
                    </Tabs>
                    <Input name={"website"} label="Website" staticPrefix="https://" />
                    <Select name={"primaryPublicationId"} label="Primary Publication" placeholder="Select a publication" options={publications} labelKey="publication" />
                    <Select name={"quality"} label="Quality" placeholder="Select a quality level" options={qualityVl} labelKey="value" />
                    <Select name={"category"} label="Category" placeholder="Select an Account Category" options={categoriesVl} labelKey="value" />
                    <PopupSelect name={"accountCodes"} label="Account Codes" allowCustomValue={true} options={accountCodesVl} labelKey="value" />
                    {getValues()?.accountCodes?.length > 0 ? getValues()?.accountCodes.map((accountCode: any, idx) => (<Badge key={idx} onRemove={() => { handleRemove(idx) }}>{accountCode.name}</Badge>)) : null}
                </div>
                <div className="flex-shrink-0 px-4 pt-4  flex justify-between  sticky bottom-0 bg-white z-20">
                    <button type="button" className="bg-white py-2 px-4 dark:bg-darkbrand-600 rounded-md shadow-sm text-sm font-medium dark:text-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                        onClick={() => {
                            console.log("form state", formState.isDirty, formState.dirtyFields, getValues());
                            if (formState.isDirty) {
                                layoutContext.setModal({
                                    title: "Discard Changes?",
                                    message: "Are you sure you want to discard your changes?",
                                    onConfirm: () => {
                                        methods.reset();
                                    },
                                    onCancel: () => {
                                    }
                                })
                            }
                            onClose();
                        }}
                    >
                        Cancel
                    </button> <button type="submit" className="ml-4   border-gray-300  inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500">
                        Save
                    </button>
                </div>

            </form>
        </FormProvider>
    )
}
export default AccountForm;