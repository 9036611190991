import { useState } from 'react';
import { SelectorIcon, CheckIcon } from '@heroicons/react/solid';
import { useFormContext, useController } from 'react-hook-form';
import { Combobox } from '@headlessui/react';
import { classNames } from './AccountForm';

export function PopupSelect({ allowCustomValue, options = [], name, label, labelKey, placeholder = "", idKey, ...props }: any) {
    const [query, setQuery] = useState('');
    const { control } = useFormContext();
    const {
        field: { value, onChange }
    } = useController({ control, name });
    const handleChange = (newSelection: any) => {
        const objectToAdd = {
            id: newSelection.name,
            name: newSelection.name
        };
        setQuery('');
        onChange([...value, objectToAdd]);
    };

    const filteredOptions = query === ''
        ? options
        : options.filter((option: any) => {
            return option.name.toLowerCase().includes(query.toLowerCase());
        });

    return (
        <Combobox as="div" className={"w-full"} value={value} onChange={handleChange}>
            {label ? <Combobox.Label className="block text-sm font-medium dark:text-white text-gray-700">{label}</Combobox.Label> : null}
            <div className="relative mt-1 w-full">
                <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(option: any) => option?.name} />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <SelectorIcon className="h-5 w-5 dark:text-white text-gray-400" aria-hidden="true" />
                </Combobox.Button>
            </div>
            <Combobox.Options className="relative z-10 mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {allowCustomValue && query.length > 0 ? (
                    <Combobox.Option className={({ active }) => `relative cursor-default select-none py-2 pl-3 pr-9 ${active ? 'bg-indigo-600 text-white' : 'dark:text-white text-gray-900'}`} value={{ id: null, name: query }}>
                        Add New Code "{query}"
                    </Combobox.Option>
                ) : null}
                {filteredOptions.length > 0 ? filteredOptions.map((option: any) => (
                    <Combobox.Option className={({ active }) => `relative rounded cursor-default select-none py-2 pl-3 pr-9 ${active ? 'bg-indigo-600 text-white' : 'dark:text-white text-gray-900'}`} key={option.id} value={option}>
                        {({ active, selected }) => (
                            <>
                                <span className={classNames('block truncate', selected && 'font-semibold')}>
                                    {option.name}</span>

                                {selected && (
                                    <span
                                        className={classNames(
                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                            active ? 'text-white' : 'text-indigo-600'
                                        )}
                                    >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                )}
                            </>
                        )}
                    </Combobox.Option>
                )) : null}
            </Combobox.Options>
        </Combobox>
    );
}
