import { useState, useMemo, useEffect } from "react";
import { ChevronRightIcon, ChevronDoubleRightIcon, ChevronDoubleLeftIcon, ChevronLeftIcon } from '@heroicons/react/solid'
import { TableInstance } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
export const Error = () => <div>Error</div>
export type ColumnMeta = {
  phone?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  popover?: boolean;
}
export const NoData = () => (
  <div className="py-8 text-center w-full text-gray-500">
    No results found
  </div>
);
export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
}
const getHeaderTailwindWidthClassName = (size: number) => {
  switch (size) {
    case 12:
      return `w-3 max-w-[12px]`;
    case 24:
      return `w-6  max-w-[24px]`;
    case 32:
      return `w-8  max-w-[32px]`;
    case 36:
      return `w-9  max-w-[36px]`;
    case 40:
      return `w-10  max-w-[40px]`;
    case 48:
      return `w-12  max-w-[48px]`;
    case 60:
      return `w-15  max-w-[60px]`;
    case 64:
      return `w-16  max-w-[64px]`;
    case 72:
      return `w-18  max-w-[72px]`;
    case 80:
      return `w-20  max-w-[80px]`;
    case 88:
      return `w-22  max-w-[88px]`;
    case 96:
      return `w-24  max-w-[96px]`;
    case 112:
      return `w-28 max-w-[112px]`
    case 128:
      return `w-32  max-w-[128px]`;
    case 144:
      return `w-36  max-w-[144px]`;
    case 192:
      return `w-48  max-w-[192px]`;
    case 256:
      return `w-64  max-w-[256px]`;
    case 384:
      return `w-96  max-w-[384px]`;
    default:
      return "w-full";
  }
}
export type ITable = {
  instance: TableInstance<any>
  handleRowClick?: Function
  loading?: Boolean
  showSelectColumnToggle?: Boolean
  showColumnSelectorToggle?: Boolean
  renderSubComponent?: Function
  portal?: Boolean
}
const lazyAndDumbResponsiveClassGetter = (meta) => {
  return `${meta?.sm ? "hidden sm:table-cell" : ""}  ${meta?.phone ? "table-cell" : "hidden"}  ${meta?.md ? "hidden md:table-cell" : ""}  ${meta?.lg ? "hidden lg:table-cell" : ""}  ${meta?.xl ? "hidden xl:table-cell" : ""}`

}
const TableHeaderCell = ({ header }) => (
  <th colSpan={header.colSpan} className={`${header.getSize() % 4 === 0 ? getHeaderTailwindWidthClassName(header.getSize()) : "w-10 xl:w-full"} ${lazyAndDumbResponsiveClassGetter(header.column.columnDef?.meta)} ${header.column.columnDefType === "display" ? "px-0 -mx-2" : "truncate space-x-2 px-2"} py-2   items-center  text-left relative `}>
    {header.isPlaceholder ? null : (
      <div {...{ className: `text-xs ${header.column.getCanSort() ? ' tracking-tighter cursor-pointer select-none ' : ''}`, onClick: header.column.getToggleSortingHandler() }}>
        {header.renderHeader()}
        {{ asc: ' ▲', desc: ' ▼' }[header.column.getIsSorted() as string] ?? null}
      </div>
    )}
  </th>
);
const TableRow = ({ handleRowClick, row, renderSubComponent }) => {
  const isSm = useMediaQuery('(max-width: 768px)');

  return (<>
  <tr className={`${handleRowClick ? "cursor-pointer hover:bg-gray-200 dark:hover:bg-darkbrand-500" : ""} `} onClick={e => {
    if (handleRowClick) handleRowClick(row.original);
  }}>
    {row.getVisibleCells().map((cell, idx) => (<td key={idx + cell.id} className={`${cell.column.columnDef?.meta?.sm ? "hidden sm:table-cell " : ""
      }  ${cell.column.columnDef?.meta?.md ? "hidden md:table-cell" : ""
      }  ${cell.column.columnDef?.meta?.phone ? "table-cell" : "hidden"
      }  ${cell.column.columnDef?.meta?.lg ? "hidden lg:table-cell" : ""
      }  ${cell.column.columnDef?.meta?.xl ? "hidden xl:table-cell" : ""
      }  ${cell.column.columnDefType === "display" || cell.column.columnDef?.meta?.popover ? "px-0" : `${getHeaderTailwindWidthClassName(cell.column.columnDef.size) } truncate px-2`
      }  py-2 align-middle  whitespace-nowrap text-xs`}>{cell.renderCell()}</td>))}
  </tr>
  {renderSubComponent && isSm && (<TableSubRow renderSubComponent={renderSubComponent} row={row}/>)}
  </>);
}
// {renderSubComponent && isSm && (<TableSubRow  row={row} key={"sub"+idx}/>)}
const TableSubRow = ({renderSubComponent, row}) => {
    return (<tr>
      <td colSpan={row.getVisibleCells().length}>
        {renderSubComponent({ row })}
      </td>
    </tr>);
}
//Column Select abandoned implementation
{/*idx === 0 && showSelectColumnToggle ? <div className="absolute  left-1 top-1 w-6 h-6 p-1 dark:text-white text-gray-300 cursor-pointer hover:bg-slate-200 border-gray-300 border hover:border-gray-300 rounded">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="absolute h-4 w-4" viewBox="0 0 1100 1100" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path d="M3204.4,4981.9c-92-40.4-87.5-35.9-964.6-1123.9c-426.2-529.4-782.9-964.6-794.1-964.6c-9,0-143.6,143.6-300.6,316.3c-159.3,179.5-314.1,329.8-352.2,345.5c-38.1,15.7-116.7,22.4-181.7,17.9c-197.4-20.2-318.5-186.2-280.4-388.1c11.2-58.3,112.2-186.2,408.3-520.5c215.4-244.5,433-482.3,482.3-527.2c157-143.6,345.5-136.8,495.8,22.4c76.3,78.5,1794.7,2205.2,1900.1,2351c62.8,87.5,67.3,246.8,9,345.5C3549.8,4990.9,3354.7,5049.2,3204.4,4981.9z" />
          <path d="M4081.5,3669.6c-100.9-67.3-157-172.7-157-298.4c0-121.1,47.1-199.6,166-280.4l74-51.6H6787c2561.9,0,2624.7,0,2707.7,44.9c94.2,47.1,181.7,190.7,181.7,293.9c-2.2,94.2-83,242.3-161.5,289.4c-74,44.9-105.4,44.9-2721.2,44.9C4227.3,3712.2,4144.3,3710,4081.5,3669.6z" />
          <path d="M568.4,1329.8c-74-38.1-114.4-78.5-150.3-152.5c-51.6-98.7-51.6-103.2-51.6-1189c0-1085.8,0-1090.3,51.6-1189c26.9-53.8,78.5-118.9,112.2-143.6c62.8-47.1,87.5-47.1,1227.1-47.1c1139.6,0,1164.3,0,1224.8,47.1c33.6,24.7,83,74,107.7,107.7c47.1,60.6,47.1,85.2,47.1,1224.9s0,1164.3-47.1,1227.1c-24.7,33.7-89.7,85.2-143.6,114.4c-98.7,49.4-103.2,49.4-1189,49.4C671.6,1379.2,667.1,1379.2,568.4,1329.8z M2452.8-11.7v-695.4h-695.4H1062v695.4v695.4h695.4h695.4V-11.7z" />
          <path d="M4090.5,275.4c-172.7-118.9-217.6-305.1-116.7-471.1c101-163.8-94.2-152.5,2835.6-152.5c2600,0,2631.4,0,2705.4,44.9c78.5,49.4,159.3,195.2,161.5,293.9c0,92-94.2,246.8-179.5,291.6c-74,40.4-188.4,42.6-2705.4,42.6h-2627L4090.5,275.4z" />
          <path d="M521.3-2084.5c-145.8-98.7-143.6-83-143.6-1341.5c0-1117.2,0-1137.4,47.1-1200.2c24.7-33.7,80.8-85.2,121.1-112.2l74-51.6h1130.6c1079,0,1135.1,2.2,1215.9,44.9c60.6,29.2,103.2,74,134.6,141.3c44.9,94.2,47.1,123.4,47.1,1195.7c0,1034.2-4.5,1101.5-44.9,1191.2c-24.7,56.1-74,114.4-116.7,141.3c-71.8,42.6-109.9,44.9-1229.3,44.9H602.1L521.3-2084.5z M2452.8-3410.4V-4117h-695.4H1062v706.6v706.7h695.4h695.4V-3410.4z" />
          <path d="M4121.9-3094.1c-83-35.9-154.8-123.4-188.4-226.6c-53.8-163.8,60.6-363.4,237.8-412.8c60.6-17.9,870.4-24.7,2644.9-24.7h2559.6l98.7,51.6c139.1,71.8,190.7,150.3,190.7,296.1c0,130.1-40.4,204.1-154.8,289.4c-60.6,47.1-74,47.1-2689.7,51.6C4653.5-3064.9,4180.2-3069.4,4121.9-3094.1z" />
        </g>
      </g>
    </svg>
  </div> : null*/}
{/*idx == headerGroup.headers.length - 1 && showColumnSelectorToggle ? <div className="cursor-pointer absolute right-1 top-2 h-6 w-6 -m-1 p-0.5 hover:bg-slate-200 border-gray-300 border hover:border-gray-300 rounded"><ViewBoardsIcon viewBox="0 0 22 22" className="absolute w-5 h-5 text-gray-500" onClick={() => alert("show/hide the select column")} /></div> : null*/ }
const LoadingRow = ({columnCount}) => (
  <tr className="w-full ">
    <td className={`px-2 py-2 align-middle whitespace-nowrap text-xs w-full `} colSpan={columnCount}>&nbsp;</td>
  </tr>
);
const TableHeaderRow = ({ headerGroup }) => {
  return (
  <tr key={Math.random()+""} className="max-w-full overflow-hidden">
    {headerGroup.headers.map((header, idx) => (
      <TableHeaderCell key={idx} header={header}></TableHeaderCell>
    ))}
  </tr>)};

export const Table = ({ instance, handleRowClick, loading = false, renderSubComponent, portal = false }: ITable) => {
  const [lastValidPageIndex, setLastValidPageIndex] = useState(0);
  const [lastValidPageCount, setLastValidPageCount] = useState(0);
  const { t } = useTranslation();
  useMemo(() => setLastValidPageCount(instance.getPageCount() > 0 ? instance.getPageCount() : lastValidPageCount), [instance.getPageCount()])
  useMemo(() => setLastValidPageIndex(instance.getState().pagination.pageIndex > 0 ? instance.getState().pagination.pageIndex : lastValidPageIndex), [instance.getState().pagination.pageIndex])

  return (
    <>
      <table className="dark:bg-darkbrand-700 px-2 table-auto md:table-fixed bg-gray-50 divide-y divide-gray-200 w-full">
        <thead>
          {instance.getHeaderGroups().map((headerGroup, idx) => (
            <TableHeaderRow key={idx} headerGroup={headerGroup}></TableHeaderRow>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200">
          {!portal && loading === true ? [...Array(instance.getState().pagination.pageSize)].map((row, idx) => (
            <LoadingRow key={idx} columnCount={instance.getVisibleFlatColumns().length} />
          )) : null}
          {instance.getRowModel().rows.map((row, idx) => (
            <TableRow key={idx} row={row } handleRowClick={handleRowClick} renderSubComponent={renderSubComponent}/>
          ))}
          {!portal && !loading && instance.getState().pagination.pageSize - instance.getRowModel().rows.length > 0 && [...Array(instance.getState().pagination.pageSize - instance.getRowModel().rows.length)].map((row, idx) => (<tr key={"3000" + idx}><td className={`px-2 py-2 align-middle whitespace-nowrap text-xs`}>&nbsp;</td></tr>))}
        </tbody>
      </table>
      <div className="w-full flex flex-col md:flex-row TablePagination justify-between items-center gap-2 p-2 ">
        <div className="w-1/3">
          <select
            className="mt-1 block py-1 h-6 text-xs dark:bg-darkbrand-600 rounded-md"
            value={instance.getState().pagination.pageSize}
            onChange={e => {
              instance.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 25, 50, 75, 100, 150, 200].map(pageSize => (
              <option key={pageSize + ""} value={pageSize}>
                {t("table.show")} {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-1 flex flex-row space-between w-1/3 flex-shrink">
          <div className="flex grow justify-start">
            <button
              className="relative justify-center inline-flex grow text-sm font-medium rounded-md dark:text-white text-gray-700 hover:bg-gray-50"
              onClick={() => instance.setPageIndex(0)}
              disabled={!instance.getCanPreviousPage()}
              title="First Page"
            >
              <ChevronDoubleLeftIcon className="flex-shrink-0 h-5 w-5 text-gray-400 cursor-pointer" />
            </button>
            <button
              className="relative justify-center inline-flex grow text-sm font-medium rounded-md dark:text-white text-gray-700 hover:bg-gray-50"
              onClick={() => instance.previousPage()}
              disabled={!instance.getCanPreviousPage()}
              title="Previous Page"
            >
              <ChevronLeftIcon className="flex-shrink-0 h-5 w-5 text-gray-400 cursor-pointer" />
            </button>
          </div>
          <div className=" text-xs mx-2 flex items-center gap-1">
            <div>{t("table.page")}</div>
            <strong>
              {instance.getState().pagination.pageIndex + 1} of{' '}
              {instance.getPageCount() > 0 ? instance.getPageCount() : lastValidPageCount}
            </strong>
          </div>
          <div className="flex grow justify-end">
            <button
              className="relative justify-center inline-flex grow text-sm font-medium rounded-md dark:text-white text-gray-700 hover:bg-gray-50" //relative content-end md:content-center md:justify-center  grow text-sm font-medium rounded-md dark:text-white text-gray-700 hover:bg-gray-50
              onClick={() => instance.nextPage()}
              disabled={!instance.getCanNextPage()}
              title="Next Page"
            >
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400 cursor-pointer" /> {/*absolute md:right-auto right-0 top-0*/}
            </button>
            <button
              className="relative justify-center inline-flex grow text-sm font-medium rounded-md dark:text-white text-gray-700 hover:bg-gray-50" //relative  md:content-center md:justify-center grow text-sm font-medium rounded-md dark:text-white text-gray-700 hover:bg-gray-50
              onClick={() => instance.setPageIndex(instance.getPageCount() - 1)}
              disabled={!instance.getCanNextPage()}
              title="Last Page"
            >
              <ChevronDoubleRightIcon className=" flex-shrink-0 h-5 w-5 text-gray-400 cursor-pointer" /> {/*absolute right-0 top-0*/}
            </button>
          </div>
        </div>
        <div className="w-1/3 hidden md:flex align-right content-end">
          <div className="flex-grow"></div>
          <label className="text-xs mt-1 -mr-3" htmlFor={"goToPage"}>{t("table.goToPage")}:</label>
          <span className="text-xs mx-4 flex items-center gap-1">
            <input
              type="number"
              id='goToPage'
              name='goToPage'
              defaultValue={instance.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                instance.setPageIndex(page)
              }}
              className="text-xs border p-1 h-6 rounded w-16 dark:bg-darkbrand-600"
            />
          </span>
        </div>
      </div>
    </>
  );
}
