import { useFormContext } from 'react-hook-form';
import { PlusIcon } from '@heroicons/react/outline';
export const Input = ({ staticPrefix, name, label, type = "text", placeholder = "", required, valueAsNumber, valueAsDate, disabled, validate, maxLength, minLength, max, min, shouldUnregister, pattern, trailingButtonText, TrailingButtonIcon = { PlusIcon }, deps, ...props }: any) => {
    const { register, formState } = useFormContext();
    const { errors } = formState;
    return (<div className="flex flex-col w-full">
        {label ? <label htmlFor={name} className="block text-sm font-medium dark:text-white text-gray-700">{label}</label> : null}
        <div className="mt-1 flex rounded-md shadow-sm ">
            <div className="flex w-full">
                {staticPrefix ? <span className="shadow-sm inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 dark:text-white text-gray-500 sm:text-sm">http://</span> : null}
                <input
                    type={type}
                    className={`shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 ${staticPrefix || trailingButtonText ? null : "rounded-md"} ${staticPrefix ? "rounded-r-md border border-l-0" : ""} ${trailingButtonText ? "rounded-l-md border border-r-0" : ""}`}
                    placeholder={placeholder}
                    {...register(name, { required, validate, maxLength, minLength, max, min, pattern })}
                    {...props} />
                {errors[name] ? <div className="text-red-500 text-sm">{`${errors[name]?.message}`}</div> : null}
            </div>
            {trailingButtonText ? (<button
                type="button"
                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md dark:text-white text-gray-700 bg-gray-50 hover:bg-gray-100 "
            >
                {TrailingButtonIcon ? <TrailingButtonIcon className="h-5 w-5 dark:text-white text-gray-400" aria-hidden="true" /> : null}
                <span>{trailingButtonText}</span>
            </button>) : null}
        </div>
        {errors[name] && <span className="text-red-500 text-sm">{`${errors[name]?.message}`}</span>}
    </div>);
};
