import { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from '@headlessui/react';
import { useNavigate, useLocation } from "react-router-dom";
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';


export const ViewSelect = ({ views = [] }: any) => {
  const location = useLocation();
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (views[selected].href !== location.pathname)
      navigate(views[selected].href);
  }, [selected]);

  const selectedView = views[selected];
  return (
    <span className={"mx-2 "}>
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <div className="mt-1 md:relative">
              <Listbox.Button className="relative w-full dark:bg-darkbrand-600 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default hover:outline-none hover:ring-1 hover:ring-indigo-300 hover:border-indigo-500 sm:text-sm">
                <span className="flex items-center">
                  {selectedView.icon ? <selectedView.icon className="h-5 w-5 text-gray-400" aria-hidden="true" /> : null}
                  <span className=" hidden lg:block ml-3 truncate">{views[selected].title}</span>
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute right-0 w-full z-10 mt-1 dark:bg-darkbrand-500 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {views.map((view, idx) => (
                    <Listbox.Option
                      key={idx}
                      className={({ active }) => `${active ? 'dark:text-white dark:bg-darkbrand-800 text-gray-900 ' : 'dark:text-white text-gray-900 dark:bg-darkbrand-400 '} ' hover:bg-gray-300 hover:dark:bg-darkbrand-700 cursor-default select-none relative py-2 pl-3 pr-9'`}
                      value={idx}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            {view.icon ? <view.icon className="h-5 w-5 text-gray-400" aria-hidden="true" /> : null}
                            <span className={`${selected ? 'font-normal' : 'font-normal'} ml-3 block truncate`}>
                              {view.title}
                            </span>
                          </div>

                          {selected ? (
                            <span className={`${active ? 'text-indigo-400' : 'text-indigo-600'} hidden lg:block absolute inset-y-0 right-0 items-center pr-4`}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </span>);
};
