type IFloppyDiskIcon = {
  className?: string;
}
export const FloppyDiskIcon = ({className}:IFloppyDiskIcon) => (<svg xmlns="http://www.w3.org/2000/svg" className={className} version="1.1" viewBox="0 0 13.679 13.679" xmlSpace="preserve">
  <g fill="#FCFEFB">
    <path d="M13.383 1.361L12.408.132c-.127-.158-.318-.13-.318-.13H.439a.437.437 0 00-.438.437v12.802c0 .241.196.438.438.438H13.24a.438.438 0 00.438-.438V2.07c0-.038.002-.302-.295-.709zM2.298.895h8.79v5.704h-8.79V.895zm4.883 10.91a2.12 2.12 0 010-4.238 2.119 2.119 0 010 4.238z"></path>
    <path d="M9.866 5.707h-2.25V1.951h2.25v3.756zM7.195 8.681a1.002 1.002 0 10-.001 2.005 1.002 1.002 0 00.001-2.005z"></path>
  </g>
</svg>);

export default FloppyDiskIcon;