import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const MyDatePicker = ({ date, setDate, label, updateDate }) => {
  return (
    <div>
      <label className="block text-sm font-medium dark:text-white text-gray-700">{label}</label>
      <div className="mt-1 relative">
        <DatePicker
          selected={date}
          onChange={(d) => {
              setDate(d)
            }}
          className="h-10 bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
        />
      </div>
    </div>
  );
};