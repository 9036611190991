import React, { Fragment, useState, useMemo, useEffect } from "react";
import { MenuDropdown } from "./MenuDropdown";
import { Transition } from '@headlessui/react'
import { Input } from "./Input";
import { FormProvider, useForm } from "react-hook-form";
import { ViewSelect } from "./ViewSelect";

export let CardContext: any = React.createContext(null);

export const Card = ({ children, config = {}, views = [], noPadding = false, addStyles = "", buttons = [], filters = [], title: cardTitle = "", menu: cardMenu }: any) => {
  const title = cardTitle || config.title;
  const menu = cardMenu || config.menu;
  const footer = null;
  const [view, setView] = useState("list");
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [filterPanelValues, setFilterPanelValues] = useState(false);
  const [doAction, setDoAction] = useState(null);
  const value = useMemo(() => ({ view, setView, doAction, setDoAction, filterPanelValues, setFilterPanelValues }), [view]);
  useEffect(() => {
    if (doAction) {
      setDoAction(null);
    }
  }, [doAction]);
  useEffect(() => {
    if (buttons.length > 0) menu.push({ items: buttons || [], title: "Buttons", onlyMobile: true });
  }, [])

  const methods = useForm({
    mode: "onChange",
    //defaultValues: filters 
  });
  const { handleSubmit } = methods;
  const onInvalid = (errors: any) => {
    console.log("INVALID", errors);
    alert("errors");
  }
  const onValid = (data: any) => {
    console.log("VALID", data);
    alert("valid");
  }
  return (
    <CardContext.Provider value={value}>
      <div className={`mt-0 sm:mt-4 container mx-auto ${noPadding ? "" : "px-2 sm:px-1 lg:px-2"} ${addStyles}`}>
        <div className="dark:bg-darkbrand-900 dark:text-neutral-400 flex flex-col bg-white shadow sm:rounded-lg divide-y divide-gray-200">
          {title || cardTitle ? (
            <div className="flex px-2 py-2 ">
              <div className={`w-1/2  ${menu && menu.length > 0 && menu[0]?.onlyMobile ? "md:hidden" : ""}  px-2.5 py-2.5  flex`}>
                {/*<FilterIcon className="h-5 w-5 text-gray-400 hover:text-rose-400 cursor-pointer" onClick={() => setShowFilterPanel(!showFilterPanel)} />*/}
              </div>
              <h3 className=" grow-0 whitespace-nowrap text-center text-lg mt-2 align-bottom font-medium dark:text-white text-gray-900">{cardTitle ? cardTitle : title}</h3>
              <div className="w-1/2 items-right flex justify-between justify-items-end">
                <div className="w-full flex-shrink-1"></div>
                {views.length > 0 ? <ViewSelect views={views} /> : null}
                {buttons.map((buttonObject, idx) => (<div key={idx} onClick={buttonObject.handleClick} title={buttonObject.title} className={` hidden md:inline-flex items-center px-2.5 py-1 border border-gray-300 text-xs font-medium rounded shadow dark:text-white text-black  hover:outline-none hover:ring-1 hover:ring-indigo-300 hover:border-indigo-500 ${buttonObject.disabled ? "pointer-events-none bg-gray-100" : ""}  `}><buttonObject.icon className={"w-5 h-4 "} /></div>))}
                {menu?.length > 0 ? (<div className={` ${menu[0]?.onlyMobile ? "md:hidden" : ""} pl-2.5 py-2.5 flex-shrink-0 self-center flex`} ><MenuDropdown actions={menu}></MenuDropdown></div>) : null}
              </div>
            </div>
          ) : null}
          <Transition
            show={showFilterPanel}
            as={Fragment}
            enter="transition duration-200 ease-out"
            enterFrom="transform  opacity-0"
            enterTo="transform  opacity-100"
            leave="transition duration-200 ease-out"
            leaveFrom="transform  opacity-100"
            leaveTo="transform  opacity-0"
          >
            <div className={`bg-brand-200 dark:bg-darkbrand-500 flex-1 flex flex-col justify-between`}>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onValid, onInvalid)}>
                  <div className="flex">
                    {filters.map((filter, idx) => (<div key={idx} {...filter} className="m-2 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                      <label className="absolute -top-2 left-2 -mt-px inline-block px-1 text-xs font-medium bg-white dark:bg-darkbrand-500 text-gray-900 dark:text-white">{filter.label}</label>
                      <Input className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 bg-white-600 focus:ring-0 sm:text-sm" type={filter.type} name={filter.key} />
                    </div>))}
                    <button type="submit" className="items-center  border border-brand-400 rounded-md shadow-sm text-sm font-medium text-white my-2 px-2 bg-gray-700  hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
                      Apply
                    </button>
                  </div>

                </form>
              </FormProvider>
            </div>
          </Transition>
          <div className={`grow max-w-full overflow-hidden ${!footer ? "rounded-b-lg " : ""} ${!title ? "sm:rounded-t-lg" : ""}`}>
            {config.content ? config.content() : null}
            {children}
          </div>

        </div>
      </div>
    </CardContext.Provider>
  );
};
/*  {views?.length > 0 ? <div className="flex-shrink-0">
                            <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                <button type="button" onClick={e => handleChangeView("list")} className={`${view == "list" ? "bg-indigo-300" : "bg-white"} -ml-px relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300  text-sm font-medium text-gray-500`}>
                                    <span className="sr-only">List</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                                    </svg>
                                </button>
                                <button type="button" onClick={e => handleChangeView("map")} className={`${view == "map" ? "bg-indigo-300" : "bg-white"} relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300  text-sm font-medium text-gray-500 `}>
                                    <span className="sr-only">Map</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                                    </svg>
                                </button>

                            </span>
                        </div> : null} */
