import { set } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useLogger, useRendersCount } from "react-use";
import { v4 as uuidv4 } from 'uuid';

export interface FileMaker {
    PerformScript: (name?: string, param?: string) => void;
    PerformScriptWithOption: (name?: string, param?: string, option?: string) => void;
}

export interface FileMakerContext {
    FileMaker?: FileMaker | undefined;
    debug?: boolean;
    callbackFunctionName?: string;
}
interface CustomWindow extends Window {
    FileMaker?: FileMaker | undefined;
    SmartPublisherScriptResponse: Function;
    FileMakerScriptResult: Function;
    [key: string]: any;
}
interface PerformScriptOptions {
    scriptOption?: string;
    timeout?: number;
}
declare const window: CustomWindow;

const MAX_ITERATIONS = 12;
const INTERVAL_MILLISECONDS = 1000;
const DEFAULT_CALLBACK_SCRIPT_NAME = "FileMakerScriptResult"

const FileMakerContext = React.createContext<FileMakerContext>({});
FileMakerContext.displayName = "FileMakerContext";
interface IFileMakerProvider {
    children: React.ReactNode;
    callbackFunctionName?: string;
    debug?: boolean;
}

export const FileMakerProvider = ({ children, callbackFunctionName, debug=false }:IFileMakerProvider) => {
    const globalCallbackName = callbackFunctionName || DEFAULT_CALLBACK_SCRIPT_NAME;
    const [FileMaker, setFileMaker] = useState<FileMaker | undefined>();
    useLogger(`FileMakerProvider ${useRendersCount()} - ${typeof window.FileMaker == "object" ? "FileMaker global exists" : "FileMaker global does not exist (yet?)"}}`);
    useMemo(() => {
        window[globalCallbackName] = (requestId: string, response: any) => document.dispatchEvent(new CustomEvent(`FileMakerScriptResult_${requestId}`, { detail: JSON.parse(response) }));
    }, []);

    useEffect(() => {
        let iterations = 0;
        const interval = setInterval(() => {
            if (typeof window.FileMaker == "object") { // Found FileMaker object
                clearInterval(interval);
                setFileMaker(window.FileMaker);
            } else if (iterations++ > MAX_ITERATIONS) { // Found FileMaker not found - giving up
                console.error(`Giving up on FileMaker global object, could not be found after ${ (MAX_ITERATIONS * INTERVAL_MILLISECONDS ) /1000 } seconds`);
                clearInterval(interval); //otherwise it keeps trying...
                throw new Error("FileMaker not found"); //Because this should be thrown back to an ErrorBoundary
            }
        }, INTERVAL_MILLISECONDS);
        return () => clearInterval(interval); //in case element is unmounted while waiting for FileMaker to load it won't keep checking for it
    }, [window.FileMaker]);
    return (
        <FileMakerContext.Provider value={{debug, FileMaker }}>
            {children}
        </FileMakerContext.Provider>
    );
}
/*
Example Usage:
```jsx
const [someValue, setSomeValue] = useState("");
const [anyNameYouWantToUse, { data: theResultDataOnNextRender, error, loading }] = useFileMakerScript("SomeFMScriptNameThatShouldNotBeChanged...Ever");
useEffect(() => {
  const runThisFileMakerScriptAsync = async () => { // must wrap async/await if used in useEffect
    const scriptParameter = { // this is the object that will be passed to the FileMaker script as a stringified JSON object
        param1: paramValue1,
        param2: paramValue2
    };
    const result = await anyNameYouWantToUse(scriptParameter); // call the function returned from useFileMakerScript
    const { someResponseValue, someResponseValue2 } = result; // destructure the response object
    setSomeValue(someResponseValue); // set the state value to the response value
  };
  runThisFileMakerScriptAsync(); // run the async function that was just defined
}, [ paramValue1, paramValue2 ]); //  the async function will run every time paramValue1 or paramValue2 changes which is important since those are being used as parameters to the FileMaker script
if(loading) return (<div>loading...</div>); // this is optional - should instead use suspense with an error boundary if available
if(error) throw new Error(error); // this is optional - will be caught by an error boundary if available and render a fallback UI
return (<div>result: {theResultDataOnNextRender.someResponseValue} <=sameDataDifferentSource=> {someValue}</div>)
```
*/
export function useFileMakerScript(hookScriptName: string, hookScriptParams?: undefined, hookScriptOption?: string | PerformScriptOptions | undefined) {
    //const FileMaker = React.useContext(FileMakerContext); //This is the real FileMaker object from window.FileMaker
    const [response, setResponse] = useState(null); //return a JSON object with a "data" key/value with the result of the scripts execution
    const [error, setError] = useState(null); //return a JSON object with an "error" key/value with the user-facing message to populate this value on failure
    const [loading, setLoading] = useState(false); //Flipped while waiting for the response callback function to fire which will send a CustomEvent with the result of the scripts execution
    const [timeoutMilliseconds, setTimeoutMilliseconds] = useState<number>(3000); //The number of milliseconds to wait for the callback function to fire before giving up and returning an error
    const requestId = uuidv4(); //This is a unique ID to avoid responses from other scripts being picked up by this hook
    const eventName = `FileMakerScriptResult_${requestId}`;
    useLogger(`useFileMakerScript ${requestId} ${useRendersCount()} `);
    let handleResponse: EventListenerOrEventListenerObject;
    const PerformScript = (params?: any, options?: PerformScriptOptions | string ) => { //This is the function that will be returned from the hook - it can and should be renamed to something more meaningful within the component that uses it
        let name = hookScriptName; //You always specify the script name in the hook
        params = hookScriptParams ? hookScriptParams : params; //You can optionally specify the script parameters in the hook rather than providing them when you call the function
        options = hookScriptOption ? hookScriptOption : options; //This MIGHT be changed to accept either JUST the option (which is a string) or an object with multiple options (overriding the callbackFunctionName on the fly for example)
        const scriptOption = typeof options === "string" ? options : options?.scriptOption; //This is the option that will be passed to the FileMaker.PerformScriptWithOption function
        setTimeoutMilliseconds(options && typeof options === "object" && options.timeout ? options.timeout : timeoutMilliseconds); //This is the number of milliseconds to wait for the callback function to fire before giving up and returning an error
        if (!name || name === undefined || name === "") throw new Error("Script Name is required");
        const promise = new Promise((resolve, reject) => { //This is the promise that will be returned when you execute the function returned by the hook
            setLoading(true);
            if (window.FileMaker) { //This is the real FileMaker object from window.FileMaker - if it doesn't exist something is wrong
                if (options && typeof options === "string" || typeof options === "object" && options.scriptOption) { //This is the option that will be passed to the FileMaker.PerformScriptWithOption function
                    window.FileMaker.PerformScriptWithOption(name, JSON.stringify({ requestId, params: typeof params === "object" ? JSON.stringify(params) : params }), scriptOption);
                } else {
                    window.FileMaker.PerformScript(name, JSON.stringify({ requestId, params: typeof params === "object" ? JSON.stringify(params) : params }));
                }
                handleResponse = ({ detail }: any) => resolve(detail); //This is the event handler that will be called when the callback function is fired - must be defined here so it can be removed later
                document.addEventListener(eventName, handleResponse);
                setTimeout(() => reject(`waited ${timeoutMilliseconds / 1000} seconds before giving up`), timeoutMilliseconds);
            } else {
                reject("FileMaker object not found");
            }
        }).catch((e) => {
            setError(e);
        }).then((data: any) => {
            setResponse(data);
            document.removeEventListener(eventName, handleResponse);
            setLoading(false);
            return data;
        });
        return promise;
    }
    const resultInfo = { data: response, error, loading };
    //huh ... https://stackoverflow.com/questions/65680316/this-expression-is-not-callable-not-all-constituents-of-type-string-search
    return [ PerformScript, resultInfo ] as const;
}


export const fml = (xml, ...keys) => {
    console.log(xml, keys);
    const parser = new DOMParser();
    const topleveltag = document.evaluate(
        "/",
        parser.parseFromString(xml,"text/xml"),
        null,
        XPathResult.ANY_TYPE,
        null,
    );
};

