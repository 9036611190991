import { useFormContext } from 'react-hook-form';


export const AddressInput = ({ namePrefix = "", label, address, suite, city, state, zip, country, ...props }: any) => {
    const { register } = useFormContext();
    return (<fieldset>
        <legend className="block text-sm font-medium dark:text-white text-gray-700">{label}</legend>
        <div className="mt-1 bg-white rounded-md shadow-sm -space-y-px">
            <div>
                <label htmlFor="street" className="sr-only">
                    Street
                </label>
                <input
                    type="text"
                    className="focus:ring-brand-500 focus:border-brand-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                    placeholder="Street Address"
                    {...register(`${namePrefix ? namePrefix + "A" : "a"}ddress`)} />
            </div>
            {suite ? <div>
                <label htmlFor="suite" className="sr-only">
                    Suite
                </label>
                <input
                    type="text"
                    className="focus:ring-brand-500 focus:border-brand-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                    placeholder="Suite"
                    {...register(namePrefix ? `${namePrefix}Suite` : `suite`)} />
            </div> : null}
            <div className="flex -space-x-px">
                <div className="w-1/2 flex-1 min-w-0">
                    <label htmlFor="city" className="sr-only">
                        City
                    </label>
                    <input
                        type="text"
                        className="focus:ring-brand-500 focus:border-brand-500 relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                        placeholder="City"
                        {...register(namePrefix ? `${namePrefix}City` : `city`)} />
                </div>
                <div className="flex-none w-16">
                    <label htmlFor="state" className="sr-only">
                        State
                    </label>
                    <input
                        type="text"
                        className="focus:ring-brand-500 focus:border-brand-500 relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300"
                        placeholder="State"
                        {...register(namePrefix ? `${namePrefix}State` : `state`)} />
                </div>
                <div className="flex-none w-28">
                    <label htmlFor="zip" className="sr-only">
                        Zip
                    </label>
                    <input
                        type="text"
                        className="focus:ring-brand-500 focus:border-brand-500 relative block w-full rounded-none rounded-br-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                        placeholder="Zip"
                        {...register(namePrefix ? `${namePrefix}Zip` : `zip`)} />
                </div>
            </div>
            {country ? <div>
                <label htmlFor="country" className="sr-only">
                    Country
                </label>
                <input
                    type="text"
                    className="focus:ring-brand-500 focus:border-brand-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                    placeholder="Country"
                    {...register(namePrefix ? `${namePrefix}Country` : 'country')} />
            </div> : null}
        </div>
    </fieldset>);
};
