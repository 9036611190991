import { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { ClassifiedEditorContext } from "./ClassifiedAdEditor";
type IFormattingButton = {
  label: string;
  type: string;
  name: string;
  value: string;
  headlineElligible?: boolean;
  className?: string;
}

export const FormattingButton = ({ label, type, name, value, headlineElligible, className="" }:IFormattingButton) => {
  const { currentField, defaultJustifyBody, defaultJustifyHeadline, caret }: any = useContext(ClassifiedEditorContext);
  const { getValues, setValue } = useFormContext();

  const handleFormattingButtonClick = () => {
    const values = getValues();
    const { start, end } = caret;

    if (currentField == 'adHeadline' && !headlineElligible) {
      alert(name + " is not elligible for the Ad Headline.");
      return 0;
    }

    if (start == end && end !== values[currentField].length) {
      alert("Select at least one character from the text to insert " + type + " elements around.");
      return 0;
    }
    let endElement;
    switch (type) {
      case 'paired':
        endElement = value.substring(0, 1) + "/" + value.substring(1, value.length);
        break;
      case 'alignment':
        endElement = end !== values[currentField].length ? "\n<*" + (currentField == "adBody" ? defaultJustifyBody : defaultJustifyHeadline) + ">" : "";
        break;
      default:
        endElement = "";
        break;
    }
    let text = values[currentField];
    const startText = text.substring(0, start);
    const endText = text.substring(end, text.length);
    const selectedText = text.substring(start, end);
    const startTag = value;
    const endTag = start == end && end === values[currentField].length && type === "alignment" ? "" : endElement;
    console.log("startText", startText, "endText", endText, "selectedText", selectedText, "startTag", startTag, "endTag", endTag);
    setValue(currentField, `${startText}${startTag}${selectedText}${endTag}${endText}`);
  };

  return (
    <button type="button" disabled={currentField == "adHeadline" && headlineElligible == false}  onClick={handleFormattingButtonClick}
      className={`${label.length > 12 ? "tracking-tight": ""} text-xs justify-center ring-1 ring-inset ring-brand-700 w-full relative inline-flex items-center ${className} px-1 py-2 my-1 text-brand-50  hover:bg-brand-400 focus:z-10  ${currentField == "adHeadline" && headlineElligible == false ? "bg-gray-300 cursor-not-allowed" : "bg-brand-500 text-brand-50 font-semibold"} `}>
      {label}
    </button>
  );
};