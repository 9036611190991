import { useContext } from 'react'
import { AppContext, IAppContext } from '../App';
import { CalendarIcon, ClockIcon, MapIcon, UsersIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom';
import { Card } from "./Card"
import { FormattedNumber } from 'react-intl';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { gql, useQuery } from '@apollo/client';
/* ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
}; */
const GET_USER_DASHBOARD_STATS = gql`
    query GetUserDashboardStats ($id: ID!, $goalsInput: FindInput, $adsInput: FindInput) {
        getUser(id: $id) {
            dashboardTasksToday
            dashboardTasksOverdue
            dashboardTasksThisWeek
            dashboardTasksUpcoming
            dashboardAccountsActive
            dashboardAccountsExpiring
            dashboardAccountsInactive
            dashboardAccountsNeglected
        }
        findAds(input: $adsInput) {
            ads {
                id
                adId
                account{
                    id
                    company
                }
                category {
                    id
                    adCodeId
                    categoryName
                }
                firstRunDate
                expireDate
            }
        }
        findGoals(input: $goalsInput) {
            data {
                id
                amountactual
                amountgoal
                dateend
                datestart
                description
                issue {
                    id
                    issueId
                    publication{
                        id
                        publicationId
                        publication
                    }
                    issueDate
                }
                product {
                    id
                    productname
                }
                publication{
                    id
                    publicationId
                    publication
                }
            }
        }
    }
`;
export const Dashboard = () => {

    const context = useContext<IAppContext | null>(AppContext);
    const { user } = context || {};
    const { data, error, loading } = useQuery(GET_USER_DASHBOARD_STATS, {
        variables: {
            id: user?.id,
            goalsInput: {
                criteria: [{
                    criterion: [
                        { key: "repid", value: user?.userId, },
                        { key: "show", value: "1" },
                    ]
                }],
                offset: 0,
                limit: 100
            },
            adsInput: {
                criteria: [{
                    criterion: [
                        { key: "Seller ID", value: user?.userId, },
                        { key: "Expire Date", value: "*" },
                        { key: "Renew Ad ID", value: "=" },
                    ]
                }],
                offset: 0,
                limit: 100
            }
        }
    });
    const { dashboardTasksToday, dashboardTasksOverdue, dashboardTasksThisWeek, dashboardTasksUpcoming, dashboardAccountsActive, dashboardAccountsExpiring, dashboardAccountsInactive, dashboardAccountsNeglected } = data?.getUser || {};
    console.log(data?.getUser?.dashboardvaluesrep);
    const { findGoals } = data || {};
    const { data: goals } = findGoals || {};
    const adsExpiring = data?.findAds?.ads || [];
    const taskStats = [
        { label: 'Today', value: dashboardTasksToday, destination: "/tasks" },
        { label: 'Overdue', value: dashboardTasksOverdue, destination: "/tasks" },
        { label: 'This Week', value: dashboardTasksThisWeek, destination: "/tasks" },
        { label: 'Next 30 days', value: dashboardTasksUpcoming, destination: "/tasks" },
    ];
    const accountStats = [
        { label: 'Active', value: dashboardAccountsActive, destination: "/accounts" },
        { label: 'Expiring', value: dashboardAccountsExpiring, destination: "/accounts" },
        { label: 'Inactive', value: dashboardAccountsInactive, destination: "/accounts" },
        { label: 'Neglected', value: dashboardAccountsNeglected, destination: "/accounts" },
    ];
    return (
        <>
            <Card className=" dark:bg-darkbrand-600">

                <section aria-labelledby="profile-overview-title">
                    <div className="rounded-lg bg-white overflow-hidden shadow">
                        <h2 className="sr-only" id="profile-overview-title">
                            Profile Overview
                        </h2>
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5">
                                    <div className="flex-shrink-0">
                                        <img className="mx-auto h-20 w-20 rounded-full" src={user?.avatar_url} alt="" />
                                    </div>
                                    <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                        <p className="text-sm font-medium text-gray-600">Good {new Date().getHours() < 12 ? "morning" : "afternoon"}, </p>
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">{user?.preferred_username}</p>
                                        <p className="text-sm font-medium text-gray-600">{user?.role}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="w-1/2 text-center">
                                <div className="w-full text-center">My Tasks</div>
                                <div className="w-full border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-4 sm:divide-y-0 sm:divide-x">
                                    {taskStats.map((stat) => (
                                        <Link to={stat.destination} key={stat.label} className="cursor-pointer max-h-14 max-w-32 truncate px-6 py-5 text-sm font-medium text-center">
                                            <span className="text-gray-900">{stat.value}</span>{' '}
                                            <span className="text-gray-600 text-xs">{stat.label}</span>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            <div className="w-1/2 text-center divide-x">
                                <div className="w-full text-center">My Accounts</div>
                                <div className="w-full border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-4 sm:divide-y-0 sm:divide-x">
                                    {accountStats.map((stat) => (
                                        <Link to={stat.destination} key={stat.label} className="cursor-pointer  max-h-14 px-6 py-5 text-sm font-medium text-center truncate">
                                            <span className="text-gray-900">{stat.value}</span>{' '}
                                            <span className="text-gray-600 text-xs ml-1">{stat.label}</span>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Card>
            <Card className=" dark:bg-darkbrand-600">
                <section aria-labelledby="quick-links-title">
                    <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                        <div key={"1"} className={"relative group bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500"}>
                            <div className="p-6">
                                <span className='rounded-lg inline-flex p-3 ring-4 ring-white'>
                                    <Link to={"#"} className="cursor-pointer focus:outline-none">
                                        <ClockIcon className="h-6 w-6" aria-hidden="true" />
                                    </Link>
                                </span>
                            </div>
                            <div className="mt-8">
                                {/*      <Line options={options} data={data} /> */}
                            </div>
                            <span
                                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                                aria-hidden="true"
                            >
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                </svg>
                            </span>
                        </div>
                        <div key={"2"} className={"relative group bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500 h-64 overflow-y-scroll"}>
                     
                            <div className="mt-8">
                                <h3 className="px-6 text-lg font-medium">
                                    Goals
                                </h3>
                                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {goals && goals.map((goal) => (
                                            <li key={goal.id}>
                                                <a href="#" className="block hover:bg-gray-50">
                                                    <div className="px-4 py-4 sm:px-6">
                                                        <div className="flex items-center justify-between">
                                                            <p className="truncate text-sm font-medium text-indigo-600">{goal.description}</p>
                                                            <div className="ml-2 flex flex-shrink-0">
                                                                <p className="truncate text-sm font-medium text-indigo-600">{goal?.product?.productname}</p>

                                                            </div>
                                                            <p className="rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                <FormattedNumber currency="usd" style="currency" value={goal?.amountactual} />/<FormattedNumber currency="usd" style="currency" value={goal?.amountgoal} />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <span
                                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                                aria-hidden="true"
                            >
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                </svg>
                            </span>
                        </div>
                        <div key={"3"} className={"relative group bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500 h-64 overflow-y-scroll"}>
                     
                            <div className="mt-8">
                                <h3 className="text-lg px-6 font-medium">
                                    Sales
                                </h3>
                                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {adsExpiring && adsExpiring.map((ad) => (
                                            <li key={ad?.id}>
                                                <a href="#" className="block hover:bg-gray-50">
                                                    <div className="px-2 py-2 sm:px-6">
                                                        <div className="flex w-full ">
                                                            <p className="truncate shrink-0 text-sm font-medium text-indigo-600">
                                                                <span>#</span>{ad.adId}
                                                            </p>
                                                            <div className="ml-2 grow truncate  space-x-2">
                                                                {ad.account.company}
                                                            </div>
                                                            <p className="inline-flex w-16 shrink-0 rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                <span className="prose truncate max-w-3">{ad.category.categoryName}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <span
                                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                                aria-hidden="true"
                            >
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                </svg>
                            </span>
                        </div>
                        <div key={"4"} className={"relative group bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500 "}>
                            <div className="px-6">
                                <span className='rounded-lg inline-flex p-3 ring-4 ring-white'>
                                    <Link to={"#"} className="cursor-pointer focus:outline-none">
                                        <ClockIcon className="h-6 w-6" aria-hidden="true" />
                                    </Link>
                                </span>
                            </div>
                            <div className="mt-8">
                             
                            </div>
                            <span
                                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                                aria-hidden="true"
                            >
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                </svg>
                            </span>
                        </div>

                    </div>
                </section>
            </Card>
        </>
    )
};
export default Dashboard;