import { createContext, useContext, useState } from "react";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export let ModalContext = createContext<any>({});

export const Modal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage, ] = useState("");
  const [onCancel, setOnCancel, ] = useState(()=>setIsOpen(false));
  const [onClose, setOnClose, ] = useState(()=>setIsOpen(false));
  const [onConfirm, setOnConfirm, ] = useState(()=>setIsOpen(false));
  const [confirmLabel, setConfirmLabel, ] = useState("Confirm");
  const [cancelLabel, setCancelLabel, ] = useState("Cancel");
  const [title, setTitle, ] = useState("");
  return (
    <ModalContext.Provider value={{
      setMessage,
      setOnCancel,
      setOnClose,
      onClose,
      setOnConfirm,
      setConfirmLabel,
      setCancelLabel,
      setTitle,
      setIsOpen,
      isOpen
    }}>
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => onClose} >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="relative inline-block bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all align-middle w-1/2">
              <div className='px-4 pt-5 pb-4 sm:p-6'>
                <div className="flex items-center justify-between">
                  <Dialog.Title className="text-lg font-medium dark:text-white text-gray-900">{title}</Dialog.Title>
                </div>
                <div className="mt-3 text-gray-600">
                  {message}
                </div>
                <div className='float-right my-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3'>
                  <button onClick={() => onCancel} type='button' className='flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium dark:text-white text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500'>
                    {cancelLabel}
                  </button>
                  <button onClick={() => onConfirm} type='button' className='flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 sm:flex-1'>
                    {confirmLabel}
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    </ModalContext.Provider>
  );
};
export const useConfirmationModal = () => {
  const context = useContext(ModalContext);
  const {
    setMessage,
    setOnCancel,
    setOnConfirm,
    setConfirmLabel,
    setCancelLabel,
    setTitle,
    setIsOpen,
    isOpen,
  }: any = context
  console.log("SET?",context, setMessage)
  const open = ({ message, handleConfirm, handleCancel, title = "Confirm?", confirmLabel = "Yes, continue", cancelLabel = "No, cancel" }) => {
    setMessage(message);
    setConfirmLabel(confirmLabel)
    setCancelLabel(cancelLabel)
    setTitle(title);
    setIsOpen(true);
    setOnCancel(handleCancel);
    setOnConfirm(handleConfirm)
  }
  const close = () => {
    setIsOpen(false);
  }
  return { open, close, isOpen };
}

export default Modal;


/*
import { Fragment, useState, createContext, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, InformationCircleIcon, XIcon } from '@heroicons/react/outline'


export const ModalContext = createContext(null);


export const Modal = ({ children, handleConfirm, handleCancel, title, message, icon = <InformationCircleIcon />}) => {
  let [isOpen, setIsOpen] = useState(false)
  const IconElement = ((props) => <div {...props}>...</div>);

  return (
    <ModalContext.Provider value={{
      isOpen,
      handleCancel,
      handleConfirm,
      title,
      message,
      icon,
      setIsOpen
    }}>
        
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext);

  const { setIsOpen } = context;
  return {
    show: (config) => {
      const { setIsOpen} = context;
      setIsOpen(true)
    },
    hide: () => {
      setIsOpen(false)
    }
  };
}

export default Modal;*/