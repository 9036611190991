import { Fragment, useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon, HomeIcon, MenuIcon, PlusIcon, SearchIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCommandPalette } from "./CommandPalette";
import { AppContext, IAppContext } from "../App";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



export function TopNav() {
  const navigate = useNavigate();
  const context = useContext<IAppContext | null>(AppContext);
  const { user } = context || {};
  const { setCommandPaletteOpen, setCommandPaletteSearchValue } = useCommandPalette();
  const { t } = useTranslation();
  const handleSignOut = async () => {
    navigate("/logout");
  };
  const navs = [
    { key: 'accounts', title: t("nav.accounts"), path: '/accounts' },
    { key: 'tasks', title: t("nav.tasks"), path: '/tasks' },
    { key: 'ads', title: t("nav.ads"), path: '/ads' },
    { key: 'contacts', title: t("nav.contacts"), path: '/contacts' },
    { key: 'calendar', title: t("nav.calendar"), path: '/calendar' },
  ]
  const { pathname } = useLocation();
  const handlePlusClick = () => {
    setCommandPaletteOpen(true);
    setCommandPaletteSearchValue(">");
  }
  return (
    <Disclosure as="nav" className="dark:bg-darkbrand-800 bg-white shadow sticky z-20 top-0">
      {({ open }) => (
        <>
          <div className="container mx-auto px-2 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16  items-center">
              <div className="flex w-full justify-between">
                <div className="flex-shrink-0 flex items-center overflow-x-hidden">
                  <NavLink to={"/home"}><img src="/images/logoIcon.png" srcSet="/images/logoIcon.png" className="w-12 block h-12" alt="Pre1 Software Logo" /></NavLink>
                </div>
                <div className="hidden flex-grow  sm:ml-2 sm:flex space-x-2 xl:space-x-4 overflow-x-hidden w-full">
                  {navs.map((nav, i) => (<Link key={i} to={nav.path} className={`h-12 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium hover:text-brand-400 dark:hover:color-darkbrand-400 ${pathname.includes(nav.key) ? "border-brand-500 dark:text-white text-gray-900" : "border-transparent text-gray-500"}`}>
                    {nav.title}
                  </Link>))}
                </div>
                <div className="flex-grow"></div>
                <button onClick={handlePlusClick} aria-label="Open Command Palette" className={`cursor-pointer h-8 my-2 mx-2 sm:ml-1 sm:flex hover:shadow shadow-brand-700 dark:shadow-darkbrand-400 rounded-lg inline-flex items-center text-sm font-medium border-transparent text-gray-500 border-gray-500 rounded-md hover:text-brand-400 dark:hover:color-darkbrand-400 `}>
                  <PlusIcon className="h-8 w-8 p-1" />
                </button>
                <button onClick={() => { setCommandPaletteOpen(true) }} aria-label="Open Search Command Palette" className={`cursor-pointer h-8 my-2 mx-2  sm:ml-1 sm:flex hover:shadow shadow-brand-700 dark:shadow-darkbrand-400 rounded-lg  inline-flex items-center text-sm font-medium border-transparent text-gray-500  border-gray-500 rounded-md hover:text-brand-400 dark:hover:color-darkbrand-400 `}>
                  <SearchIcon className="h-8 w-8 p-1" />
                </button>
                <button aria-label="Home" className={`cursor-pointer  h-8 my-2 mx-2   sm:ml-1 sm:flex    hover:shadow shadow-brand-700 dark:shadow-darkbrand-400 rounded-lg inline-flex items-center text-sm font-medium border-transparent text-gray-500  border-gray-500 rounded-md hover:text-brand-400 dark:hover:color-darkbrand-400 `}>
                  <NavLink to="/home">
                    <HomeIcon className="h-8 w-8 p-1" />
                    </NavLink>
                </button>
                <button aria-label="Back" className={`cursor-pointer h-8 my-2  mx-2  hover:shadow shadow-brand-700 dark:shadow-darkbrand-400 rounded-lg inline-flex items-center text-sm font-medium border-transparent text-gray-500  border-gray-500 rounded-md hover:text-brand-400 dark:hover:color-darkbrand-400 `}>
                  <NavLink to={-1 as any} >
                    <ChevronLeftIcon className="h-8 w-8 p-1" />
                    </NavLink>
                </button>
                <button aria-label="Forward" className={`cursor-pointer h-8 my-2 mx-2  hover:shadow shadow-brand-700 dark:shadow-darkbrand-400 rounded-lg inline-flex items-center text-sm font-medium border-transparent text-gray-500  border-gray-500 rounded-md hover:text-brand-400 dark:hover:color-darkbrand-400`}>
                  <NavLink to={1 as any} >
                    <ChevronRightIcon className="h-8 w-8 p-1" />
                    </NavLink>
                </button>
              </div>
              <div className="hidden  sm:ml-1 sm:flex sm:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (<>
                    <Menu.Button className="bg-white dark:bg-darkbrand-800 rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 ">
                      <span className="sr-only">
                        {t("nav.openusermenu")}
                      </span>
                      <span className="relative z-0 w-10">
                        <img alt={"User avatar"} src={user?.avatar_url} className="inline-flex items-center justify-center h-10 w-10 rounded-full" />
                        {/libravatar/.test(user?.avatar_url ? user?.avatar_url : "") ? <span className="z-10 inset-0 absolute uppercase text-gray-700 h-10 w-10 center py-3 -ml-0.5 font-mono text-lg tracking-tighter font-bold leading-none">{user?.initials ? user.initials : user && user.userId}</span> : null}
                      </span>
                    </Menu.Button>
                    <Transition show={open} as={Fragment} enter="transition ease-out duration-200" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 dark:bg-darkbrand-800 bg-white ring-1 dark:ring-white ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <button onClick={() => navigate("/settings")} className={classNames(active ? 'bg-gray-100  dark:bg-darkbrand-900 ' : '', 'block px-4 py-2 text-sm dark:text-white text-gray-700 cursor-pointer w-full text-left')}>
                              {t("userMenu.settings")}
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button onClick={() => window.location.replace("https://pre1software.na1.teamsupport.com/")} className={classNames(active ? 'bg-gray-100  dark:bg-darkbrand-900 ' : '', 'block px-4 py-2 text-sm dark:text-white text-gray-700 cursor-pointer  w-full text-left')}>
                              {t("userMenu.support")}
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (<div onClick={handleSignOut} className={classNames(active ? "bg-gray-100  dark:bg-darkbrand-900 " : "", "block px-4 py-2 text-sm dark:text-white text-gray-700 cursor-pointer")} >
                            {t("userMenu.signOut")}
                          </div>)}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                  )}
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (<XIcon className="block h-6 w-6" aria-hidden="true" />) : (<MenuIcon className="block h-6 w-6" aria-hidden="true" />)}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navs.map((nav, i) => (
                <Disclosure.Button key={i} as={Link} to={nav.path} className={`block pl-3 pr-4 py-2 border-l-4 text-base font-medium ${pathname === nav.path ? "bg-brand-50 border-brand-500 text-brand-700" : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:dark:text-white text-gray-700"}`}>
                  {nav.title}
                </Disclosure.Button>
              ))}
            </div>
            <div className="py-3 border-t border-gray-200">
              <Disclosure.Button as="a" href="/settings" className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                Settings
              </Disclosure.Button>
              <div onClick={handleSignOut} className="block px-4 py-2 w-18 w-96 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 cursor-pointer">
                Sign out
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
