import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        load: "languageOnly",
        resources: {
            "en": { //English
                translation: {
                    nav: {
                        accounts: "Accounts",
                        tasks: "Tasks",
                        ads: "Ads",
                        contacts: "Contacts",
                        calendar: "Calendar",
                        dashboard: "Dashboard",
                        goals: "Goals",
                        sales: "Sales",
                        reports: "Reports",
                        history: "History",
                        adExport: "Ad Export"
                    },
                    role: {
                        salesRep: "Sales Rep",
                        admin: "Admin",
                        accounting: "Accounting",
                        production: "Production",
                        salesManager: "SalesManager"
                    },
                    views: {
                        list: "List",
                        map: "Map",
                    },
                    table: {
                        page: "Page",
                        show: "Show",
                        goToPage: "Go To Page"
                    },
                    calendar: {
                        date: 'Date',
                        time: 'Time',
                        event: 'Event',
                        allDay: 'All Day',
                        week: 'Week',
                        work_week: 'Work Week',
                        day: 'Day',
                        month: 'Month',
                        previous: 'Back',
                        next: 'Next',
                        yesterday: 'Yesterday',
                        tomorrow: 'Tomorrow',
                        today: 'Today',
                        agenda: 'Agenda',
                        noEventsInRange: 'There are no events in this range.',
                        showMore: total => `+${total} more`,
                    },
                    bulkActionMenu: {
                        startBulkEmail: "Start Bulk Email",
                        viewRelatedAccounts: "View Related Accounts",
                        markTasksAsDone: "Mark Tasks as Done"
                    },
                    userMenu: {
                        settings: "Settings",
                        support: "Support",
                        signOut: "Sign out"
                    },
                    pages: {
                        myAccounts: "My Accounts",
                        myTasks: "My Tasks",
                        myAds: "My Ads",
                        myContacts: "My Contacts",
                        calendar: "Calendar"
                    },
                    login: {
                        signintoyouraccount: "Sign in to your account",
                        username: "Username",
                        password: "Password",
                        signin: "Sign in",
                    },
                    module: {
                        accounts: "Accounts",
                        ads: "Ads",
                        agings: "Aging",
                        contacts: "Contacts",
                        contracts: "Contracts",
                        goals: "Goals",
                        history: "History",
                        insertions: "Insertions",
                        packages: "Packages",
                        issues: "Issues",
                        letters: "Letters",
                        invoices: "Invoices",
                        subscriptions: "Subscriptions",
                        tasks: "Tasks",
                        transactions: "Transactions",
                        transIndex: "Transaction Line Items",
                    },
                    vls: { //Reference with useTranslation +  {t('vls.accountType.' + account.acctType)}
                        accountType: {
                            DIRECT: "Direct",
                            AGENCY_CLIENT: "Agency client",
                            AD_AGENCY: "Ad agency",
                            TRADE: "Trade",
                            PERSONALS: "Personals",
                        },
                        adStatus: {
                            QUOTE: "Quote",
                            AD: "Ad",
                        },
                        adSubType: {
                            WORD: "Word",
                            BOX: "Box",
                        },
                        adSuperType: {
                            DISPLAY: "Display",
                            CLASSIFIED_WORD: "Classified word",
                            CLASSIFIED_BOX: "Classified box",
                        },
                        adType: {
                            DISPLAY: "Display",
                            CLASSIFIED: "Classified",
                        },
                        billingCycle: {
                            BY_ISSUE: "By issue",
                            MONTHLY: "Monthly",
                        },
                        contractStatus: {
                            NEW: "New",
                            APPROVED: "Approved",
                            CURRENT: "Current",
                            RENEWAL: "Renewal",
                            COMPLETE: "Complete",
                            CANCELED: "Canceled",
                            UNFULFILLED: "Unfulfilled",
                        },
                        insertStatus: {
                            NEW: "New",
                            APPROVED: "Approved",
                            INVOICED: "Invoiced",
                            DONE: "Done",
                            CANCELED: "Canceled",
                            HELD_OUT: "Held out",
                            DELETED: "Deleted",
                        },
                        invoiceStatus: {
                            PARTIAL_PMT: "Partial pmt",
                            UNPAID: "Unpaid",
                            PAID: "Paid",
                            BAD_DEBT: "Bad debt",
                            ERROR: "Error",
                        },
                        invoiceType: {
                            STANDARD: "Standard",
                            STUB: "Stub",
                            DISPLAY: "Display",
                            CUSTOM: "Custom",
                            AD_COPY: "Ad copy",
                            LONG: "Long",
                            CARD: "Card",
                            MISC: "Misc",
                            FIN_CHG: "Fin chg",
                            SUMMARY: "Summary",
                            SALES: "Sales",
                        },
                        issuelessSkip: {
                            CONSECUTIVE: "Consecutive",
                            EVERY_2ND: "Every 2nd",
                            EVERY_3RD: "Every 3rd",
                            EVERY_4TH: "Every 4th",
                            EVERY_5TH: "Every 5th",
                            EVERY_6TH: "Every 6th",
                            EVERY_7TH: "Every 7th",
                            EVERY_14TH: "Every 14th",
                            EVERY_30TH: "Every 30th",
                            SUNDAY: "Sunday",
                            MONDAY: "Monday",
                            TUESDAY: "Tuesday",
                            WEDNESDAY: "Wednesday",
                            THURSDAY: "Thursday",
                            FRIDAY: "Friday",
                            SATURDAY: "Saturday",
                            FIRST_OF_THE_MONTH: "First of the month",
                            LAST_OF_THE_MONTH: "Last of the month",
                        },
                        letterType: {
                            EMAIL: "Email",
                            HTML_EMAIL: "Html email",
                            LETTER: "Letter",
                        },
                        mergeItems: {
                            COMPANY: "Company",
                            FIRST_NAME: "First name",
                            LAST_NAME: "Last name",
                            BALANCE: "Balance",
                            AGING: "Aging",
                            LAST_RUN_DATE: "Last run date",
                            LAST_PAYMENT_DATE: "Last payment date",
                            LAST_PAYMENT_AMOUNT: "Last payment amount",
                            SUBSCRIPTION_PUBLICATION: "Subscription publication",
                            SUBSCRIPTION_EXPIRE_DATE: "Subscription expire date",
                            CUSTOMER_PORTAL_HOME: "Customer portal home",
                            CUSTOMER_PASSWORD_LINK: "Customer password link",
                            QUOTE_LINK: "Quote link",
                        },
                        paymentMethods: {
                            CHECK: "Check",
                            CASH: "Cash",
                            CARD: "Card",
                            ECHECK: "Echeck",
                            ACCT_CREDIT: "Acct credit",
                            ADJUSTMENT: "Adjustment",
                        },
                        paymentStatus: {
                            UNPAID: "Unpaid",
                            PARTIAL_PMT: "Partial pmt",
                            PAID: "Paid",
                        },
                        prebillType: {
                            CLASSIFIED: "Classified",
                            DISPLAY: "Display",
                            TRADE: "Trade",
                            SEPARATE: "Separate",
                            AD_SCHEDULE: "Ad schedule",
                        },
                        primaryFunction: {
                            MANAGER: "Manager",
                            ACCOUNTANT: "Accountant",
                            PRODUCTION: "Production",
                            SALES: "Sales",
                            SUPERUSER: "Superuser",
                            EDITOR: "Editor",
                            WRITER: "Writer",
                        },
                        rateChargeType: {
                            FREQUENCY: "Frequency",
                            BULK: "Bulk",
                        },
                        recurringPaymentMethod: {
                            CARD: "Card",
                            ECHECK: "Echeck",
                        },
                        recurringPaymentAmount: {
                            BALANCE: "Balance",
                            INVOICED_DUE: "Invoiced due",
                            OTHER: "Other",
                        },
                        reportModule: {
                            ACCOUNTS: "Accounts",
                            ADS: "Ads",
                            AGING: "Aging",
                            CONTRACTS: "Contracts",
                            GOALS: "Goals",
                            HISTORY: "History",
                            INSERTIONS: "Insertions",
                            INVOICES: "Invoices",
                            SUBSCRIPTIONS: "Subscriptions",
                            TASKS: "Tasks",
                            TRANSACTIONS: "Transactions",
                            TRANS_INDEX: "Trans index",
                        },

                        reportType: {
                            DETAIL: "Detail",
                            SUMMARY: "Summary",
                        },
                        salesFunction: {
                            DISPLAY: "Display",
                            CLASSIFIED: "Classified",
                            OTHER: "Other",
                            COLLECTIONS: "Collections",
                        },
                        terms: {
                            NET_7: "Net 7",
                            NET_10: "Net 10",
                            NET_15: "Net 15",
                            NET_20: "Net 20",
                            NET_30: "Net 30",
                            NET_45: "Net 45",
                            NET_60: "Net 60",
                            NET_90: "Net 90",
                            PREPAY: "Prepay",
                        },
                        transIndexType: {
                            SALE: "Sale",
                            MISC_CHARGE: "Misc charge",
                            FIN_CHARGE: "Fin charge",
                            PMT_APPLIED: "Pmt applied",
                            APPLIED_CR: "Applied cr",
                            ACCT_CREDIT: "Acct credit",
                            CREDIT_MEMO: "Credit memo",
                        },
                        userLicenseType: {
                            FULL: "Full",
                            CRM: "Crm",
                            PRODUCTION: "Production",
                            WEB: "Web",
                        }
                    }
                }
            },
            "fr": { //French
                translation: {
                    login: {
                        signintoyouraccount: "Connectez-vous à votre compte",
                        username: "Nom d'utilisateur",
                        password: "Mot de passe",
                        signin: "S'identifier",
                    },
                    module: {
                        accounts: "Comptes",
                        ads: "Les publicités",
                        agings: "Vieillissement",
                        contacts: "Contacts",
                        contracts: "Contractes",
                        goals: "Buts",
                        history: "Histoire",
                        insertions: "Insertions",
                        packages: "Paquets",
                        issues: "Problèmes",
                        letters: "Des lettres",
                        invoices: "Facture",
                        subscriptions: "Abonnements",
                        tasks: "Tâches",
                        transactions: "Transactions",
                        transIndex: "Éléments de ligne de transaction",
                    },
                    vls: {
                        accountType: {
                            DIRECT: "Direct-fr",
                            AGENCY_CLIENT: "Client de l'agence",
                            AD_AGENCY: "Agence de publicité",
                            TRADE: "Échanger",
                            PERSONALS: "Personals",
                        },
                        adStatus: {
                            QUOTE: "Devis",
                            AD: "Un d",
                        },
                        adSubType: {
                            WORD: "Mot",
                            BOX: "Boîte",
                        },
                        adSuperType: {
                            DISPLAY: "Affichage",
                            CLASSIFIED_WORD: "Mot classifié",
                            CLASSIFIED_BOX: "Boîte classifiée",
                        },
                        adType: {
                            DISPLAY: "Affichage",
                            CLASSIFIED: "Classifié",
                        },
                        billingCycle: {
                            BY_ISSUE: "Par problème",
                            MONTHLY: "Mensuel",
                        },
                        contractStatus: {
                            NEW: "Nouveau",
                            APPROVED: "Approuvé",
                            CURRENT: "Courant",
                            RENEWAL: "Renouvellement",
                            COMPLETE: "Complet",
                            CANCELED: "Annulé",
                            UNFULFILLED: "Insatisfait",
                        },
                        insertStatus: {
                            NEW: "Nouveau",
                            APPROVED: "Approuvé",
                            INVOICED: "Facturé",
                            DONE: "Fait",
                            CANCELED: "Annulé",
                            HELD_OUT: "Résister",
                            DELETED: "Supprimé",
                        },
                        invoiceStatus: {
                            PARTIAL_PMT: "PMT partiel",
                            UNPAID: "Non payé",
                            PAID: "Payé",
                            BAD_DEBT: "Dette",
                            ERROR: "Erreur",
                        },
                        calendar: {
                            date: 'Date',
                            time: 'Time',
                            event: 'Event',
                            allDay: 'All Day',
                            week: 'Week',
                            work_week: 'Work Week',
                            day: 'Day',
                            month: 'Month',
                            previous: 'Back',
                            next: 'Next',
                            yesterday: 'Yesterday',
                            tomorrow: 'Tomorrow',
                            today: 'Today',
                            agenda: 'Agenda',
                            noEventsInRange: 'There are no events in this range.',
                            showMore: total => `+${total} more`,
                        },
                        invoiceType: {
                            STANDARD: "Standard",
                            STUB: "Bout",
                            DISPLAY: "Affichage",
                            CUSTOM: "Personnalisé",
                            AD_COPY: "Copie d'annonce",
                            LONG: "Long",
                            CARD: "Carte",
                            MISC: "Mission",
                            FIN_CHG: "Nageuse",
                            SUMMARY: "Sommaire",
                            SALES: "Ventes",
                        },
                        issuelessSkip: {
                            CONSECUTIVE: "Consécutif",
                            EVERY_2ND: "Chaque 2",
                            EVERY_3RD: "Chaque 3",
                            EVERY_4TH: "Tous les 4",
                            EVERY_5TH: "Tous les 5",
                            EVERY_6TH: "Tous les 6",
                            EVERY_7TH: "Tous les 7",
                            EVERY_14TH: "Tous les 14 ans",
                            EVERY_30TH: "Tous les 30",
                            SUNDAY: "Dimanche",
                            MONDAY: "Lundi",
                            TUESDAY: "Mardi",
                            WEDNESDAY: "Mercredi",
                            THURSDAY: "Jeudi",
                            FRIDAY: "Vendredi",
                            SATURDAY: "Samedi",
                            FIRST_OF_THE_MONTH: "Premier du mois",
                            LAST_OF_THE_MONTH: "Dernier du mois",
                        },
                        letterType: {
                            EMAIL: "E-mail",
                            HTML_EMAIL: "E-mail HTML",
                            LETTER: "Lettre",
                        },
                        mergeItems: {
                            COMPANY: "Compagnie",
                            FIRST_NAME: "Prénom",
                            LAST_NAME: "Nom de famille",
                            BALANCE: "Solde",
                            AGING: "Vieillissement",
                            LAST_RUN_DATE: "Dernière date d'exécution",
                            LAST_PAYMENT_DATE: "Dernière date de paiement",
                            LAST_PAYMENT_AMOUNT: "Dernier montant de paiement",
                            SUBSCRIPTION_PUBLICATION: "Publication d'abonnement",
                            SUBSCRIPTION_EXPIRE_DATE: "Abonnement expire la date",
                            CUSTOMER_PORTAL_HOME: "Portail client à la maison",
                            CUSTOMER_PASSWORD_LINK: "Lien de mot de passe client",
                            QUOTE_LINK: "Lien de devis",
                        },
                        paymentMethods: {
                            CHECK: "Vérifier",
                            CASH: "En espèces",
                            CARD: "Carte",
                            ECHECK: "Échec",
                            ACCT_CREDIT: "Crédit Acct",
                            ADJUSTMENT: "Ajustement",
                        },
                        paymentStatus: {
                            UNPAID: "Non payé",
                            PARTIAL_PMT: "PMT partiel",
                            PAID: "Payé",
                        },
                        prebillType: {
                            CLASSIFIED: "Classifié",
                            DISPLAY: "Affichage",
                            TRADE: "Échanger",
                            SEPARATE: "Séparé",
                            AD_SCHEDULE: "Calendrier d'annonce",
                        },
                        primaryFunction: {
                            MANAGER: "Gestionnaire",
                            ACCOUNTANT: "Comptable",
                            PRODUCTION: "Production",
                            SALES: "Ventes",
                            SUPERUSER: "Superutilisateur",
                            EDITOR: "Éditeur",
                            WRITER: "Écrivain",
                        },
                        rateChargeType: {
                            FREQUENCY: "La fréquence",
                            BULK: "En gros",
                        },
                        recurringPaymentMethod: {
                            CARD: "Carte",
                            ECHECK: "Échec",
                        },
                        recurringPaymentAmount: {
                            BALANCE: "Solde",
                            INVOICED_DUE: "Facturé dû",
                            OTHER: "Autre",
                        },
                        reportModule: {
                            ACCOUNTS: "Comptes",
                            ADS: "Les publicités",
                            AGING: "Vieillissement",
                            CONTRACTS: "Contractes",
                            GOALS: "Buts",
                            HISTORY: "Histoire",
                            INSERTIONS: "Insertions",
                            INVOICES: "Facture",
                            SUBSCRIPTIONS: "Abonnements",
                            TASKS: "Tâches",
                            TRANSACTIONS: "Transactions",
                            TRANS_INDEX: "Index trans",
                        },

                        reportType: {
                            DETAIL: "Détail",
                            SUMMARY: "Sommaire",
                        },
                        salesFunction: {
                            DISPLAY: "Affichage",
                            CLASSIFIED: "Classifié",
                            OTHER: "Autre",
                            COLLECTIONS: "Collections",
                        },
                        terms: {
                            NET_7: "Net 7",
                            NET_10: "Net 10",
                            NET_15: "Net 15",
                            NET_20: "Net 20",
                            NET_30: "Net 30",
                            NET_45: "Net 45",
                            NET_60: "Net 60",
                            NET_90: "Net 90",
                            PREPAY: "Remboursé",
                        },
                        transIndexType: {
                            SALE: "Vente",
                            MISC_CHARGE: "Accusation de mise",
                            FIN_CHARGE: "Frais de nageoire",
                            PMT_APPLIED: "PMT appliqué",
                            APPLIED_CR: "CR appliqué",
                            ACCT_CREDIT: "Crédit Acct",
                            CREDIT_MEMO: "Mémo de crédit",
                        },
                        userLicenseType: {
                            FULL: "Plein",
                            CRM: "CRM",
                            PRODUCTION: "Production",
                            WEB: "la toile",
                        },
                    },
                    nav: {
                        accounts: "Comptes",
                        tasks: "Tâches",
                        ads: "Les publicités",
                        contacts: "Contacts",
                        calendar: "Calendrier",
                        dashboard: "Tableau de bord",
                        goals: "Buts",
                        sales: "Ventes",
                        reports: "Rapports",
                        history: "Histoire",
                        adExport: "Exportation d'annonces",
                    },
                    role: {
                        salesRep: "Représentant commercial",
                        admin: "Administrer",
                        accounting: "Comptabilité",
                        production: "Production",
                        salesManager: "Directeur commercial",
                    },
                    views: {
                        list: "Liste",
                        map: "Carte",
                    },
                    table: {
                        page: "Page",
                        show: "Spectacle",
                        goToPage: "Aller à la page",
                    },
                    calendar: {
                        date: "Date",
                        time: "Temps",
                        event: "Événement",
                        allDay: "Toute la journée",
                        week: "La semaine",
                        work_week: "Semaine de travail",
                        day: "Jour",
                        month: "Mois",
                        previous: "Retour",
                        next: "Prochain",
                        yesterday: "Hier",
                        tomorrow: "Demain",
                        today: "Aujourd'hui",
                        agenda: "Ordre du jour",
                        noEventsInRange: "Il n'y a pas d'événements dans cette gamme.",
                        showMore: total => `+${total} more`
                    },
                    bulkActionMenu: {
                        startBulkEmail: "Démarrer l'e-mail en vrac",
                        viewRelatedAccounts: "Afficher les comptes connexes",
                        markTasksAsDone: "Marquez les tâches comme fait",
                    },
                    userMenu: {
                        settings: "Réglages",
                        support: "Soutien",
                        signOut: "Déconnexion",
                    },
                    pages: {
                        myAccounts: "Mes comptes",
                        myTasks: "Mes tâches",
                        myAds: "Mes annonces",
                        myContacts: "Mes contacts",
                        calendar: "Calendrier",
                    }
                },
            },
            "es": { //Spanish
                translation: {
                    login: {
                        signintoyouraccount: "Iniciar sesión en su cuenta",
                        username: "Nombre de usuario",
                        password: "Clave",
                        signin: "Iniciar sesión",
                    },
                    nav: {
                        accounts: "Cuentas",
                        tasks: "Tareas",
                        ads: "Anuncios",
                        contacts: "Contactos",
                        calendar: "Calendario",
                        dashboard: "Tablero",
                        goals: "Metas",
                        sales: "Ventas",
                        reports: "Informes",
                        history: "Historia",
                        adExport: "Exportación de anuncios",
                    },
                    role: {
                        salesRep: "Representante de ventas",
                        admin: "Administración",
                        accounting: "Contabilidad",
                        production: "Producción",
                        salesManager: "Gerente de ventas",
                    },
                    views: {
                        list: "Lista",
                        map: "Mapa",
                    },
                    table: {
                        page: "Página",
                        show: "Espectáculo",
                        goToPage: "Ir a la página",
                    },
                    calendar: {
                        date: "Fecha",
                        time: "Tiempo",
                        event: "Evento",
                        allDay: "Todo el dia",
                        week: "Semana",
                        work_week: "Semana de trabajo",
                        day: "Día",
                        month: "Mes",
                        previous: "atrás",
                        next: "próximo",
                        yesterday: "El dia de ayer",
                        tomorrow: "Mañana",
                        today: "Este Dia",
                        agenda: "Agenda",
                        noEventsInRange: "No hay eventos en este rango.",
                        showMore: total => `+${total} more`
                    },
                    bulkActionMenu: {
                        startBulkEmail: "Iniciar correo electrónico a granel",
                        viewRelatedAccounts: "Ver cuentas relacionadas",
                        markTasksAsDone: "Marque las tareas como se hizo",
                    },
                    userMenu: {
                        settings: "Ajustes",
                        support: "Apoyo",
                        signOut: "desconectar",
                    },
                    pages: {
                        myAccounts: "Mis cuentas",
                        myTasks: "Mis tareas",
                        myAds: "Mis anuncios",
                        myContacts: "Mis contactos",
                        calendar: "Calendario",
                    },
                    module: {
                        accounts: "Cuentas",
                        ads: "Anuncios",
                        agings: "Envejecimiento",
                        contacts: "Contactos",
                        contracts: "Contratos",
                        goals: "Metas",
                        history: "Historia",
                        insertions: "Inserciones",
                        packages: "Paquetes",
                        issues: "Problemas",
                        letters: "Letras",
                        invoices: "Facturas",
                        subscriptions: "Suscripciones",
                        tasks: "Tareas",
                        transactions: "Actas",
                        transIndex: "LISTE DE LÍNEA DE TRANSACCIÓN",
                    },
                    vls: {
                        accountType: {
                            DIRECT: "Directo",
                            AGENCY_CLIENT: "Cliente de la agencia",
                            AD_AGENCY: "Agencia de publicidad",
                            TRADE: "Comercio",
                            PERSONALS: "Mensajes personales",
                        },
                        adStatus: {
                            QUOTE: "Cotizar",
                            AD: "Anuncio",
                        },
                        adSubType: {
                            WORD: "Palabra",
                            BOX: "Caja",
                        },
                        adSuperType: {
                            DISPLAY: "Monitor",
                            CLASSIFIED_WORD: "Palabra clasificada",
                            CLASSIFIED_BOX: "Caja clasificada",
                        },
                        adType: {
                            DISPLAY: "Monitor",
                            CLASSIFIED: "Clasificado",
                        },
                        billingCycle: {
                            BY_ISSUE: "Por tema",
                            MONTHLY: "Mensual",
                        },
                        contractStatus: {
                            NEW: "Nuevo",
                            APPROVED: "Aprobado",
                            CURRENT: "Actual",
                            RENEWAL: "Renovación",
                            COMPLETE: "Completo",
                            CANCELED: "Cancelado",
                            UNFULFILLED: "Incumplido",
                        },
                        insertStatus: {
                            NEW: "Nuevo",
                            APPROVED: "Aprobado",
                            INVOICED: "Facturado",
                            DONE: "Hecho",
                            CANCELED: "Cancelado",
                            HELD_OUT: "Tendió",
                            DELETED: "Eliminado",
                        },
                        invoiceStatus: {
                            PARTIAL_PMT: "PMT parcial",
                            UNPAID: "No pagado",
                            PAID: "Pagado",
                            BAD_DEBT: "Deuda incobrable",
                            ERROR: "Error",
                        },
                        invoiceType: {
                            STANDARD: "Estándar",
                            STUB: "Talón",
                            DISPLAY: "Monitor",
                            CUSTOM: "Disfraz",
                            AD_COPY: "Copia",
                            LONG: "Largo",
                            CARD: "Tarjeta",
                            MISC: "Maga",
                            FIN_CHG: "Aleta CHG",
                            SUMMARY: "Resumen",
                            SALES: "Ventas",
                        },
                        issuelessSkip: {
                            CONSECUTIVE: "Consecutivo",
                            EVERY_2ND: "Cada segundo",
                            EVERY_3RD: "Cada tercero",
                            EVERY_4TH: "Cada 4to",
                            EVERY_5TH: "Cada quinto",
                            EVERY_6TH: "Cada sexto",
                            EVERY_7TH: "Cada 7º",
                            EVERY_14TH: "Cada 14º",
                            EVERY_30TH: "Cada 30",
                            SUNDAY: "Domingo",
                            MONDAY: "Lunes",
                            TUESDAY: "martes",
                            WEDNESDAY: "miércoles",
                            THURSDAY: "jueves",
                            FRIDAY: "Viernes",
                            SATURDAY: "sábado",
                            FIRST_OF_THE_MONTH: "Primero del mes",
                            LAST_OF_THE_MONTH: "El último mes",
                        },
                        letterType: {
                            EMAIL: "Correo electrónico",
                            HTML_EMAIL: "Correo electrónico html",
                            LETTER: "Carta",
                        },
                        mergeItems: {
                            COMPANY: "Compañía",
                            FIRST_NAME: "Nombre de pila",
                            LAST_NAME: "Apellido",
                            BALANCE: "Balance",
                            AGING: "Envejecimiento",
                            LAST_RUN_DATE: "Última fecha de ejecución",
                            LAST_PAYMENT_DATE: "Última Fecha de Pago",
                            LAST_PAYMENT_AMOUNT: "Último monto de pago",
                            SUBSCRIPTION_PUBLICATION: "Publicación de suscripción",
                            SUBSCRIPTION_EXPIRE_DATE: "Fecha de caducidad de suscripción",
                            CUSTOMER_PORTAL_HOME: "Inicio del portal del cliente",
                            CUSTOMER_PASSWORD_LINK: "Enlace de contraseña del cliente",
                            QUOTE_LINK: "Enlace de cita",
                        },
                        paymentMethods: {
                            CHECK: "Controlar",
                            CASH: "Dinero",
                            CARD: "Tarjeta",
                            ECHECK: "Resplandar",
                            ACCT_CREDIT: "Crédito por cuenta",
                            ADJUSTMENT: "Ajustamiento",
                        },
                        paymentStatus: {
                            UNPAID: "No pagado",
                            PARTIAL_PMT: "PMT parcial",
                            PAID: "Pagado",
                        },
                        prebillType: {
                            CLASSIFIED: "Clasificado",
                            DISPLAY: "Monitor",
                            TRADE: "Comercio",
                            SEPARATE: "Separado",
                            AD_SCHEDULE: "Horario publicitario",
                        },
                        primaryFunction: {
                            MANAGER: "Gerente",
                            ACCOUNTANT: "Contador",
                            PRODUCTION: "Producción",
                            SALES: "Ventas",
                            SUPERUSER: "Supervolador",
                            EDITOR: "Editor",
                            WRITER: "Escritor",
                        },
                        rateChargeType: {
                            FREQUENCY: "Frecuencia",
                            BULK: "A granel",
                        },
                        recurringPaymentMethod: {
                            CARD: "Tarjeta",
                            ECHECK: "Resplandar",
                        },
                        recurringPaymentAmount: {
                            BALANCE: "Balance",
                            INVOICED_DUE: "Facturado debido",
                            OTHER: "Otro",
                        },
                        reportModule: {
                            ACCOUNTS: "Cuentas",
                            ADS: "Anuncios",
                            AGING: "Envejecimiento",
                            CONTRACTS: "Contratos",
                            GOALS: "Metas",
                            HISTORY: "Historia",
                            INSERTIONS: "Inserciones",
                            INVOICES: "Facturas",
                            SUBSCRIPTIONS: "Suscripciones",
                            TASKS: "Tareas",
                            TRANSACTIONS: "Actas",
                            TRANS_INDEX: "Índice trans",
                        },

                        reportType: {
                            DETAIL: "Detalle",
                            SUMMARY: "Resumen",
                        },
                        salesFunction: {
                            DISPLAY: "Monitor",
                            CLASSIFIED: "Clasificado",
                            OTHER: "Otro",
                            COLLECTIONS: "Colecciones",
                        },
                        terms: {
                            NET_7: "Net 7",
                            NET_10: "Neto 10",
                            NET_15: "Net 15",
                            NET_20: "Neto 20",
                            NET_30: "Neto 30",
                            NET_45: "Neto 45",
                            NET_60: "Net 60",
                            NET_90: "NET 90",
                            PREPAY: "Pagar por adelantado",
                        },
                        transIndexType: {
                            SALE: "Rebaja",
                            MISC_CHARGE: "Misciba",
                            FIN_CHARGE: "Cargo de aleta",
                            PMT_APPLIED: "PMT aplicado",
                            APPLIED_CR: "CR aplicado",
                            ACCT_CREDIT: "Crédito por cuenta",
                            CREDIT_MEMO: "Nota de crédito",
                        },
                        userLicenseType: {
                            FULL: "Completo",
                            CRM: "CRM",
                            PRODUCTION: "Producción",
                            WEB: "Web",
                        },
                    }
                }

            }
        }
    });
//console.log(i18n)
export default i18n;