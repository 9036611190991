import { useState } from "react";
import { gql } from "@apollo/client";

import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "./Card";
import { renderBox } from "./AdDetail";
import { MyDatePicker } from "./DatePicker";
import { Loading } from "./Loading";
import { useMutation } from "@apollo/client";

const changeStartDate = gql`
  mutation renewAdStartDate($recordId: String!, $adId: String!, $date: String!) {
    renewAdStartDate(recordId: $recordId, adId: $adId, date: $date) {
      error
      fail
    }
  }
`;
const changeEndDate = gql`
  mutation renewAdEndDate($recordId: String!, $adId: String!, , $date: String!) {
    renewAdEndDate(recordId: $recordId, adId: $adId, date: $date) {
      error
      fail
    }
  }
`;

const getAdQuery = gql`
  query getAd($id: ID!) {
    getAd(id: $id) {
        id
      adId
      adSource
      color
      tearsheetsprintcount
      orientation
      productionNotes
      adheadline
      adbody
      displayInvoiceNotes
      section
      filepath
      displayUnits
      glCode
      endDate
      startDate
      productionStatus
      purchaseOrder
      superType
      adStatus
      webpreferredplacement
      category {
        adCodeId
        categoryName
      }
      rate {
        rateId
        rateType
      }
      seller {
        userId
        firstName
        lastName
      }
      contact {
        contactFirst
        contactLast
        phone1
      }
      account {
        company
      }
      taker {
        userId
        firstName
        lastName
      }
      contract {
        description
        adContractId
      }
      quoteIndexes{
          id
          charge
          issueId
          qiId
          issue{
            issueDate
          }
          publication{
              publicationId
              publication
          }
          quoteExtras{
            extratype
            recur
            charge
            cCharge
          }
      }

    }
  }
`;

const createSchedule = gql`
  mutation createSchedule($adID: String!) {
    createSchedule(adID: $adID) {
      error
      fail
    }
  }
`;

const setPublicationCharge = gql`
mutation editFieldValue ($input: EditFieldOnLayoutInput){
    editFieldOnLayout(input: $input){
      error
  }
}
`;

const RenderPublication = ({ quoteIndex, onChargeChange }) => {
  const [startIssue] = useState(quoteIndex?.issue?.issueDate);
  const [charge, setCharge] = useState(quoteIndex?.charge?.toFixed(2));

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 p-5 gap-5 items-center">
        <div>
          <span>{quoteIndex.publication.publicationId} &nbsp; </span>
          <span>{quoteIndex.publication.publication}</span>
        </div>
        <div>
          {startIssue}
        </div>
        <div>
          <label htmlFor="charge" className="text-sm font-medium dark:text-white text-gray-700 hidden">
            Charge
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="dark:text-white text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              name="charge"
              id="charge"
              className="focus:ring-brand-500 focus:border-brand-500 block w-full pl-7 sm:text-sm border-gray-300 rounded-md"
              placeholder="0.00"
              aria-describedby="price-currency"
              value={charge}
              onChange={e => {
                setCharge(e.target.value)
                onChargeChange(e.target.value, quoteIndex.id)
              }}
            />
          </div>
        </div>
      </div>
      {quoteIndex.quoteExtras.length > 0 && <div className=" grid grid-cols-1 md:grid-cols-3 p-5 gap-5 items-center -mt-6 text-sm font-medium">
        <div className="pl-20">Extra Type</div>
        <div>Recur</div>
        <div></div>
      </div>}
      {quoteIndex.quoteExtras && quoteIndex.quoteExtras.map((extra, index) => (
        <div className="grid grid-cols-1 md:grid-cols-3 p-5 gap-5 items-center -mt-6 dark:text-white text-gray-700">
          <div className="pl-20">{extra.extratype}</div>
          <div>{extra?.recur}</div>
          <div>${extra?.cCharge.toFixed(2)}</div>
        </div>
      ))}
    </>
  );
};

const AdRenew = () => {
  const { adId } = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { data } = useQuery(getAdQuery, {
    variables: { id: adId },
    onCompleted(_data) {
      setStartDate(new Date(_data.getAd.startDate))
      setEndDate(new Date(_data.getAd.endDate))
      setAdDescription(_data.getAd.displayInvoiceNotes)
    }
  });

  const ad = data?.getAd;

  const [handleCreateSchedule] = useMutation(createSchedule, {
    variables: { adID: adId },
    onCompleted(data) {
      // go to ad detail of new ad
      navigate(`/ads/${adId}`);

    }
  });

  const [changeAdStartDate] = useMutation(changeStartDate, {
    onCompleted(data) {
      // reload the record
      window.location.reload();
    }
  });

  const [
    changeAdEndDate
  ] = useMutation(changeEndDate, {
    onCompleted(data) {
      // reload the record
      window.location.reload();
    }
  });

  const [
    changePublicationCharge
  ] = useMutation(setPublicationCharge, {
    onCompleted(data) {
      //console.log('set cahrge')
    }
  });

  function onStartDateChange(date) {
    setStartDate(date)
    changeAdStartDate(
      {
        variables: {
          recordId: adId, adId: String(ad?.adId), date: date.toLocaleDateString()
        }
      }
    )
  }
  function onEndDateChange(date) {
    setEndDate(date)
    changeAdEndDate(
      {
        variables: {
          recordId: adId, adId: String(ad?.adId), date: date.toLocaleDateString()
        }
      }
    )
  }
  function onChargeChange(charge, id) {
    changePublicationCharge(
      {
        variables: {
          input: {
            layoutName: 'gql__QuoteIndex',
            id: id,
            value: charge,
            name: 'charge'

          }
        }
      }
    )
  }
  function onAdDescriptionChange(description, id) {
    changePublicationCharge(
      {
        variables: {
          input: {
            layoutName: 'gql__Ad',
            id: id,
            value: description,
            name: 'displayInvoiceNotes'

          }
        }
      }
    )
  }

  const navigate = useNavigate();

  const [adDescription, setAdDescription] = useState("");

  const tableData = ad
    ? {
      Contact: `${ad.contact.contactFirst} ${ad.contact.contactLast}`,
      "Contact Phone": ad.contact.phone1,
      Seller: `${ad.seller.userId} - ${ad.seller.firstName} ${ad.seller.lastName}`,
      Contract: ad.contract
        ? `${ad.contract.adContractId} - ${ad.contract.description}`
        : "—",
      Section: ad.section,
      Category: ad.category
        ? `${ad.category.adCodeId} - ${ad.category.categoryName}`
        : "-",
      Rate: ad.rate ? `${ad.rate.rateId} - ${ad.rate.rateType}` : "-",
      Tear: ad.tearsheetsprintcount ?? '—',
      GL: ad.glCode ?? '—',
      PO: ad.purchaseOrder ?? '—',
    }
    : null;

  const additionalDetailsTableData = ad
    ? [
      ["Ad Size", ad.displayUnits],
      ["Production Notes", ad.productionNotes],
      ["Ad Source", ad.adSource],
      ["Orientation", ad.orientation],
      ["Production Status", ad.productionStatus],
      ["File Name", ad.filepath],
      ["Color", ad.color],
      ["Placement/Page Request", ad.webpreferredplacement],
    ]
    : [];

  const adDetailsConfig = {
    title: "Ad Details",
    menu: [],
    content: () => {
      return ad ? (
        <>
          <div className="p-5">
            <div className="w-full grid grid-cols-1 md:grid-cols-5 gap-x-5 gap-y-10">
              {!!tableData &&
                Object.keys(tableData as object).map((key) =>
                  renderBox(key, (tableData as object)[key])
                )}
            </div>
            <div className="pt-10 grid grid-cols-1 md:grid-cols-3 gap-10">
              <MyDatePicker
                date={startDate}
                setDate={onStartDateChange}
                label="Start Date"
                updateDate={null}
              />
              <MyDatePicker
                date={endDate}
                setDate={onEndDateChange}
                label="End Date"
                updateDate={null}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="p-20">
          <Loading />
        </div>
      );
    },
    footer: false,
  };

  const publicationsConfig = {
    title: "Publications",
    menu: [],
    content: () => {
      return ad ? (
        <div>
          <div className=" grid grid-cols-1 md:grid-cols-3 p-5 gap-5 -mb-6 items-center text-sm font-medium">
            <div></div>
            <div>Start Issue</div>
            <div>Charge</div>
          </div>
          {ad.quoteIndexes.map((quoteIndex, index) => (
            <div key={index}>
              <RenderPublication
                quoteIndex={quoteIndex}
                onChargeChange={onChargeChange}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="p-20">
          <Loading />
        </div>
      );
    },
    footer: false,
  };

  let re = /(<([^>]+)>)/ig
  let cleanedCopy = ad ? `${ad.adheadline || ''} ${ad.adbody || ''}`.replace(re, '') : null
  const description = ad ? ad.displayInvoiceNotes ? ad.displayInvoiceNotes : ad.adheadline || ad.adbody ? cleanedCopy : '—' : null

  const additionalDetailsConfig = {
    title: "Additional Details",
    menu: [],
    content: () => {
      return ad ? (
        <div className="grid grid-cols-1 md:grid-cols-5 p-5 gap-5 items-end">
          {additionalDetailsTableData.map((item, index) => (
            <div
              key={index}
              className={index === 1 ? "col-span-1 md:col-span-3" : ""}
            >
              {renderBox(item[0], item[1])}
            </div>
          ))}
          <div className="md:col-span-5">
            <p className="block text-sm font-medium dark:text-white text-gray-700">Ad Description</p>
            {ad?.superType !== 'Display' && <p className="mt-1 block w-full sm:text-sm">{description}</p>}
            {ad?.superType === 'Display' &&
              <textarea
                title="Ad Description"
                name="Ad Description"
                id="AdDescription"
                rows={4}
                className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={adDescription}
                onChange={(e) => {
                  setAdDescription(e.target.value)
                  onAdDescriptionChange(e.target.value, ad.id)
                }
                }
              />
            }
          </div>
        </div>
      ) : (
        <div className="p-40">
          <Loading />
        </div>
      );
    },
    footer: false,
  };

  return (
    <div>
      <Card config={adDetailsConfig} onMenuActionEvent={null} />
      <Card config={publicationsConfig} onMenuActionEvent={null} />
      <Card config={additionalDetailsConfig} onMenuActionEvent={null} />
      <div className="flex space-x-5 justify-end container mx-auto px-4 sm:px-6 lg:px-8 mt-5">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium dark:text-white text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-brand-500"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-brand-500"
          onClick={() => handleCreateSchedule()}
        >
          Create Schedule
        </button>
      </div>
    </div>
  );
};
export default AdRenew;

/* const setAdDescriptionMutation = gql`
mutation editFieldValue ($input: EditFieldOnLayoutInput){
    editFieldOnLayout(input: $input){
error
  }
}
`;
 */
/*   const [ changeAdDescription ] = useMutation(setAdDescriptionMutation, {
    onCompleted(data) {
      //console.log('set ad description')
    }
  }); */



/* const breadcrumb = [
  { name: "Accounts", href: "/accounts", current: false },
  { name: ad?.account?.company ?? "", href: false, current: false },
  { name: "Ads", href: "/ads", current: false },
  { name: "Ad Details", href: false, current: false },
  { name: "Renew Ad", href: false, current: true },
]; */
/*
  const headerConfig = {
    export: false,
    add: false,
    edit: false,
    custom: [],
    menu: [],
    title: `${ad?.superType || ''} ${ad?.adStatus || ''} ${ad?.adId || ''}`,
    subtitle: "",
    search: false,
  };
 */