
import { gql, useMutation } from "@apollo/client";
import React, { Fragment, useState, useContext } from 'react'
import { CheckCircleIcon, PencilIcon, PlusIcon, SelectorIcon, CheckIcon, XIcon } from '@heroicons/react/solid'
import { useQuery } from "@apollo/client";
import { FormProvider, useForm, useFormContext, useController } from 'react-hook-form';
import { Tab } from '@headlessui/react'
import { Combobox } from '@headlessui/react'
import { Listbox, Transition } from '@headlessui/react'
import { LayoutContext } from 'react-filemaker';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useSlideOver } from '../SlideOver';
import { AddressInput } from "./AddressInput";
import { Input } from "./Input";
import { Select } from "./Select";
import { useConfirmationModal } from "./Modal";
export const CREATE_TASK = gql`
    mutation createTask($input: CreateTaskInput) {
      createTask(input: $input) {
        id
        accountId
        type
        description
        datedone
        done
        callDate
        callTime
        priority
      }
    }
  `;
export const GET_VALUE_LISTS = gql`
query getValueLists{
    getValueLists{
        taskType{
            value
        }
    }
}
`;
export const NewTaskForm = ({ accountId }) => {

    const { data: valueListData } = useQuery(GET_VALUE_LISTS);
    const { getValueLists } = valueListData || {};
    const { taskType } = getValueLists || {};
    console.log("TASK TYPE", taskType)
    const [createTask] = useMutation(CREATE_TASK);
    const { onClose } = useSlideOver();
    const onSubmit = async (values) => {
        try {
            const result = await createTask({
                variables: {
                    input: values
                },
            });
            console.log("createContact", result);
            toast.custom("Contact created successfully");
        } catch (e) {
            toast.custom("Error creating account");
        }
        //onClose(true);
    }
    const onError = (error, e) => {
        toast("Error creating account");
        console.log("error account create", error);
    }
    const defaultTask = {
        accountId: accountId,
        type: "",
        description: "",
        datedone: "",
        done: false,
        callDate: "",
        callTime: "",
        priority: "",
    };
    const methods = useForm({
        defaultValues: defaultTask
    });
    const { open } = useConfirmationModal();
    const { handleSubmit, formState } = methods;
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="space-y-4">
                <Input name={"description"} label="Description" />
                <Select name={"type"} label="Type" placeholder="&nbsp;" labelKey={"value"} idKey={"value"} options={taskType || []} />
                <Input name={"date"} label="Date" />
                <Input name={"time"} label="Time" />
                <Select name={"priority"} label="Priority" placeholder="&nbsp;" labelKey={"displayValue"} idKey={"value"} options={[{ value: "1", displayValue: "High" }, { value: "2", displayValue: "Normal" }, { value: "3", displayValue: "Low" },]} />
                <Input name={"completed"} label="Completed" />
                <div className="flex-shrink-0 px-4 pt-4 flex justify-between sticky bottom-0 bg-white z-20">

                    <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium dark:text-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (formState.isDirty) {
                                open({
                                    title: "Discard Changes?",
                                    message: "Are you sure you want to discard your changes?",
                                    handleConfirm: () => {
                                        methods.reset();
                                    },
                                    handleCancel: () => {
                                        console.log("cancel...");
                                    }
                                })
                            } else {
                                methods.reset();
                                //onClose();
                            }
                        }}
                    >
                        Close
                    </button>
                    <button type="submit" className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500">
                        Save
                    </button>
                </div>
            </form>
        </FormProvider>
    )
}
export default NewTaskForm;

/*
}

            {!editData && <div>
                <div className="sm:flex sm:items-end">
                    <div className="sm:flex-1">

                        <div className="flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                            {!dateCompleted && <button
                                type="button"
                                onClick={() => handleCompleteTask()}
                                className={`flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium dark:text-white text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500`}
                                                      >
                                Complete Task
                            </button>}

                            {dateCompleted && <div
                                className={`bg-white space-x-3 flex-1 w-full inline-flex items-center justify-left rounded-md font-bold text-xl dark:text-white text-gray-900 sm:text-2xl`}
                            >
                                <span>Task Completed</span> <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                            </div>}
                            <span className="ml-3 inline-flex sm:ml-0">
                                <div className="relative inline-block text-left">
                                    <button onClick={() => handleEdit()} className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500">
                                        <span className="sr-only">Open options menu</span>
                                        <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="pt-5 pb-5">
                    <dl className="space-y-8 sm:space-y-6">
                        <div>
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Description</dt>
                            <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{description || '—'}</dd>
                        </div>
                        <div>
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Type</dt>
                            <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{type || '—'}</dd>
                        </div>
                        <div>
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Date Scheduled/Due</dt>
                            <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{dateDue || '—'}</dd>
                        </div>
                        <div>
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Priority</dt>
                            <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{priority || '—'}</dd>
                        </div>
                        <div>
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Date Completed</dt>
                            <dd className="mt-1 text-sm dark:text-white text-gray-900 sm:col-span-2">{dateCompleted || '—'}</dd>
                        </div>
                        
                    </dl>
                </div>
            </div>}
            */