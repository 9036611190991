import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Card } from "./Card";
import { Loading } from "./Loading";

export const getAdQuery = gql`
  query getAd($id: ID!) {
    getAd(id: $id) {
      modId
      accountId
      adadditionalCopy
      adbody
      adchargesoverride
      adCodeId
      ademail
      adheadline
      adheadsize
      adId
      adphone
      adSource
      adStatus
      adType
      adurl
      alert
      approvalDate
      approvalTime
      bodychargeunitsoverride
      bodylinecount
      boldunits
      borderColor
      borderStyle
      borderWidth
      color
      colWidth
      contractId
      dateCreated
      dateModified
      designerId
      displayInvoiceNotes
      displayUnits
      endDate
      expireDate
      exportToPrint
      exportToWeb
      filepath
      firstRunDate
      glCode
      graphic1Path
      graphic2Path
      headchargeunitsoverride
      headlinecount
      height
      lastRunDate
      modifier
      normalizedbody
      orientation
      packageId
      pageRequest
      pickupAdId
      pickupDate
      probability
      productionNotes
      productionStatus
      productionStatusLog
      publishedInserts
      publishedTotal
      purchaseOrder
      rateId
      remainingInsertions
      remainingTotal
      renewAdId
      renewDate
      section
      sellerId
      sortDate
      startDate
      subcategory
      subType
      superType
      takerId
      tearsheetsprintcount
      tillFurtherNotice
      timeCreated
      timeModified
      timePrinted
      totalIssues
      tsModifiedClient
      tsmodspecial
      uid
      webadId
      webbackcolor
      webCharge
      webExpireDate
      webExtra
      webphoto
      webpreferredplacement
      webpremiumplacement
      webrushcharge
      webrushdate
      webStartDate
      zone
      category {
        adCodeId
        categoryName
      }
      rate {
        rateId
        rateType
      }
      seller {
        userId
        firstName
        lastName
      }
      contact {
        contactFirst
        contactLast
        phone1
      }
      insertions {
        publication {
          advertiserPortalUrl
          publication
          anniversarydate
        }
      }
      account {
        company
      }
      taker {
        userId
        firstName
        lastName
      }
      quoteIndexes{
          id
          charge
          issueId
          pubId
          qiId
          issue{
            issueDate
          }
      }

    }
  }
`;

export const renderBox = (title, value) => {
  return (
    <div key={title}>
      <p className="block text-sm font-medium dark:text-white text-gray-700">{title}</p>
      <p>{value ?? "—"}</p>
    </div>
  );
};

const AdDetail = () => {
  const { adId } = useParams();
  //const navigate = useNavigate();
  //const [insertionsSort, setInsertionsSort] = useState([{ column: "Date Created", dir: "descend" }]);
  const { loading, error, data } = useQuery(getAdQuery, {
    variables: { id: adId },
  });
  const ad = data?.getAd;


  if (error) throw new Error(`${error}`);
  if (loading) return <Loading />;

  if (!ad) throw new Error("No ad found for id: " + adId);

  let re = /(<([^>]+)>)/ig
  let cleanedCopy = ad ? `${ad.adheadline || ''} ${ad.adbody || ''}`.replace(re, '') : null
  const description = ad ? ad.displayInvoiceNotes ? ad.displayInvoiceNotes : ad.adheadline || ad.adbody ? cleanedCopy : '—' : null

  const adDetails = ad
    ? {
      "Ad Code": ad.category
        ? `${ad.category.adCodeId} - ${ad.category.categoryName}`
        : "—",
      Print: ad.tearsheetsprintcount ?? "No", // ??
      Contact: `${ad.contact.contactFirst} ${ad.contact.contactLast}`,
      Section: ad.section,
      Web: ad.webadId ?? "No", // ??
      Phone: ad.adphone,
      "Rate Code": ad.rate ? `${ad.rate.rateId} - ${ad.rate.rateType}` : "-",
      Ad: ad.adId,
      Email: ad.ademail,
      Seller: ad.seller
        ? `${ad.seller.userId} - ${ad.seller.firstName} ${ad.seller.lastName}`
        : "—",
      "Web Ad": ad.webadId,
      TFN: ad.tillFurtherNotice,
      Taker: ad.taker
        ? `${ad.taker.userId} - ${ad.taker.firstName} ${ad.taker.lastName}`
        : "—",
      Contract: ad.contract
        ? `${ad.contract.adContractId} - ${ad.contract.description}`
        : "—",
      Status: ad.adStatus,
      Description: description
    }
    : null;

  const displayProperties = ad
    ? {
      "Display Units": ad.displayUnits,
      "Color Code": ad.color,
      "Column Width": ad.colWidth,
      Orientation: ad.orientation,
      "Column Height": ad.height,
      "Graphic Before": ad.graphic1Path,
      "Production Status": ad.productionStatus,
      "Graphic After": ad.graphic2Path,
      Placement: ad.webpreferredplacement,
      "File Name": ad.filepath,
      "Ad Source": ad.adSource,
      "Production Notes": ad.productionNotes,
    }
    : null;

  const createTable = (object, cols) => {
    const lastKey = Object.keys(object)[Object.keys(object).length - 1];
    return (
      <>
        <div
          className={`grid grid-cols-1 md:grid-cols-${cols} p-5 gap-5 divide-y divide-gray-200 md:space-y-0 md:divide-y-0`}
        >
          {Object.keys(object)
            .slice(0, Object.keys(object).length - 1)
            .map((key) => renderBox(key, object[key]))}
        </div>
        <div className="px-5 pb-5">{renderBox(lastKey, object[lastKey])}</div>
      </>
    );
  };

  const adDetailsConfig = {
    title: "Ad Details",
    menu: [],
    content: () => {
      return (
        <div>
          {adDetails ? (
            createTable(adDetails, 3)
          ) : (
            <div className="p-40">
              <Loading />
            </div>
          )}
        </div>
      );
    },
    footer: false,
  };

  const displayPropertiesConfig = {
    title: "Display Properties",
    menu: [],
    content: () => {
      return (
        <div>
          {displayProperties ? (
            createTable(displayProperties, 2)
          ) : (
            <div className="p-40">
              <Loading />
            </div>
          )}
        </div>
      );
    },
    footer: false,
  };


  const insertionsConfig = {
    title: "Scheduled Insertions",
    menu: [],
    content: () => {
      return null;
    },
    footer: () => {

    },
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 container m-auto">
        <div className="col-span-1 md:col-span-2 flex flex-col">
          <Card
            config={adDetailsConfig}
            onMenuActionEvent={null}
            addStyles="grow mx-0"
          />
        </div>
        <div className="col-span-1 flex flex-col">
          <Card
            config={displayPropertiesConfig}
            onMenuActionEvent={null}
            addStyles="grow mx-0  md:-ml-8 lg:-ml-12 grid-cols-1 md:grid-cols-2"
          />
        </div>
      </div>
      <div className="col-span-3">
        <Card config={insertionsConfig} />
      </div>
    </>
  );
};
export default AdDetail;
/* const GET_INSERTIONS = gql`
  query getInsertions($input: FindInput) {
    findInsertions(input: $input) {
      insertions {
        statactvAmtDue
        pricecharged
        discount1
        discount2
        taxRate
        dateCreated
        type
        insertionStatus
        pricecharged
        publication {
          state
        }
        issue {
          issueId
          issueStatus
        }
      }
      count
      total
    }
  }
`; */

/* const GET_RENEW_AD_ID = gql`
  mutation renewAD($adID: String!) {
    renewAd(adID: $adID) {
      newAdId
      error
      fail
    }
  }
`; */
/*   const [getRenewId] = useMutation(GET_RENEW_AD_ID, {
    variables: { adID: adId },
    onCompleted(data) {
      const renewAdId = data?.renewAd?.newAdId;
      navigate(`/ads/${renewAdId}/renew`);
    }
  }); */
/*  const {
   loading: insertionsLoading,
   error: insertionsError,
   data: insertionsData,
 } = useQuery(GET_INSERTIONS, {
   variables: {
     input: {
       criteria: {
         criterion: [
           {
             key: "Ad ID",
             value: String(ad?.adId),
           },
         ],
       },
       offset: rowsPerPage * insertionsPage,
       limit: rowsPerPage,
       sort: insertionsSort,
     },
   },
 }); */


/*   const handleInsertionsChangeSort = () => {
    setInsertionsSort((currentSort) =>
      currentSort.map((sort) => ({
        ...sort,
        dir: sort.dir === "ascend" ? "descend" : "ascend",
      }))
    );
  }; */

/*   const insertionsTableConfig = {
    columns: [
      { header: "Date ", prop: "dateCreated", sort: true },
      {
        header: "Publication",
        prop: "publicationState",
        class: "hidden md:table-cell",
      },
      { header: "Issue", prop: "issueId", class: "hidden md:table-cell" },
      {
        header: "Type",
        prop: "type",
        class: "hidden md:table-cell",
      },
      {
        header: "Insertion Status",
        prop: "insertionStatus",
        class: "hidden md:table-cell",
      },
      {
        header: "Issue Status",
        prop: "issueStatus",
        class: "hidden md:table-cell",
      },
      { header: "Total", prop: "total", class: "hidden md:table-cell" },
      {
        header: "Amount Due",
        prop: "amountDue",
        class: "hidden md:table-cell",
      },
    ],
    menu: [],
    sort: insertionsSort,
    handleChangeSort: handleInsertionsChangeSort,
  };
 */
/*  const insertionTableData = insertionsData
   ? insertionsData.findInsertions.insertions.map((insertion) => ({
     ...insertion,
     publicationState: insertion.publication.state,
     issueId: insertion.issue.issueId,
     issueStatus: insertion.issue.issueStatus,
     total:
       insertion.statactvAmtDue ??
       insertion.pricecharged -
       insertion.discount1 -
       insertion.discount2 +
       Math.round(
         (((insertion.pricecharged -
           insertion.discount1 -
           insertion.discount2) *
           insertion.taxRate +
           Number.EPSILON) *
           100) /
         100
       ),
     amountDue: insertion.statactvAmtDue,
   }))
   : null; */
