import { useFormContext } from "react-hook-form";
type IAdEditorTextArea = {
  fieldName: string;
  onSelection?: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
}

export const AdEditorTextArea = ({ fieldName, onSelection, onBlur, onFocus }: IAdEditorTextArea) => {
  const { register, setValue } = useFormContext();
  return (<textarea
    {...register(fieldName, {
      onBlur: (e) => {
        console.log(`${fieldName} has changed`, e.target.value);
        setValue(fieldName, e.target.value);
      }, onChange: () => { return 0; }
    })}
    className={`p-1 text-black tracking-tight w-full ${fieldName == "headline" ? "h-4":"h-full"}`}
    style={{ fontSize: "12px", lineHeight: `13px` }}
    onSelect={onSelection}
    onFocus={onFocus}
    onBlur={onBlur} />);
};
