import { Fragment } from 'react';
import { ChevronRightIcon, SparklesIcon } from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { ArrowCircleUpIcon, IdentificationIcon, BriefcaseIcon, ChartBarIcon, ChatAlt2Icon, ClockIcon, CloudDownloadIcon, ArrowCircleDownIcon, CollectionIcon, CurrencyDollarIcon, DocumentReportIcon, NewspaperIcon, OfficeBuildingIcon, TemplateIcon, UserGroupIcon, CalendarIcon, PresentationChartLineIcon } from '@heroicons/react/outline';
import { foundSet } from '../App';
import { useReactiveVar } from '@apollo/client';
const CurrentMenu = () => {
  const location = useLocation();
  const { t } = useTranslation();
  switch (location.pathname.split("/")[1]) {
    case "tasks":
      return (<><ChatAlt2Icon className="w-5 h-5 mr-1 pb-1"></ChatAlt2Icon><div className='hidden sm:block'>{t("nav.tasks")}</div></>);
    case "accounts":
      return (<><OfficeBuildingIcon className="w-5 h-5 mr-1 pb-1"></OfficeBuildingIcon><div className='hidden sm:block'>{t("nav.accounts")}</div></>);
    case "ads":
      return (<><NewspaperIcon className="w-5 h-5 mr-1 pb-1"></NewspaperIcon><div className='hidden sm:block'>{t("nav.ads")}</div></>);
    case "contacts":
      return (<><UserGroupIcon className="w-5 h-5 mr-1 pb-1"></UserGroupIcon><div className='hidden sm:block'>{t("nav.contacts")}</div></>);
    case "calendar":
      return (<><CalendarIcon className="w-5 h-5 mr-1 pb-1"></CalendarIcon><div className='hidden sm:block'>{t("nav.calendar")}</div></>);
    case "dashboard":
      return (<><PresentationChartLineIcon className="w-5 h-5 mr-1 pb-1"></PresentationChartLineIcon><div className='hidden sm:block'>{t("nav.dashboard")}</div></>);
    default:
      return null;
  }

}
export const Breadcrumb = () => {

  const { t } = useTranslation();
  const foundSetReactive = useReactiveVar(foundSet);

  console.log("foundSetReactive", foundSetReactive);
  const params = useParams();

  return (
    <div className="bg-brand-900 py-2 w-full">
      <div className=" md:flex md:items-center md:justify-start container w-full mx-auto md:px-6 lg:px-8">

        <nav className="flex justify-between w-full" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center">
            <li key={"currentMenuLevel"} className="flex">

              <div className="rounded-lg flex items-center">
                <div className="text-gray-200 ml-2 text-sm mt-0.5 font-bold"> </div>
                <Menu as="div" className=" grow relative">
                  {({ open }) => (
                    <>
                      <Menu.Button className="flex flex-start  hover:text-rose-400">
                        <IdentificationIcon className="w-5 h-5 text-gray-200  mr-2" />
                        <span className={`hidden sm:block text-left text-sm font-medium text-gray-200 shadow-white `}>{t("role.salesRep")}</span>
                      </Menu.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="divide-y overflow-y-visible origin-top-right absolute left-0 w-36 mt-2 text-sm rounded-md border-darkbrand-400  shadow-lg bg-white dark:bg-darkbrand-700 dark:ring-darkbrand-700  ring-1 ring-black ring-opacity-5 focus:outline-none z-50 font-bold">
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink to={"/admin"}   className={({ isActive }) => `pointer-events-none bg-brand-200 dark:bg-darkbrand-50 hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  rounded-t-lg flex items-center font-bold`} >
                                <>
                                  <SparklesIcon className="w-4 h-4 mr-1" />
                                  {t("role.admin")}
                                </>
                              </NavLink>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink to={"/accounting"} className={({ isActive }) => `pointer-events-none bg-brand-100 dark:bg-darkbrand-50 hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center font-bold`} >
                                <><CurrencyDollarIcon className="w-4 h-4 mr-1" />   {t("role.accounting")}</>
                              </NavLink>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink to={"/production"} className={({ isActive }) => `pointer-events-none bg-brand-100 dark:bg-darkbrand-50 hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center font-bold`} >
                                <><NewspaperIcon className="w-4 h-4 mr-1" />   {t("role.production")}</>
                              </NavLink>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink to={"/manager"} className={({ isActive }) => `pointer-events-none bg-brand-100 dark:bg-darkbrand-50 hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center font-bold`} >
                                <><BriefcaseIcon className="w-4 h-4 mr-1" />   {t("role.salesManager")}</>
                              </NavLink>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink to={"/dashboard"} className={({ isActive }) => ` ${isActive ? 'bg-brand-600 dark:bg-darkbrand-600' : 'bg-brand-400 dark:bg-darkbrand-400'} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  rounded-b-lg flex items-center font-bold`} >
                                <><IdentificationIcon className="w-4 h-4 mr-1" />   {t("role.salesRep")}</>
                              </NavLink>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </li>
            <li>
              <Menu as="div" className=" grow relative w-full">
                {({ open }) => (
                  <>
                    <Menu.Button className="flex hover:text-rose-400">
                      <ChevronRightIcon className={`${open ? "transform rotate-90 ease-in duration-100" : "transform rotate-0 ease-out duration-100"} w-5 h-5 text-gray-200 mt-0.5`} />
                      <div className="text-xs flex  font-bold mt-1 text-gray-200">
                        <CurrentMenu />
                      </div>
                    </Menu.Button>
                    <Transition show={open} as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="divide-y overflow-y-visible origin-top-right absolute left-0 mt-2 text-sm rounded-md border-darkbrand-400  shadow-lg bg-white dark:bg-darkbrand-700 dark:ring-darkbrand-700  ring-1 ring-black ring-opacity-5 w-32 focus:outline-none z-50">
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={"/dashboard"} className={({ isActive }) => ` ${isActive ? 'bg-brand-600 dark:bg-darkbrand-600' : 'bg-brand-400 dark:bg-darkbrand-400'} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  rounded-t-lg flex items-center text-xs font-bold`} >
                              <><TemplateIcon className="w-4 h-4 mr-1"></TemplateIcon>   {t("nav.dashboard")}</>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={"/accounts"} className={({ isActive }) => ` ${isActive ? 'bg-brand-600 dark:bg-darkbrand-600' : 'bg-brand-400 dark:bg-darkbrand-400'} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center  text-xs font-bold`} >
                              <><OfficeBuildingIcon className="w-4 h-4 mr-1"></OfficeBuildingIcon> {t("nav.accounts")}</>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={"/contacts"} className={({ isActive }) => ` ${isActive ? 'bg-brand-600 dark:bg-darkbrand-600' : 'bg-brand-400 dark:bg-darkbrand-400'} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center  text-xs font-bold`} >
                              <><UserGroupIcon className="w-4 h-4 mr-1"></UserGroupIcon> {t("nav.contacts")}</>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={"/goals"} className={({ isActive }) => `pointer-events-none bg-brand-200 dark:bg-darkbrand-50  cursor-not-allowed hover:dark:bg-darkbrand-500   hover:bg-brand-500 px-2 py-0.5 text-gray-300  flex items-center  text-xs font-bold`} >
                              <><ChartBarIcon className="w-4 h-4 mr-1"></ChartBarIcon> {t("nav.goals")}</>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={"/ads"} className={({ isActive }) => ` ${isActive ? 'bg-brand-600 dark:bg-darkbrand-600' : 'bg-brand-400 dark:bg-darkbrand-400'} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center  text-xs font-bold`} >
                              <><NewspaperIcon className="w-4 h-4 mr-1"></NewspaperIcon> {t("nav.sales")}</>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={"/tasks"} className={({ isActive }) => ` ${isActive ? 'bg-brand-600 dark:bg-darkbrand-600' : 'bg-brand-400 dark:bg-darkbrand-400'} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center text-xs font-bold`} >
                              <><ChatAlt2Icon className="w-4 h-4 mr-1"></ChatAlt2Icon> {t("nav.tasks")}</>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={"/reports"} className={({ isActive }) => `pointer-events-none bg-brand-200 dark:bg-darkbrand-50  cursor-not-allowed  hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-300  flex items-center  text-xs font-bold`} >
                              <><DocumentReportIcon className="w-4 h-4 mr-1"></DocumentReportIcon>  {t("nav.reports")}</>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={"/history"} className={({ isActive }) => `pointer-events-none bg-brand-200 dark:bg-darkbrand-50  cursor-not-allowed  hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-300  flex items-center  text-xs font-bold`} >
                              <><ClockIcon className="w-4 h-4 mr-1"></ClockIcon> {t("nav.history")}</>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => <NavLink to={"/"} className={({ isActive }) => `pointer-events-none bg-brand-200 dark:bg-darkbrand-50  truncate hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-300  rounded-b-lg flex items-center  text-xs font-bold`} >
                            <><CloudDownloadIcon className="w-4 h-4 mr-1"></CloudDownloadIcon> {t("nav.adExport")}</>
                          </NavLink>}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </li>
            {Object.keys(params).length ? <li>
              <Menu as="div" className=" grow relative  w-full">
                {({ open }) => (
                  <>
                    <Menu.Button className="flex hover:text-rose-400">
                      <>
                        <ChevronRightIcon className={`${open ? "transform rotate-90 ease-in duration-100" : "transform rotate-0 ease-out duration-100"} w-5 h-5 text-gray-200 mt-0.5`} />
                        <div className={`mr-1 text-xs font-bold mt-1 text-gray-200 ${Object.keys(params).length > 0 && foundSetReactive?.activeRecId === params.accountId ? "" : "hidden"}`}>
                          {`# ${foundSetReactive?.activeRecordNumber}`}
                        </div>
                      </>
                    </Menu.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="divide-y overflow-y-visible origin-top-right absolute left-0  mt-2 text-sm rounded-md border-darkbrand-400  shadow-lg bg-white dark:bg-darkbrand-700 dark:ring-darkbrand-700  ring-1 ring-black ring-opacity-5 w-32 focus:outline-none z-50">
                        <Menu.Item>
                          <div className="divide-y px-2 py-0.5 text-gray-200  rounded-t-lg text-center w-full mx-auto text-xs font-bold">Recent Accounts</div>
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={`/account/${"123123123123"}`} className={({ isActive }) => ` ${isActive ? 'bg-darkbrand-600' : ''} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center text-xs font-bold`} >
                              <OfficeBuildingIcon className="w-4 h-4 mr-1 min-w-fit"></OfficeBuildingIcon>
                              <div className="truncate">ACME Inc. DBA Rock n Roll Hall of fame</div>
                              <div> #2232</div>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={`/account/${"123123123123"}`} className={({ isActive }) => ` ${isActive ? 'bg-darkbrand-600' : ''} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center text-xs font-bold`} >
                              <OfficeBuildingIcon className="w-4 h-4 mr-1 min-w-fit"></OfficeBuildingIcon>
                              <div className="truncate">ACME Inc. DBA Rock n Roll Hall of fame</div>
                              <div> #2232</div>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={`/account/${"123123123123"}`} className={({ isActive }) => ` ${isActive ? 'bg-darkbrand-600' : ''} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center text-xs font-bold`} >
                              <OfficeBuildingIcon className="w-4 h-4 mr-1 min-w-fit"></OfficeBuildingIcon>
                              <div className="truncate">ACME Inc. DBA Rock n Roll Hall of fame</div>
                              <div> #2232</div>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          <div className="divide-y px-2 py-0.5 text-gray-200 text-center w-full mx-auto text-xs font-bold">Found Set</div>
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={`/account/${"123123123123"}`} className={({ isActive }) => ` ${isActive ? 'bg-darkbrand-600' : ''} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center text-xs font-bold`} >
                              <OfficeBuildingIcon className="w-4 h-4 mr-1 min-w-fit"></OfficeBuildingIcon>
                              <div className="truncate">ACME Inc. DBA Rock n Roll Hall of fame</div>
                              <div> #2232</div>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={`/account/${"123123123123"}`} className={({ isActive }) => ` ${isActive ? 'bg-darkbrand-600' : ''} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center text-xs font-bold`} >
                              <OfficeBuildingIcon className="w-4 h-4 mr-1 min-w-fit"></OfficeBuildingIcon>
                              <div className="truncate">ACME Inc. DBA Rock n Roll Hall of fame</div>
                              <div> #2232</div>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={`/account/${"123123123123"}`} className={({ isActive }) => ` ${isActive ? 'bg-darkbrand-600' : ''} hover:dark:bg-darkbrand-500 hover:bg-brand-500 px-2 py-0.5 text-gray-200  flex items-center text-xs font-bold`} >
                              <OfficeBuildingIcon className="w-4 h-4 mr-1 min-w-fit"></OfficeBuildingIcon>
                              <div className="truncate">ACME Inc. DBA Rock n Roll Hall of fame</div>
                              <div> #2232</div>
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to={"/accounts/{foundSetId}"} className={({ isActive }) => ` ${isActive ? 'bg-darkbrand-600' : ''}    hover:dark:bg-darkbrand-500 rounded-b-lg hover:bg-brand-500 px-2 py-0.5 text-gray-200  items-center  text-xs font-bold text-center flex w-full`} >
                              <CollectionIcon className="w-4 h-4 mr-1"></CollectionIcon>
                              <div className="truncate grow">Open Found Set as List</div>
                              <div>9 total</div>
                            </NavLink>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </li> : null}
          </ol>
          <div className={`flex ml-1 py-1 ${Object.keys(params).length > 0 && foundSetReactive?.activeRecId === params.accountId ? "" : "hidden"}`}>
            <NavLink to={`/${foundSetReactive?.tableName}`} className="text-xs text-gray-300 hover:text-rose-500 cursor-pointer font-bold -mt-0.5 mr-1.5">
              <span>{foundSetReactive?.activeRow}</span> of <span>{foundSetReactive?.recordCount}</span>
            </NavLink>
            <NavLink to={`/${foundSetReactive?.tableName}/${foundSetReactive?.prevRecId}`} title="Go to Prev Record">
              <span className={foundSetReactive?.prevRecId ? "" : "pointer-events-none bg-brand-200 dark:bg-darkbrand-50 "}>
                <ArrowCircleUpIcon className="cursor-pointer hover:text-rose-500 -mt-0.5 mr-1 h-4 w-4 text-gray-500" />
              </span>
            </NavLink>
            <NavLink to={`/${foundSetReactive?.tableName}/${foundSetReactive?.nextRecId}`}>
              <span className={foundSetReactive?.nextRecId ? "" : "pointer-events-none bg-brand-200 dark:bg-darkbrand-50 "}>
                <ArrowCircleDownIcon className="cursor-pointer hover:text-rose-500 -mt-0.5  mr-1 h-4 w-4 text-gray-500" />
              </span>
            </NavLink>
          </div>
        </nav>
      </div>
    </div>
  )
}