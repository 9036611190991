import { Suspense, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useFileMakerScript } from "./FileMaker";
import Loading from "./components/Loading";
import { useLogger, useRendersCount, useSearchParam } from "react-use";

//types for the window object because otherwise typescript complains
interface CustomWindow extends Window {
  FileMaker?: any;
  param?: any;
  google?: any;
  handleAdData: any;
}
type SubmitData = {
  file: File;
}

declare const window: CustomWindow;

export function FileUpload() {

  useLogger(`FileUpload render #${useRendersCount()}`);

  useEffect(() => { //This undoes the "normal" background for the main SP web app
    //window.document.body.classList.add('bg-white');
    window.document.body.style.backgroundColor = 'white';
    window.document.body.style.backgroundImage = 'none';
    window.document.body.style.color = "#000000";
    window.document.body.style.overflow = "hidden";
  }, []);
  //Define the form with blank default values
  const methods = useForm({
    defaultValues: {
      file: new File([], "test.txt")
    }
  });

  const { formState, register, handleSubmit } = methods;
  const { isSubmitting } = formState;
  const fileType = useSearchParam("fileType");
  console.log("fileType", fileType);
  const [getPreSignedUrl, { data, error, loading }] = useFileMakerScript("WebFileUpload - Get Pre-Signed URL");
  const onSubmit = async (data: SubmitData) => {
    try {
      console.log("onSubmit", data, fileType);
      const { url, publicUrl } = await getPreSignedUrl({
        fileName: data.file[0].name,
        fileType,
        mimeType: data.file[0].type
      });
      console.log("url", url, publicUrl);
      const result = await fetch(url, {
        "mode": "cors",
        "method": "PUT",
        "headers": {
          "Content-Type": data.file[0].type,
        },
        "body": data.file[0]
      });
      console.log("result", result);
    } catch (e) {
      throw new Error("Error updating via FileMaker global object");
    }
  };
  console.log(data);
  return data ? <div>file uploaded</div> : (
    <Suspense fallback={<div className="dark:bg-darkbrand-800 flex justify-center items-center min-h-screen"><span>loading</span><Loading /></div>}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <fieldset disabled={isSubmitting || loading}>
            <div className="flex items-center justify-center w-full">
              <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="hidden text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                </div>
                <input type="file" {...register("file", { required: true })} className={"hidden"} />
              </label>
            </div>
            <input type="submit" value="Upload" className="m-1 w-full text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded cursor-pointer" />
          </fieldset>
        </form>
      </FormProvider>
    </Suspense >
  );
}
