import { useContext, useEffect } from "react";
import { ClassifiedEditorContext } from "./ClassifiedAdEditor";

//This is the same Google Client as is used for Google Calendar in SmartPublisher Proper
export const API_KEY = 'AIzaSyBGo6CIEohczFjQ7l2_jtiTR4jAF_WUDjI';
export const APP_ID = '885637200718';

interface CustomWindow extends Window {
  FileMaker?: any;
  param?: any;
  googleAccessToken?: any;
  google?: any;
  gapi?: any;
  DriveApp?: any;
  handleAdData: any;
}
declare const window: CustomWindow;
type IGraphicSelection = {
  type: string;
  onSelection: (path: string) => void;
}

export const GraphicSelection = ({ type, onSelection }:IGraphicSelection) => {
  const { googleDriveProductionFolderParentId, googleAccessToken }: any = useContext(ClassifiedEditorContext);

  //Fires after the user has selected a file in the Google Picker
  const onPickerCallback = async (data:any) => {
    console.log("PICKER CALLBACK", data);
    if (data.action === window.google.picker.Action.PICKED) {
      //Selected (or uploaded) file object
      const document = data[window.google.picker.Response.DOCUMENTS][0];
      //File ID of the selected file
      const fileId = document[window.google.picker.Document.ID];

      //Get the file details from the Google Drive API
      const res = await window.gapi.client.drive.files.get({
        'fileId': fileId,
        'fields': 'name, parents' //We need the parents field in order to determine the path of the file
      });

      //Get all parents of the file
      let { parents, name: pathString } = res.result;
      if (parents && parents.length > 0) { //If the file has no parents, it is in the root of... That users own drive? or the parent folder specified as base file path?
        //Get the name of each parent folder as an array of file objects
        let pathParts = await Promise.all(parents.map((parentFileId:string) => {
          return window.gapi.client.drive.files.get({
            'fileId': parentFileId,
            'fields': 'name, parents'
          });
        }));
        //Get the name of each parent folder as an array of strings
        const pathNameParts = pathParts.map((part) => part.result.name);
        //Join the array of parent folder names with slashes to create the path string
        pathString = pathNameParts.join("/") + "/" + pathString;
      }
      onSelection(pathString);
    } else if (data.action === window.google.picker.Action.CANCEL) {
      console.log('User cancelled picker.');
    } else {
      console.log('Picker action not implemented', data.action);
    }
  }

  //Runs whenever the type (before or after) changes, except when it clears the value during the picker callback
  useEffect(() => {
    console.log("GOOGLE DRIVE token", googleAccessToken);
    if (googleDriveProductionFolderParentId == "") throw new Error("Unable to open picker, GoogleDrive Production Folder ParentId is not set");

    //This view is the tab that allows you to upload a file
    const docsUploadView = new window.google.picker.DocsUploadView();
    docsUploadView.setIncludeFolders(true); //don't know if this is necessary
    docsUploadView.setLabel("Upload to Base File Path");
    docsUploadView.setParent(googleDriveProductionFolderParentId) //This is the folder that the user will upload to

    //This view is the tab that allows you to select a file
    const docsView = new window.google.picker.DocsView();
    docsView.setLabel("Select file from SmartPublisher Base File Path");
    docsView.setIncludeFolders(true); //otherwise you'll only see one level of files within the parent folder
    docsView.setMode(window.google.picker.DocsViewMode.LIST); //Rather than grid view
    docsView.setParent(googleDriveProductionFolderParentId) //This is the folder that the user can select files from

    //Build Picker object with the above views
    const picker = new window.google.picker.PickerBuilder()
      .setDeveloperKey(API_KEY)
      .setAppId(APP_ID)
      .setSize(566, 350)
      .setTitle(`Select graphic for ${type} ad`)
      .setSelectableMimeTypes("application/postscript,image/bmp,application/x-director,application/postscript,image/gif,image/jpeg,image/jpeg,application/pdf,image/png,image/x-png,application/bmp,application/eps,image/pjpeg,application/x-eps,image/eps,image/x-eps,application/x-bmp,image/ms-bmp,image/x-bitmap,application/psd,application/x-photoshop,image/photoshop,image/psd,image/x-photoshop,image/x-psd,image/x-bmp,image/x-ms-bmp,image/x-win-bitmap,image/x-windows-bmp,image/x-xbitmap,image/vnd.adobe.photoshop,image/x-rgb,image/svg+xml,image/tiff,application/tif,application/tiff,application/x-tif,application/x-tiff,image/tif,image/x-tif,image/x-tiff,image/webp,application/pdf,application/postscript,application/photoshop,application/x-indesign,application/illustrator")
      .setOAuthToken(googleAccessToken)
      .addView(docsView)
      .addView(docsUploadView)
      .setCallback(onPickerCallback)
      .build();

    //Finally show picker over the top of the page
    picker.setVisible(true);
  }, []);

  return <></>;
};
