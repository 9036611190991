import { Suspense, createContext, useEffect, useState } from "react";
import { useLogger, useRendersCount } from "react-use";
import { FormProvider, useForm } from "react-hook-form";
import { DocumentIcon } from "@heroicons/react/solid";

import { AdEditorTextArea } from "./AdEditorTextArea";
import { InsertValueSelect } from "./InsertValueSelect";
import { ColorSelect } from "./ColorSelect";
import { FormattingButton } from "./FormattingButton";
import { GraphicSelection } from "./GraphicSelection";
import { HeadlineSizeSelect } from "./HeadlineSizeSelect";
import { useFileMakerScript } from "./FileMaker";
import Loading from "./components/Loading";
import { FloppyDiskIcon } from "./FloppyDiskIcon";
import { LoadingSpinner } from "./LoadingSpinner";
import { EventData } from "mapbox-gl";
import { GraphicPath } from "./GraphicPath";

//This is the URL you specify to download the API info
export const GAPI_URL = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';
//types for the window object because otherwise typescript complains
interface CustomWindow extends Window {
  FileMaker?: any;
  param?: any;
  google?: any;
  gapi?: any;
  handleAdData: any;
}

declare const window: CustomWindow;
type ColorSelectInput = {
  name: string;
  value: string;
}

export const ClassifiedEditorContext: any = createContext({
  accountData: null, //The account data sent by the FileMaker script
  caret: { start: 0, end: 0 }, //The range of characters selected - what would be wrapped by tags
  currentField: "adBody", //Always starting on the ad body seems to make the most sense
  defaultJustifyBody: "", //Full Justify until an ad code specifies a different one
  defaultJustifyHeadline: "", //Full Justify until an ad code specifies a different one
  googleDriveProductionFolderParentId: "", //The ID of the folder in which to search for graphics
  graphicAfter: "", //The path to the graphic after the ad body
  graphicBefore: "", //The path to the graphic before the ad body
});

export function ClassifiedAdEditor() {
  useLogger(`ClassifiedAdEditor render #${useRendersCount()}`);

  const [defaultJustifyBody, setDefaultJustifyBody] = useState("");
  const [defaultJustifyHeadline, setDefaultJustifyHeadline] = useState("");
  const [caret, setCaret] = useState({ start: 0, end: 0 });
  const [accountData, setAccountData]: any = useState({});
  const [currentField, setCurrentField] = useState("");
  const [googleDriveProductionFolderParentId, setGoogleDriveProductionFolderParentId] = useState("");
  const [graphicBefore, setGraphicBefore] = useState("");
  const [selectGraphic, setSelectGraphic] = useState(""); //This is used to trigger the Google Picker [before|after
  const [graphicAfter, setGraphicAfter] = useState("");
  const [colorList, setColorList] = useState([]);
  const [lastSaved, setLastSaved] = useState("");
  const [googleAccessToken, setGoogleAccessToken] = useState("");

  //This gets a valid Google Drive API token from FileMaker - it runs EVERY time this loads while it should ONLY load when the system is configured for google drive, tried putting this into the graphicselection component but that really didn't work very well.
  const [getToken] = useFileMakerScript("CAE - Get Token for Google Drive");

  //Used after the user selects a graphic in the Google Picker
  const [setGraphicPath] = useFileMakerScript("CAE - Set Graphic Path");

  //Define the FileMaker script hook to fetch the ad data, response is a truple strting with the function you call to run the script
  const [getAdData] = useFileMakerScript("CAE - Get Ad Data");

  //Define the FileMaker script hook to update the ad data and read the loading value ... but do I need this loading value or is waiting for isSubmitting going to wait for the async response just as well?
  const [updateAd, { loading }] = useFileMakerScript("CAE - Update Ad from Web Viewer");
  
  //Runs one time - This undoes the "normal" background for the main SP web app
  useEffect(() => {
    //window.document.body.classList.add('bg-white');
    window.document.body.style.backgroundColor = 'white';
    window.document.body.style.color = "#000000 !important";
    window.document.body.style.overflow = "hidden";
  }, []);

  //Define the form with blank default values
  const methods = useForm({
    defaultValues: {
      adBody: "",
      adHeadline: "",
      adHeadlineSize: "",
      modId: ""
    }
  });

  const { setValue, getValues, formState, reset, register, handleSubmit } = methods;
  const { isSubmitting, isDirty } = formState;

  useEffect(() => {
    const getAccountData = async () => {
      const adData = await getAdData();
      //Set the form values to the ad data returned by the script (which should be when the form loads since it should be waiting due to suspense)
      setValue("adBody", adData.adBody);
      setValue("adHeadline", adData.adHeadline);
      setValue("adHeadlineSize", adData.adHeadlineSize);
      setValue("modId", adData.modId);
      //Set the values used by the context
      setAccountData(adData); //This is used for inserting account data values
      setDefaultJustifyBody(adData.adBodyJustify);
      setDefaultJustifyHeadline(adData[`adHeadlineJustify${adData.adHeadlineSize}`]);
      setColorList(adData.colorList);
      setGraphicBefore(adData.graphicBefore);
      setGraphicAfter(adData.graphicAfter);
      setLastSaved(adData.lastModified);
      setGoogleDriveProductionFolderParentId(adData.googleDriveProductionFolderParentId);
    };
    //Run the (newly defined and using async/await) function to get the ad data
    getAccountData();
  }, []);
 
  const handleColorSelect = ({ name, value }:ColorSelectInput) => {
    const values:any = getValues();
    const { start, end } = caret;
    if (start == end && start !== values[currentField].length) {
      alert("Select at least one character from the text to insert " + name + " elements around.");
      return 0;
    }
    let text = values[currentField];
    const startText = text.substring(0, start);
    const endText = text.substring(end, text.length);
    const selectedText = text.substring(start, end);
    const startTag = value;
    const endTag = start == end && start === values[currentField].length ? "" : "<cK>";
    setValue(currentField as "adBody" | "adHeadline", `${startText}${startTag}${selectedText}${endTag}${endText}`);
  };

  const handleFocus = (e: EventData) => {
    console.log("handleFocus", e.target.name);
    setCurrentField(e.target.name);
    setCaret({
      start: e.target.selectionStart,
      end: e.target.selectionEnd
    });
  };
  const handleBlur = (e:EventData) => {
    console.log("handleBlur", e.target.name);
    setCurrentField(e.target.name);
    setCaret({
      start: e.target.selectionStart,
      end: e.target.selectionEnd
    });
  };
  const handleSelection = (e: EventData) => {
    console.log("handleSelection", e.target.name);
    if (currentField !== e.target.name) {
      setCurrentField(e.target.name);
      setCaret({
        start: e.target.selectionStart,
        end: e.target.selectionEnd
      });
    } else if (e.target.selectionStart !== e.target.selectionEnd) {
      console.log("No change in currentField but selection has changed");
      setCurrentField(e.target.name);
      setCaret({
        start: e.target.selectionStart,
        end: e.target.selectionEnd
      });
    }
  };

  const onSubmit = async (data: any) => {
    try {
      console.log("onSubmit", data);
      const ad = await updateAd(data, { timeout: data.action == "preview" ? 30000 : 3000 });
      setLastSaved(new Date().toLocaleTimeString());
      if(data.action == "preview") {
        window.open(ad.url, "_blank");
      }
      reset(ad, {
        keepValues: true,
        keepSubmitCount: true
      });
    } catch (e) {
      throw new Error("Error updating via FileMaker global object");
    }
  };

  const handleGraphicSelection = async (path: string) => {
    console.log("handleGraphicSelection", path);
    await setGraphicPath({ type: selectGraphic, pathString: path });
    if (selectGraphic == "before") {
      setGraphicBefore(path);
    } else if (selectGraphic == "after") {
      setGraphicAfter(path);
    }
    setSelectGraphic("");
  };

  //Runs only once - Load the Google API client library
  useEffect(() => {
    if (window && document) {
      //Add the script via JS so that it doesn't block the page load
      const script = document.createElement('script')
      const body = document.getElementsByTagName('body')[0]
      script.src = 'https://apis.google.com/js/api.js'
      body.appendChild(script)
      script.addEventListener('load', async () => {
        console.log("GAPI LOADED");
        await new Promise((res, rej) => {
          window.gapi.load("client:picker", { callback: res, onerror: rej });
        });
        const response = await getToken();
        const accessToken = response.accessToken;
        setGoogleAccessToken(accessToken);
        console.log("gapi", window.gapi, accessToken);
        window.gapi.client.setToken({ access_token: accessToken });
        await window.gapi.client.load(GAPI_URL);
        window.gapi.client.setToken({ access_token: accessToken });
      })
    }
  }, []);

  const handleInsertSelect = ({ value }:{value: string }) => {
    const { adBody, adHeadline } = getValues();
    const { start, end } = caret;
    let text = currentField == 'adBody' ? adBody : adHeadline;
    const startText = text.substring(0, start);
    const endText = text.substring(end, text.length);
    const selectedText = text.substring(start, end);
    console.log("startText", startText, "endText", endText, "selectedText", selectedText, "value", value);
    setValue(currentField as "adBody" | "adHeadline", `${startText}${value}${selectedText}${endText}`);
  };

  if (accountData == null) return null;

  return (
    <Suspense fallback={ <div className="dark:bg-darkbrand-800 flex justify-center items-center min-h-screen"><span>loading</span><Loading /></div>}>
      <ClassifiedEditorContext.Provider value={{ colorList, googleAccessToken, googleDriveProductionFolderParentId, graphicBefore, graphicAfter, currentField, defaultJustifyBody, defaultJustifyHeadline, caret, accountData }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <fieldset disabled={isSubmitting || loading}>
              <div className="p-2 text-black text-sm w-full gap-1 flex">
                <div className="flex flex-col w-80">
                  <div className={"text-xs flex flex-row justify-between"}>
                    <div className="text-xs font-semibold flex-grow">Headline</div>
                    <HeadlineSizeSelect />
                  </div>
                  <AdEditorTextArea fieldName="adHeadline" onSelection={handleSelection} onBlur={handleBlur} onFocus={handleFocus} />
                  <div className={"text-xs my-1 font-semibold"}>Body</div>
                  <AdEditorTextArea fieldName="adBody" onSelection={handleSelection} onBlur={handleBlur} onFocus={handleFocus} />
                  <div className="flex flex-col space-y-2 mt-1">
                    <GraphicPath currentPath={graphicBefore} handleSelect={() => setSelectGraphic("before")} handleTrash={() => setGraphicPath({ type: "before", pathString: "" })} label={"Before Ad"} />
                    <GraphicPath currentPath={graphicAfter}  handleSelect={() => setSelectGraphic("after")} handleTrash={() => setGraphicPath({ type: "after", pathString: "" })}   label={"After Ad"}/>
                  </div>
                  {selectGraphic !== "" ? <GraphicSelection type={selectGraphic} onSelection={handleGraphicSelection} /> : null}
                </div>
                <div id="formatting" className="flex flex-col w-full mt-12">
                  <div className=" flex-row   w-full isolate inline-flex ">
                    <FormattingButton type={"paired"} label={"H1"} name={"H1"} value={"<H1>"} headlineElligible={false} className={"rounded-l-md"} />
                    <FormattingButton type={"paired"} label={"H2"} name={"H2"} value={"<H2>"} headlineElligible={false} />
                    <FormattingButton type={"paired"} label={"H3"} name={"H3"} value={"<H3>"} headlineElligible={false} className={"rounded-r-md"} />
                  </div>
                  <div className=" flex-row  w-full isolate inline-flex ">
                    <FormattingButton type={"paired"} label={"Bold"} name={"B"} value={"<B>"} headlineElligible={true} className={"rounded-l-md"} />
                    <FormattingButton type={"paired"} label={"Italics"} name={"I"} value={"<I>"} headlineElligible={true} />
                    <FormattingButton type={"paired"} label={"Underline"} name={"U"} value={"<U>"} headlineElligible={true} className={"rounded-r-md"} />
                  </div>
                  <div className=" flex-row  w-full isolate inline-flex ">
                    <FormattingButton type={"paired"} label={"Strikethrough"} name={"S̵t̵"} value={"</>"} headlineElligible={true} className={"rounded-l-md"} />
                    <FormattingButton type={"paired"} label={"Bold Italic"} name={"BI"} value={"<BI>"} headlineElligible={true} />
                    <FormattingButton type={"paired"} label={"All Caps"} name={"K"} value={"<K>"} headlineElligible={true} className={"rounded-r-md"} />
                  </div>
                  <div className=" flex-row  w-full isolate inline-flex ">
                    <FormattingButton type={"alignment"} label={"Center"} name={"C"} value={"<*C>"} headlineElligible={true} className={"rounded-l-md"} />
                    <FormattingButton type={"alignment"} label={"Left"} name={"Left"} value={"<*L>"} headlineElligible={true} />
                    <FormattingButton type={"alignment"} label={"Right"} name={"Right"} value={"<*R>"} headlineElligible={true} className={"rounded-r-md"} />
                  </div>
                  <div className="w-full">
                    <div className={"text-xs font-semibold"}>Insert</div>
                    <InsertValueSelect onSelect={handleInsertSelect} />
                  </div>
                  <div className="w-full mt-1" >
                    <div className={"text-xs font-semibold"}>Color</div>
                    <ColorSelect onSelect={handleColorSelect} />
                  </div>
                  <div className="mt-2 w-full flex grow flex-col">
                    <div className="flex flex-row justify-between">
                      <button type="submit" onClick={handleSubmit((data) => onSubmit({ ...data, action: "save" }))} disabled={!isDirty} className={`h-12 text-xs justify-center ring-1 ring-inset ring-brand-700 w-12 relative inline-flex items-center p-2 hover:bg-brand-400 focus:z-10 ${isDirty ? "bg-brand-500" : "bg-brand-300"} text-brand-50 font-semibold rounded-l cursor-pointer`}>
                      {isSubmitting ? <LoadingSpinner /> : <FloppyDiskIcon className="h-6 w-6 mr-1" />}
                      </button>
                      <button type="submit" onClick={handleSubmit((data) => onSubmit({ ...data, action: "preview" }))} className="h-12 text-base justify-center ring-1 ring-inset ring-brand-700 w-full relative inline-flex items-center p-2  hover:bg-brand-400 focus:z-10 bg-brand-500 text-brand-50 font-semibold rounded-r cursor-pointer" >
                        <DocumentIcon className="h-8 w-8 mr-1" />
                        Preview
                      </button>
                    </div>
                    <div className="text-[10px] font-semibold tracking-tighter text-center">Last Saved: {lastSaved}</div>
                    <div className="grow">&nbsp;</div>
                    <button type="submit" onClick={handleSubmit((data) => onSubmit(data))} className="text-xs justify-center ring-1 ring-inset ring-brand-700 w-full relative inline-flex items-center px-1 py-2 my-1 hover:bg-brand-400 focus:z-10 bg-brand-500 text-brand-50 font-semibold rounded cursor-pointer">
                      {isSubmitting ? <LoadingSpinner />: "Save & Close"}
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>
            <input type="hidden" {...register("modId")} />
          </form>
        </FormProvider>
      </ClassifiedEditorContext.Provider>
    </Suspense >
  );
}

